<template>
  <transition
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    enter-active-class="transform duration-150 ease-out"
    :appear="true"
  >
    <div
      class="absolute inset-0 z-40 flex items-center justify-around bg-white/90 dark:bg-neutral-900/80"
    >
      <svg
        class="h-6 w-6 animate-spin text-blue-600/90"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
      <!-- <i class="fas fa-spinner-third fa-spin text-4xl text-blue-600"></i> -->
    </div>
  </transition>
</template>

<script lang="ts">
export default {
  name: "Loading",
};
</script>
