<template>
  <div v-flux-loading="isFetching || isMutating">
    <flux-input-box
      class="ml-3"
      label="Patiënt stemt in met het delen van behandeldata met de LDF?"
    >
      <flux-radio-button-small
        :model-value="permission"
        label="Ja"
        @update:model-value="handleSelect('permit')"
        optionValue="permit"
      />

      <flux-radio-button-small
        :model-value="permission"
        label="Nee"
        @update:model-value="handleSelect('deny')"
        optionValue="deny"
      />
    </flux-input-box>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Patient } from "@/composables/patient";
import { useLdfConsent, useUpdateLdfConsent } from "@/composables/ldfConsent";
import { ConsentType } from "@/models/Consent";

const props = defineProps<{
  patient: Patient;
}>();

const permission = computed(() => consents.value?.at(0)?.type ?? "deny");

const { data: consents, isFetching } = useLdfConsent(
  computed(() => props.patient.zis_number),
);

const { updateLdfConsent: mutate, isMutating } = useUpdateLdfConsent(
  computed(() => props.patient.zis_number),
);

function handleSelect(newType: ConsentType) {
  mutate(newType);
}
</script>
