<template>
  <ul class="relative m-0 list-none px-0 py-1">
    <slot name="top"></slot>
    <li
      v-for="(entry, index) in listData"
      :key="index"
      @mousedown="onSelect(entry)"
    >
      <slot
        name="entry"
        :entry="entry"
        :active="activeIndex === index"
        :index="index"
        >{{ entry }}</slot
      >
    </li>
    <slot name="bottom"></slot>
  </ul>
</template>
<script setup lang="ts" generic="T">
import { inject } from "vue";

defineProps<{
  listData: T[];
  activeIndex: number | undefined;
}>();

const emit = defineEmits<{ (e: "select", v: T): void }>();

const onSelectFromTypeAhead = inject<(selected: T) => void>(
  "onSelectFromTypeAhead",
);

function onSelect(selected: T) {
  onSelectFromTypeAhead?.(selected);
  emit("select", selected);
}
</script>
