import { TelemetryEntry } from "./Entry";
import {
  TelemetryTransmitter,
  BackendApiTelemetryTransmitter,
} from "./Transmitter";

type TelemetryEntryInput = Omit<TelemetryEntry, "frontend_release" | "time">;

class TelemetryManager {
  private queue: TelemetryEntry[] = [];

  constructor(private transmitter: TelemetryTransmitter) {}

  public queueEntry(partialEntry: TelemetryEntryInput) {
    if (!this.isValidEntry(partialEntry)) {
      throw new Error("TelemetryEntry is not valid.");
    }

    const entry = this.formatQueueEntry(partialEntry);
    this.pushEntryOntoQueue(entry);
  }

  private formatQueueEntry(entry: TelemetryEntryInput): TelemetryEntry {
    return {
      ...entry,
      frontend_release: this.getFrontendRelease(),
      time: new Date(),
    };
  }

  private isValidEntry(entry: Pick<TelemetryEntry, "action">): boolean {
    return /^[a-z.]+$/.test(entry.action);
  }

  private getFrontendRelease(): string {
    return (import.meta.env.VITE_SENTRY_RELEASE as string) || "unknown";
  }

  private pushEntryOntoQueue(entry: TelemetryEntry) {
    this.queue.push(entry);
    setTimeout(() => this.transmit(), 2500);
  }

  private async transmit() {
    if (this.queue.length === 0) {
      return;
    }

    const success = await this.transmitter.transmit(this.queue);

    if (success) {
      this.queue = [];
    } else {
      throw new Error("Error occured during transmission");
    }
  }
}

export const telemetryManager = new TelemetryManager(
  new BackendApiTelemetryTransmitter(),
);
