import { apiClient } from "../utils/axios";
import { ref } from "vue";
import { Country } from "@/models/Country";
import { defineStore } from "pinia";

export const useCountryStore = defineStore("countries", () => {
  const cache = ref<Promise<Country[]>>();

  const getCache = () =>
    (cache.value ??= apiClient
      .get("/countries")
      .then(({ data }) => data.countries));

  async function findById(countryId: number): Promise<Country | undefined> {
    return (await getCache()).find(({ id }) => id === countryId);
  }

  async function findAll(): Promise<Country[]> {
    return await getCache();
  }

  return { findById, findAll };
});
