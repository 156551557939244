export const routes = {
  homeRoute: {
    name: "Home",
    meta: {
      icon: "fal fa-home",
      label: "Home",
    },
  },
  calendarRoute: {
    name: "Calendar",
    meta: {
      icon: "fal fa-calendar",
      label: "Calendar",
    },
  },
  financeRoute: {
    name: "Finance",
    meta: {
      icon: "fal fa-euro-sign",
      label: "Finance",
    },
  },
  adminRoute: {
    name: "AdminOverview",
    meta: {
      icon: "fal fa-cog",
      label: "Admin",
    },
  },
  patientsRoute: {
    name: "patientList",
    meta: {
      icon: "fal fa-user-friends",
      label: "Patients",
    },
  },
  healthcareProfessionalsRoute: {
    name: "healthcareProfessionalList",
    meta: {
      icon: "fal fa-book-medical",
      label: "Zorgverleners",
    },
  },
  businessIntelligenceRoute: {
    name: "businessIntelligence",
    meta: {
      icon: "fal fa-chart-bar",
      label: "Business Analytics",
    },
  },
  dataExplorer: {
    name: "dataExplorer",
    meta: {
      icon: "fal fa-database",
      label: "Data Explorer",
    },
  },
} as const;
