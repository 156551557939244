import { Ref } from "vue";
import { recurringPaymentMandatesQueryKeys } from "./../queries/recurringPaymentMandates/recurringPaymentMandatesQueryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import {
  fetchRecurringPaymentMandatesForPatient,
  revokeRecurringPaymentMandate,
} from "@/queries/recurringPaymentMandates/recurringPaymentMandatesQuery";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

export function useRecurringPaymentMandates(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey:
      recurringPaymentMandatesQueryKeys.recurringPaymentMandates(zisNumber),
    queryFn: ({ queryKey }) =>
      fetchRecurringPaymentMandatesForPatient(queryKey[1]),
    enabled: hasFeatureFlag("recurring-payments"),
    refetchOnWindowFocus: false,
    throwOnError: true,
  });

  const revoke = useMutation({
    mutationFn: (payload: {
      zisNumber: number;
      recurringPaymentMandateUuid: string;
    }) =>
      revokeRecurringPaymentMandate(
        payload.zisNumber,
        payload.recurringPaymentMandateUuid,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey:
          recurringPaymentMandatesQueryKeys.recurringPaymentMandates(zisNumber),
      });
    },
  });

  return {
    ...query,
    revokeRecurringPayment: (
      zisNumber: number,
      recurringPaymentMandateUuid: string,
    ) => revoke.mutateAsync({ zisNumber, recurringPaymentMandateUuid }),
  };
}
