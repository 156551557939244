export interface Post {
  date: `${string}-${string}-${string}`;
}

export const posts: Post[] = [
  { date: "2024-08-03" },
  { date: "2024-07-17" },
  { date: "2024-05-25" },
  { date: "2024-05-10" },
  { date: "2024-03-22" },
  { date: "2024-03-15" },
  { date: "2024-03-01" },
  { date: "2024-02-23" },
  { date: "2024-02-09" },
  { date: "2024-02-02" },
  { date: "2024-01-26" },
  { date: "2023-11-20" },
  { date: "2023-10-24" },
  { date: "2023-10-06" },
  { date: "2023-09-29" },
  { date: "2023-09-08" },
  { date: "2023-09-01" },
  { date: "2023-08-25" },
  { date: "2023-08-18" },
  { date: "2023-08-11" },
  { date: "2023-08-04" },
  { date: "2023-07-28" },
  { date: "2023-07-24" },
  { date: "2023-07-10" },
  { date: "2023-06-02" },
  { date: "2023-05-26" },
  { date: "2023-05-17" },
  { date: "2023-03-27" },
  { date: "2023-03-20" },
  { date: "2023-03-03" },
  { date: "2023-02-27" },
  { date: "2023-02-20" },
  { date: "2023-02-03" },
  { date: "2023-01-27" },
  { date: "2022-07-08" },
  { date: "2022-07-01" },
  { date: "2022-06-24" },
  { date: "2022-06-17" },
  { date: "2022-06-10" },
  { date: "2022-05-27" },
  { date: "2022-05-13" },
  { date: "2022-04-29" },
  { date: "2022-04-22" },
  { date: "2022-04-15" },
  { date: "2022-04-08" },
  { date: "2022-03-25" },
  { date: "2022-03-18" },
  { date: "2022-02-25" },
  { date: "2022-02-18" },
  { date: "2022-02-11" },
  { date: "2022-02-04" },
  { date: "2022-01-31" },
  { date: "2021-10-27" },
  { date: "2021-10-26" },
];

export function hasReadPost(post: Post): boolean {
  return getReadReleaseNotes()?.some((date) => date === post.date) ?? true;
}

export function updatePostAsRead(post: Post) {
  const read = new Set(getReadReleaseNotes() ?? getAllReleaseNotes());
  read.add(post.date);
  updateReadNotes(read);
}

export function hasUnreadReleaseNotes(): boolean {
  const read = getReadReleaseNotes();
  if (read === undefined) {
    updateReadNotes(getAllReleaseNotes());

    return false;
  }

  const allNotes = getAllReleaseNotes();

  const hasReadAll = allNotes.every((note) => read.includes(note));

  return !hasReadAll;
}

export function getReadReleaseNotes() {
  const raw = window.localStorage.getItem("read_release_notes");
  if (raw == null) {
    return undefined;
  }
  const read = JSON.parse(raw);
  if (
    !Array.isArray(read) ||
    !read.every((name): name is string => typeof name === "string")
  ) {
    return undefined;
  }

  return read;
}

export function getAllReleaseNotes() {
  return posts.map((post) => post.date);
}

export function updateReadNotes(notes: Iterable<string>) {
  const payload = JSON.stringify(Array.from(notes));
  window.localStorage.setItem("read_release_notes", payload);
}
