<template>
  <span v-if="company && companyDivision">
    <router-link :to="`/admin/companies/${company.uuid}`">{{
      company?.name
    }}</router-link>
    › {{ companyDivision.name }}
  </span>
  <span
    class="inline-block h-3 w-24 transform animate-pulse rounded-lg bg-gray-200"
    v-else-if="isLoading"
  ></span>
  <span v-else-if="isError"
    ><i class="fas fa-exclamation-triangle text-orange-600" /> Bedrijfsdivisie
    niet gevonden</span
  >
</template>

<script lang="ts" setup>
import { toRef } from "vue";
import { useCompanyDivision } from "@/composables/companyDivision";

const props = defineProps<{ id: string }>();

const { company, companyDivision, isLoading, isError } = useCompanyDivision(
  toRef(props, "id"),
);
</script>
