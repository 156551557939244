import { useQueryClient, useQuery, useMutation } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref, unref } from "vue";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { z } from "zod";
import { useNotify } from "@/composables/notify";
import { consentSchema, ConsentType } from "@/models/Consent";

async function getLdfConsent(
  zisNumber: MaybeRef<number>,
): Promise<LdfConsent[]> {
  const result = await apiClient.get(
    `/patients/${unref(zisNumber)}/consent/ldf`,
  );

  return z.array(ldfConsentScheme).parse(result.data);
}

const ldfConsentScheme = consentSchema
  .omit({ referral_id: true, care_plan_id: true, letter_id: true })
  .extend({ action: z.enum(["ldf"]) });

export type LdfConsent = z.infer<typeof ldfConsentScheme>;

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useLdfConsent(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  function invalidateLdfConsent(zisNumber: number) {
    queryClient.invalidateQueries({ queryKey: ["ldf_consent", zisNumber] });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    retry: 1,
    queryKey: ["ldf_consent", zisNumber] as const,
    queryFn: ({ queryKey }) => getLdfConsent(queryKey[1]),
  });

  return { ...query, invalidateLdfConsent };
}

export function useUpdateLdfConsent(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  const { notify } = useNotify();

  const mutation = useMutation({
    mutationFn: (newType: ConsentType) =>
      mutateLdfConsent(zisNumber.value, newType),
    onMutate: async (newConsent) => {
      await queryClient.cancelQueries({
        queryKey: ["ldf_consent", zisNumber.value],
      });

      const previousConsent = queryClient.getQueryData([
        "ldf_consent",
        zisNumber.value,
      ]);

      queryClient.setQueryData(["ldf_consent", zisNumber.value], newConsent);

      return { previousConsent };
    },
    onError: (err, newTodo, context) => {
      notify({
        message: "Consent opslaan mislukt",
        type: "error",
      });
      queryClient.setQueryData(
        ["ldf_consent", zisNumber.value],
        context?.previousConsent,
      );
      queryClient.invalidateQueries({
        queryKey: ["ldf_consent", zisNumber.value],
      });
    },
    onSuccess: () => {
      notify({
        message: "Opslaan LDF consent gelukt",
        type: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["ldf_consent", zisNumber] });
    },
  });

  return {
    updateLdfConsent: (payload: ConsentType) =>
      mutation.mutateAsync(payload, {}),
    isMutating: mutation.isPending,
  };
}

async function mutateLdfConsent(zisNumber: number, newType: ConsentType) {
  await apiClient.patch(
    "/patients/:zis_number/consent",
    { type: newType, action: "ldf" },
    {
      params: {
        zis_number: zisNumber,
      },
    },
  );
}
