import { apiClient } from "@/libraries/utils/axios";

export async function setPatientDateOfDeath(
  command: ChangeOfDateOfDeathCommand,
): Promise<void> {
  await apiClient.patch(`/patients/${command.zisNumber}`, command.toApiBody());

  if (command.shouldSetInactive) {
    await apiClient.patch(`/patients/${command.zisNumber}/deactivate`);
  }

  return;
}

export class ChangeOfDateOfDeathCommand {
  constructor(
    public zisNumber: Readonly<number>,
    public dateOfDeath: Readonly<string | undefined>,
    public shouldSetInactive: Readonly<boolean>,
  ) {}

  public toApiBody() {
    return {
      date_of_death: this.dateOfDeath ?? null,
    };
  }
}
