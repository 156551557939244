<template>
  <span class="inline-flex items-baseline">
    <flux-tooltip>
      <template #content>
        <div class="flex flex-col">
          <div class="flex items-center gap-1" v-if="fluxUser">
            <UserAvatar :user="fluxUser" />
            <span>{{ fullNameInitials(fluxUser) }}</span>
          </div>
          <div class="space-x-1" v-if="specialismeCode">
            <span>{{ specialismeCode }}</span>
            <a
              class="font-semibold text-blue-400"
              v-if="vektisLink"
              :href="vektisLink"
              rel="noreferrer noopener"
              target="_blank"
              ><i class="fas fa-external-link-square"></i> Vektisregister</a
            >
          </div>
          <div v-else>
            <span>De AGB code van de verwijzer is incorrect</span>
          </div>
        </div>
      </template>
      {{ agbCode }}
    </flux-tooltip>
  </span>
</template>

<script setup lang="ts">
import { $t } from "@/libraries/i18n";
import store from "@/libraries/store";
import { fullNameInitials } from "@/models/Person";
import { computed, defineComponent } from "vue";
import UserAvatar from "../UserAvatar.vue";

const props = defineProps({
  agbCode: {
    type: String,
    required: true,
  },
});
defineComponent({ UserAvatar });

const validAgb = /^[0-9]{8}$/;
const specialismeCode = computed(() => {
  if (!validAgb.test(props.agbCode)) {
    return undefined;
  }
  try {
    return $t(`specialism_code.${props.agbCode.substring(0, 2)}`);
  } catch {
    return undefined;
  }
});

const fluxUser = computed(() => {
  return store.state.user?.healthcare_provider.users.find(
    (user) => user.AGB_code === props.agbCode,
  );
});

const vektisLink = computed(() => {
  if (!validAgb.test(props.agbCode)) {
    return undefined;
  }
  return `https://www.vektis.nl/agb-register/zorgverlener-${props.agbCode}`;
});

defineExpose({ specialismeCode, vektisLink, fluxUser, fullNameInitials });
</script>
