import { FluxStorage } from "./fluxStorage";
import { FluxStorageManager } from "./fluxStorageManager";
import IndexedDBStorage from "./indexedDBStorage";

export default class IndexedDBStorageManager implements FluxStorageManager {
  public async getScopedInstance<K extends IDBValidKey, T>(
    scopeName: string,
    userUuid: string,
  ): Promise<FluxStorage<K, T>> {
    return new IndexedDBStorage<K, T>(
      scopeName + "-" + userUuid,
      "autosaveData",
    );
  }
}
