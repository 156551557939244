import { Ref } from "vue";
import { QueryKeyFactory } from "../querykeys";

export const zorgvergoedingenQueryKeys = {
  zorgvergoedingen: (
    uzovi: Ref<string>,
    packageCode: Ref<string>,
    date: Ref<string>,
  ) => ["zorgvergoedingen", uzovi, packageCode, date] as const,
} as const satisfies QueryKeyFactory;

export const patientZorgvergoedingenQueryKeys = {
  patientZorgvergoedingen: (zisNumber: Ref<number>, date: Ref<string>) =>
    ["patientZorgvergoedingen", zisNumber, date] as const,
} as const satisfies QueryKeyFactory;
