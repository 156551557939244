import { User } from "@/models/User";
import moment from "moment";
import { $t } from "@/libraries/i18n";

export function getGreeting(user?: User) {
  if (user && getFirstNameOnly(user)) {
    return getDayPart() + ", " + getFirstNameOnly(user);
  }

  return getDayPart();
}

function getDayPart() {
  const hour = moment().hour();
  if (hour <= 5) {
    return $t("calendar.greeting.good_night");
  }
  if (hour <= 11) {
    return $t("calendar.greeting.good_morning");
  }
  if (hour <= 17) {
    return $t("calendar.greeting.good_afternoon");
  }
  return $t("calendar.greeting.good_evening");
}

function getFirstNameOnly(user: User): string | undefined {
  return user.first_names?.split(" ")[0];
}
