import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";

const addressResponseSchema = z.object({
  street_name: z.string().optional(),
  city: z.string().optional(),
});

type AddressResponse = z.infer<typeof addressResponseSchema>;

export async function fetchAddress(
  postalCode: string,
): Promise<AddressResponse> {
  const { data } = await apiClient.get("/address/:postal_code", {
    params: {
      postal_code: postalCode,
    },
  });

  return addressResponseSchema.parse(data);
}
