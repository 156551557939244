import { handledZodParse } from "@/libraries/utils/errorHandling";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref, unref } from "vue";
import { z } from "zod";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { Patient, patientScheme, referralSchema, usePatient } from "./patient";

async function getPatientWithoutMedicalData(
  zisNumber: MaybeRef<number>,
): Promise<PatientWithoutMedicalData> {
  const result = await apiClient.get(
    `/patients/${unref(zisNumber)}/without_medical_data`,
  );
  return handledZodParse({
    schema: patientWithoutMedicalDataScheme,
    input: result.data,
    notifyUser: true,
  });
}

const patientWithoutMedicalDataScheme = patientScheme
  .pick({
    zis_number: true,
    gender: true,
    initials: true,
    first_names: true,
    surname: true,
    date_of_birth: true,
    date_of_death: true,
    deceased: true,
    surname_prefix: true,
    maiden_name: true,
    maiden_name_prefix: true,
    nickname: true,
    multiple_birth: true,
    email_addresses: true,
    phone_numbers: true,
    addresses: true,
    languages: true,
    contacts: true,
    uuid: true,
    company_division_id: true,
    employee_number: true,
    nationalities: true,
    photo_filename: true,
    labels: true,
    inactive_at: true,
    national_identity_number_type: true,
    patient_title_uuid: true,
    custom_short_title: true,
    custom_long_title: true,
    salutation_template_uuid: true,
    custom_salutation: true,
    patient_treatment_counts: true,
  })
  .extend({
    referrals: z.array(
      referralSchema.pick({
        id: true,
        hcp_diagnosis: true,
        closed_at: true,
        is_red_flagged: true,
        is_draft: true,
      }),
    ),
  });

export type PatientWithoutMedicalData = z.infer<
  typeof patientWithoutMedicalDataScheme
>;

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function usePatientWithoutMedicalData(zisNumber: Ref<number>) {
  if (hasPermission("view-patient")) {
    return usePatient(zisNumber);
  }
  const queryClient = useQueryClient();
  function invalidatePatient(zisNumber: number) {
    return queryClient.invalidateQueries({
      queryKey: ["patientWithoutMedicalData", zisNumber],
    });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    queryKey: ["patientWithoutMedicalData", zisNumber] as const,
    queryFn: ({ queryKey }) => getPatientWithoutMedicalData(queryKey[1]),
    enabled: hasPermission("view-patient-without-medical-data"),
  });

  return { ...query, invalidatePatient };
}

export function patientHasMedicalData(
  patient: PatientWithoutMedicalData | Patient,
): patient is Patient {
  const keys: Exclude<keyof Patient, keyof PatientWithoutMedicalData>[] = [
    "documents",
    "payer_insurers",
  ];
  return keys.every((key) => key in patient);
}
