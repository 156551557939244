import {
  DisplayItem,
  Extension,
  EXTENSION,
  VALUE_SET,
} from "@/libraries/questionnaires/item";

export const createBoundLabel = (
  type: "lower" | "upper",
  text: string,
): DisplayItem => {
  return {
    linkId: Math.random().toString(),
    type: "display",
    extension: [
      {
        url: EXTENSION.ITEM_CONTROL,
        valueCodeableConcept: {
          coding: [
            {
              system: VALUE_SET.ITEM_CONTROL,
              code: type,
            },
          ],
        },
      },
    ],
    text,
  };
};

export function ExtensionHasCode(
  extension: Extension,
  url: string,
  code: string,
): boolean {
  if (extension.url !== url) {
    return false;
  }

  return (
    extension.valueCodeableConcept?.coding.some(
      (coding) => coding.code === code,
    ) ?? false
  );
}
