/**
 * Wrapper NProgress with style
 */

import nprogress from "nprogress";

// config
nprogress.configure({
  minimum: 0.1,
  easing: "ease",
  speed: 250,
  trickle: true,
  trickleSpeed: 100,
  showSpinner: true,
});

export default nprogress;
