<template>
  <div
    class="mx-auto flex w-full max-w-5xl flex-col gap-2"
    v-flux-loading="isLoading || isFetching"
  >
    <flux-card-button-header title="Werklijst" icon="fal fa-plus">
      <template #action>
        <flux-select
          class="max-w-72"
          v-if="hasOwnerView"
          v-model:modelValue="selectedUserId"
        >
          <option
            v-for="user in selectableUsers"
            :value="user.id"
            :label="fullNameInitials(user)"
          >
            {{ fullNameInitials(user) }}
          </option>
        </flux-select>
      </template>
    </flux-card-button-header>
    <flux-card class="dark:bg-neutral-900" shadow="never" overflows>
      <template v-if="items === undefined"></template>
      <div
        class="p-4"
        v-else-if="items.length === 0 && !(isLoading || isFetching)"
      >
        <EmptyState text="Je werklijst is helemaal bijgewerkt.">
          <template #image>
            <div class="flex flex-row items-center justify-center gap-2">
              <svg
                class="text-green-700"
                xmlns="http://www.w3.org/2000/svg"
                width="40px"
                height="40px"
                viewBox="0 0 24 24"
                fill="none"
                alt="succes icon"
              >
                <circle
                  opacity="0.8"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
                <path
                  d="M8.5 12.5L10.5 14.5L15.5 9.5"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <h1 class="text-4xl font-normal">Klaar</h1>
            </div>
            <img class="h-48" src="@/assets/images/high_five.svg" />
          </template>
        </EmptyState>
      </div>
      <PaginatedTable v-else :data="items" :paginator="paginator" compact>
        <flux-table-column prop="description" label="Omschrijving" primary />
        <flux-table-column prop="patient_zis_number" label="Patiënt">
          <template #row="task: WorkListItem">
            <AsyncPatientLink
              v-if="task.patient_zis_number"
              :zis-number="task.patient_zis_number"
            />
          </template>
        </flux-table-column>
        <flux-table-column prop="date" label="Datum" align="right">
          <template #row="task: WorkListItem">
            <time
              class="text-gray-400 dark:text-neutral-300"
              v-if="task.date"
              >{{ dateHumanFilter(task.date) }}</time
            >
          </template>
        </flux-table-column>
        <flux-table-column prop="na" label="">
          <template #row="task: WorkListItem">
            <WorkListAction
              :actions="task.actions"
              @execute="executeCommand($event)"
            />
          </template>
        </flux-table-column>
      </PaginatedTable>
    </flux-card>
  </div>
</template>

<script setup lang="ts">
import {
  WorkListActionCommand,
  WorkListItem,
  useWorkListUserItems,
} from "@/composables/workList";
import { dateHumanFilter } from "@/filters";
import { getUser } from "@/libraries/plugins/getUser";
import { apiClient } from "@/libraries/utils/axios";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import WorkListAction from "./WorkListAction.vue";
import AsyncPatientLink from "../ui/AsyncPatientLink.vue";
import PaginatedTable from "../ui/Table/PaginatedTable.vue";
import { fullNameInitials } from "@/models/Person";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import EmptyState from "@/components/ui/EmptyState.vue";

const router = useRouter();

const hasOwnerView = computed(
  () =>
    hasFeatureFlag("work-list-owner") &&
    getUser().roles.some((role) => role.name === "owner"),
);
const selectedUserId = ref(getUser().id);
const selectableUsers = computed(() => {
  return getUser()
    .healthcare_provider.users.filter((user) => user.active)
    .filter((user) => user.roles?.some((r) => r.name === "doctor") ?? false)
    .toSorted((a, b) => a.surname?.localeCompare(b.surname ?? "") ?? 0);
});

const queryOptions = computed(() => {
  return {
    userId: selectedUserId.value,
  };
});
const {
  paginationData: items,
  paginator,
  invalidate,
  isLoading,
  isFetching,
} = useWorkListUserItems(queryOptions);

async function executeCommand(command: WorkListActionCommand) {
  if (command.type === "api") {
    await apiClient.request({
      method: command.method.toLowerCase(),
      url: command.url,
      data: command.data,
    });
    invalidate();
  } else if (command.type === "navigate") {
    router.push({
      name: command.name,
      params: command.data,
      hash: command.hash,
    });
  }
}
</script>
