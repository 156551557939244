<template>
  <flux-tooltip
    :content="content || $t('icon.' + (description ? description : icon))"
    :disabled="hideTooltip || false"
    :openDelay="delay"
    @show="increaseOpenedTooltips"
    @hide="decreaseOpenedTooltips"
  >
    <i :class="['fal', icon, 'clickable-icon']"></i>
  </flux-tooltip>
</template>

<script setup lang="ts">
import { useClickableIconDelays } from "@/composables/clickableIcons";
import { $t } from "@/libraries/i18n";

const { delay, increaseOpenedTooltips, decreaseOpenedTooltips } =
  useClickableIconDelays();

defineProps<{
  icon: string;
  description?: string;
  content?: string;
  hideTooltip?: boolean;
}>();
</script>
