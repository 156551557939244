import {
  InvoiceApiBody,
  invoiceTypes,
  StoreCompanyProductInvoiceApiBody,
  StorePatientProductInvoiceApiBody,
} from "@/apis/patients/invoices";
import { declarationStatus } from "@/models/Declaration";
import {
  CollectionInvoiceApi,
  Invoice,
  InvoiceApi,
  invoiceFilterStatus,
  invoiceStatus,
  InvoiceStatus,
} from "@/models/Invoice";
import { InjectionKey } from "vue";
import { z } from "zod";
import { MessageBag } from "../utils/MessageBag";
import { laravelLengthAwarePaginatorScheme } from "../paginators/usePaginator";
import { EmailSentType, emailSentTypes } from "@/models/EmailSent";

const emailSentScheme = z.object({
  id: z.number(),
  type: z.enum(emailSentTypes),
  email: z.string(),
  patient_zis_number: z.optional(z.number()),
  user_id: z.optional(z.number()),
  appointment_id: z.optional(z.number()),
  invoice_id: z.optional(z.number()),
  metadata: z.unknown(),
  created_at: z.string().transform((z) => new Date(z)),
  updated_at: z.string().transform((z) => new Date(z)),
});

export const invoiceSummarySchema = z.object({
  id: z.number(),
  patient_zis_number: z.number().optional(),
  status: z.enum(invoiceStatus),
  type: z.enum(invoiceTypes),
  collection_invoice_id: z.number().optional(),
});

export type InvoiceSummary = z.infer<typeof invoiceSummarySchema>;

export const invoiceDetailSchema = z.object({
  id: z.number(),
  user_id: z.number(),
  patient_zis_number: z.optional(z.number()),
  prices_include_tax: z.boolean(),
  pay_date: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  number: z.optional(z.string()),
  type: z.enum(invoiceTypes),
  date: z.optional(z.string()).transform((z) => (z ? new Date(z) : undefined)),
  created_at: z.string().transform((z) => (z ? new Date(z) : undefined)),
  updated_at: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  status: z.enum(invoiceStatus),
  deleted_at: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  correcting_invoice_id: z.optional(z.number()),
  slug: z.optional(z.string()),
  filter_status: z.enum(invoiceFilterStatus),
  uzovi: z.optional(z.string()),
  period_start: z.optional(z.string()),
  period_end: z.optional(z.string()),
  credits_invoice_id: z.optional(z.number()),
  price_inc_vat: z.number(),
  collection_invoice_id: z.optional(z.number()),
  collection_invoice_filter_status: z.optional(z.enum(invoiceFilterStatus)),
  company_administration_id: z.optional(z.string()),
  company_id: z.optional(z.string()),
  total_unpaid: z.number(),
  void_date: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  finalised_date: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  corrections: z.array(
    z.object({
      id: z.number(),
      patient_zis_number: z.number(),
      email_sents: z.array(z.any()),
      correcting_invoice_id: z.number(),
    }),
  ),
  invoice_entries: z.array(
    z.object({
      id: z.number(),
      product_code: z.optional(z.string()),
      credit_reference: z.optional(z.string()),
      amount: z.number(),
      amount_text: z.optional(z.string()),
      tax_rate_id: z.string(),
      description: z.string(),
      price: z.number(),
      date_treatment: z.optional(z.string()),
      prestatiecode: z.optional(z.string()),
      user_id: z.optional(z.number()),
      csi_code: z.optional(z.string()),
      reference: z.string(),
      agb_code: z.optional(z.string()),
      tax_rate_percent: z.number(),
      total_price: z.number(),
      patient_zis_number: z.optional(z.number()),
      appointment_uuid: z.optional(z.string()),
    }),
  ),
  invoice_payments: z.array(
    z.object({
      metadata: z.any(),
    }),
  ),
  invoice_events: z.array(z.any()),
  invoice_sents: z.array(z.any()),
  declarations: z.array(
    z.object({
      id: z.number(),
      status: z.enum(declarationStatus),
    }),
  ),
  email_sents: z.array(emailSentScheme),
  patient: z.optional(
    z.object({
      zis_number: z.number(),
      maiden_name: z.optional(z.string()),
      maiden_name_prefix: z.optional(z.string()),
      surname: z.optional(z.string()),
      surname_prefix: z.optional(z.string()),
      first_names: z.optional(z.string()),
      initials: z.optional(z.string()),
    }),
  ),
  user: z.object({
    id: z.number(),
    maiden_name: z.optional(z.string()),
    maiden_name_prefix: z.optional(z.string()),
    surname: z.string(),
    surname_prefix: z.optional(z.string()),
    first_names: z.string(),
    initials: z.string(),
  }),
  price_ex_vat: z.number(),
  hcp_agb_id: z.optional(z.number()),
  hcp_kvk_id: z.number(),
  paid_in_advance: z.boolean(),
  total_paid: z.number(),
});

const invoiceListEntrySchema = z.object({
  id: z.number(),
  user_id: z.number(),
  patient_zis_number: z.optional(z.number()),
  company_id: z.optional(z.string()),
  pay_date: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  number: z.optional(z.string()),
  date: z.optional(z.string()).transform((z) => (z ? new Date(z) : undefined)),
  created_at: z.string().transform((z) => (z ? new Date(z) : undefined)),
  updated_at: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  status: z.enum(invoiceStatus),
  type: z.enum(invoiceTypes),
  deleted_at: z
    .optional(z.string())
    .transform((z) => (z ? new Date(z) : undefined)),
  correcting_invoice_id: z.optional(z.number()),
  slug: z.optional(z.string()),
  filter_status: z.enum(invoiceFilterStatus),
  uzovi: z.optional(z.string()),
  period_start: z.optional(z.string()),
  period_end: z.optional(z.string()),
  is_processed: z.optional(z.boolean()),
  credits_invoice_id: z.optional(z.number()),
  price_inc_vat: z.number(),
  collection_invoice_id: z.optional(z.number()),
  collection_invoice_filter_status: z.optional(z.enum(invoiceFilterStatus)),
  total_unpaid: z.number(),
  corrections: z.array(
    z.object({
      id: z.number(),
      patient_zis_number: z.number(),
      email_sents: z.array(z.any()),
      correcting_invoice_id: z.number(),
    }),
  ),
  invoice_entries: z.array(
    z.object({
      id: z.number(),
      credit_reference: z.optional(z.string()),
    }),
  ),
  invoice_payments: z.array(
    z.object({
      metadata: z.any(),
    }),
  ),
  invoice_sents: z.array(z.any()),
  declarations: z.array(
    z.object({
      id: z.number(),
      status: z.enum(declarationStatus),
    }),
  ),
  email_sents: z.array(emailSentScheme),
  patient: z.optional(
    z.object({
      zis_number: z.number(),
      maiden_name: z.optional(z.string()),
      maiden_name_prefix: z.optional(z.string()),
      surname: z.optional(z.string()),
      surname_prefix: z.optional(z.string()),
      first_names: z.optional(z.string()),
      initials: z.optional(z.string()),
    }),
  ),
  user: z.object({
    id: z.number(),
    maiden_name: z.optional(z.string()),
    maiden_name_prefix: z.optional(z.string()),
    surname: z.string(),
    surname_prefix: z.optional(z.string()),
    first_names: z.string(),
    initials: z.string(),
  }),
  referral_id: z.number().optional(),
});

export type InvoiceListEntry = z.infer<typeof invoiceListEntrySchema>;

export type InvoiceWithDetails = z.infer<typeof invoiceDetailSchema>;

export const responseSchema = laravelLengthAwarePaginatorScheme(
  invoiceListEntrySchema,
);

export type PaginationData = {
  first_page_url: string;
  from?: number;
  last_page: number;
  last_page_url: string;
  next_page_url?: string;
  path: string;
  per_page: number;
  prev_page_url?: string;
  current_page: number;
  to?: number;
  total: number;
};

type InvoiceId = { id: number };

export const invoiceRepositoryKey = Symbol() as InjectionKey<
  () => InvoiceRepository
>;
export interface InvoiceRepository {
  findAllOnPage(
    url: string,
    params: any,
  ): Promise<{ data: InvoiceListEntry[] } & PaginationData>;

  findForPatient(
    patientZisNumber: number,
    invoiceId: number,
  ): Promise<InvoiceApi>;

  findById(invoiceId: number): Promise<InvoiceWithDetails | undefined>;

  createForPatient(
    patientZisNumber: number,
    invoice: InvoiceApiBody,
    partialInvoiceReferenceDate?: Date,
  ): Promise<InvoiceId | MessageBag | Error>;

  createProductInvoice(
    invoice:
      | StoreCompanyProductInvoiceApiBody
      | StorePatientProductInvoiceApiBody,
  ): Promise<InvoiceSummary | MessageBag | Error>;

  updateStatus(invoice: number, newStatus: InvoiceStatus): Promise<InvoiceApi>;

  updateForPatient(
    invoiceId: number,
    patientZisNumber: number,
    invoice: InvoiceApiBody,
  ): Promise<InvoiceSummary>;

  update(
    invoiceId: number,
    invoice:
      | InvoiceApiBody
      | StoreCompanyProductInvoiceApiBody
      | StorePatientProductInvoiceApiBody,
  ): Promise<InvoiceSummary | MessageBag | Error>;

  download(invoice: Invoice): Promise<void>;

  send(invoice: Invoice): Promise<void>;

  validate(invoice: CollectionInvoiceApi): Promise<void>;

  declare(invoice: CollectionInvoiceApi): Promise<void>;

  updatePeriod(
    invoice: Invoice,
    period_start: string,
    period_end: string,
  ): Promise<void>;
}
