import { getUser } from "@/libraries/plugins/getUser";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { Task } from "@/models/Task";
import {
  fetchActiveUserTasks,
  fetchTasks,
  saveTask,
} from "@/queries/tasks/tasksQuery";
import { tasksQueryKeys } from "@/queries/tasks/tasksQueryKeys";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed } from "vue";
import { ref } from "vue";

export function useMyTasks() {
  const query = useQuery({
    queryKey: tasksQueryKeys.activeUserTasks(ref(getUser().id)),
    queryFn: ({ queryKey }) => fetchActiveUserTasks(queryKey[2]),
    staleTime: 1000 * 60,
    gcTime: Infinity,
    refetchOnWindowFocus: true,
    enabled: hasPermission("view-task"),
  });

  const queryClient = useQueryClient();

  function invalidate() {
    queryClient.invalidateQueries({
      queryKey: tasksQueryKeys.activeUserTasks(ref(getUser().id)),
    });
  }

  const hasOpenTasks = computed(
    () => query.data.value !== undefined && query.data.value?.length > 0,
  );

  return {
    ...query,
    invalidate,
    hasOpenTasks,
  };
}

export function useTasks() {
  const query = useQuery({
    queryKey: tasksQueryKeys.allTasks(),
    queryFn: () => fetchTasks(),
    staleTime: 1000 * 60,
    gcTime: Infinity,
    refetchOnWindowFocus: true,
    enabled: hasPermission("view-task"),
  });

  const queryClient = useQueryClient();

  function invalidate() {
    queryClient.invalidateQueries({
      queryKey: tasksQueryKeys.allTasks(),
    });
  }

  return {
    ...query,
    invalidate,
  };
}

export function useUpdateTask() {
  const queryClient = useQueryClient();

  function invalidate() {
    queryClient.invalidateQueries({
      queryKey: tasksQueryKeys.allTasks(),
    });
  }

  const mutation = useMutation({
    mutationFn: (task: Task) => saveTask(task),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: tasksQueryKeys.allTasks() });
    },
  });

  return {
    ...mutation,
    invalidate,
  };
}
