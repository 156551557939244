import { z } from "zod";

export const laravelPaginatorScheme = z.object({
  current_page: z.number(),
  per_page: z.number(),
  last_page: z.number().optional(),
  total: z.number().optional(),
  next_page_url: z.string().optional(),
});

export const lengthAwarePagePaginatorScheme = laravelPaginatorScheme.extend({
  last_page: z.number(),
  total: z.number(),
});

export const pagePaginatorScheme = laravelPaginatorScheme.extend({
  last_page: z.undefined(),
  total: z.undefined(),
});

export type LaravelPaginator = z.infer<typeof laravelPaginatorScheme>;
export type LengthAwarePagePaginator = z.infer<
  typeof lengthAwarePagePaginatorScheme
>;
export type PagePaginator = z.infer<typeof pagePaginatorScheme>;

export type Paginator<P extends LaravelPaginator = LaravelPaginator> = P & {
  next: () => void;
  prev: () => void;
};

export function paginatorIsLengthAware(
  paginator: Paginator,
): paginator is Paginator<LengthAwarePagePaginator> {
  return paginator.total !== undefined;
}

export function paginatorIsNotLengthAware(
  paginator: Paginator,
): paginator is Paginator<PagePaginator> {
  return paginator.total === undefined;
}
