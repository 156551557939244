import { PatientTreatmentCount } from "./../libraries/repositories/PatientTreatmentCount/patientTreatmentCount";
import { Address } from "./Address";
import { PayerInsurerPersisted } from "./PayerInsurer";
import { Contact } from "./Contact";
import { Nationality } from "./Nationality";
import { ReferralPersisted } from "./Referral";
import { Appointment } from "./Appointment";
import { Language } from "./Language";
import { PhoneNumberPersisted } from "./PhoneNumber";
import { InvoiceType } from "@/apis/patients/invoices";
import { EmailAddressPersisted } from "./EmailAddress";
import { PatientLabel } from "@/models/PatientLabel";
import { Patient as ComposablePatient } from "@/composables/patient";

export const genderTypes = ["UN", "M", "F", "UNK"] as const;

export type GenderType = (typeof genderTypes)[number];

export type Patient = {
  zis_number: number;
  first_names?: string;
  initials?: string;
  surname?: string;
  surname_prefix?: string;
  maiden_name?: string;
  maiden_name_prefix?: string;
  gender?: GenderType;
  multiple_birth?: boolean;
  date_of_birth?: string;
  patient_title_uuid?: string;
  custom_short_title?: string;
  custom_long_title?: string;
  salutation_template_uuid?: string;
  custom_salutation?: string;
  deceased?: boolean;
  date_of_death?: Date;
  nickname?: string;
  addresses?: Address[];
  contacts?: Contact[];
  nationalities?: Nationality[];
  languages?: Language[];
  referrals?: ReferralPersisted[];
  photo_filename?: string;
  company_division_id?: string;
  employee_number?: string;
  patient_treatment_counts?: PatientTreatmentCount[];
  invoice_settings?: PatientInvoiceSettings;
  readonly created_at?: Date;
  readonly updated_at?: Date;
  labels?: PatientLabel[];
  inactive_at?: string;
};

export interface PatientLocalCache {
  zis_number: number;
  first_names: string;
  initials?: string;
  surname: string;
  surname_prefix?: string;
  maiden_name?: string;
  maiden_name_prefix?: string;
  gender?: GenderType;
  multiple_birth?: boolean;
  date_of_birth?: string;
  deceased?: boolean;
  date_of_death?: Date;
  nickname?: string;
  photo_filename?: string;
  company_division_id?: string;
  labels?: PatientLabel[];
  languages: Language[];
  wallet_uuid?: string;
}

export type PatientApi = Patient & {
  first_names: string;
  surname: string;
  zis_number: number;
  contacts: Contact[];
  addresses: Address[];
  payer_insurers: PayerInsurerPersisted[];
  phone_numbers: PhoneNumberPersisted[];
  nationalities: Nationality[];
  referrals: ReferralPersisted[];
  appointments: Appointment[];
  email_addresses: EmailAddressPersisted[];
  suppressed_email_types: string[];
  labels?: PatientLabel[];
};

export type PatientInvoiceSettings = {
  default_invoice_type?: InvoiceType | null;
};

export function isPatient(
  object: unknown,
): object is Patient | PatientApi | ComposablePatient {
  if (!(typeof object === "object") || object == null) {
    return false;
  }

  return (
    "zis_number" in object && typeof (object as any).zis_number === "number"
  );
}
