<template>
  <div class="grid grid-cols-[max-content,1fr] items-center gap-1">
    <div>Question</div>
    <div><flux-input v-model="item.text"></flux-input></div>
    <div>Lower bound</div>
    <div><flux-input v-model="lowerBound"></flux-input></div>
    <div>Upper bound</div>
    <div><flux-input v-model="upperBound"></flux-input></div>
    <div>min Value</div>
    <div><flux-input v-model="minValue"></flux-input></div>
    <div>max Value</div>
    <div><flux-input v-model="maxValue"></flux-input></div>
  </div>
</template>

<script lang="ts" setup>
import {
  DisplayItem,
  EXTENSION,
  IntegerItem,
} from "@/libraries/questionnaires/item";
import { computed } from "vue";
import { createBoundLabel, ExtensionHasCode } from "./helpers";

const { item } = defineProps<{
  item: IntegerItem;
}>();

const bounds = computed(() => {
  if (!Array.isArray(item.extension)) {
    return [-Infinity, Infinity];
  }
  const minValue =
    item.extension?.find((ex) => ex.url === EXTENSION.MIN_VALUE)
      ?.valueDecimal ?? -Infinity;

  const maxValue =
    item.extension?.find((ex) => ex.url === EXTENSION.MAX_VALUE)
      ?.valueDecimal ?? Infinity;

  return [minValue, maxValue];
});

function setMinMaxValue(val: number, type: "MIN_VALUE" | "MAX_VALUE") {
  if (!item.extension) {
    item.extension = [];
  }

  if (item.extension.find((ex) => ex.url === EXTENSION[type])) {
    item.extension.find((ex) => ex.url === EXTENSION[type])!.valueDecimal = val;
  } else {
    item.extension.push({
      url: EXTENSION[type],
      valueDecimal: val,
    });
  }
}

const minValue = computed({
  get: () => bounds.value[0],
  set: (val: number) => {
    setMinMaxValue(val, "MIN_VALUE");
  },
});

const maxValue = computed({
  get: () => bounds.value[1],
  set: (val: number) => {
    setMinMaxValue(val, "MAX_VALUE");
  },
});

const lowerBound = computed({
  get: () => findBoundingChildItem("lower")?.text ?? "",
  set: (val: string) => {
    if (findBoundingChildItem("lower") === undefined) {
      if (item.item === undefined) {
        item.item = [];
      }
      item.item.push(createBoundLabel("lower", val));
    } else {
      findBoundingChildItem("lower")!.text = val;
    }
  },
});

const upperBound = computed({
  get: () => findBoundingChildItem("upper")?.text ?? "",
  set: (val: string) => {
    if (findBoundingChildItem("upper") === undefined) {
      if (item.item === undefined) {
        item.item = [];
      }
      item.item.push(createBoundLabel("upper", val));
    } else {
      findBoundingChildItem("upper")!.text = val;
    }
  },
});

function findBoundingChildItem(
  bound: "upper" | "lower",
): DisplayItem | undefined {
  const boundItem = item.item?.find((item) => {
    if (item.extension === undefined) {
      return;
    }
    const extension = item.extension;
    return extension?.some((extension) =>
      ExtensionHasCode(extension, EXTENSION.ITEM_CONTROL, bound),
    );
  });

  if (boundItem?.type !== "display") {
    return undefined;
  }
}
</script>
