import { sbvzSearch } from "@/queries/sbvzSearch/sbvzSearchQuery";
import { useNotify } from "./notify";
import { $t } from "@/libraries/i18n";

export function useSbvzSearch() {
  const { notify } = useNotify();

  async function executeSbvzSearch(bsn?: string, zisNumber?: number) {
    const res = await sbvzSearch(bsn, zisNumber);
    if (res.success === false) {
      notify({
        title: $t("error.general"),
        type: "error",
        message: res.messages.join("\n"),
      });
      return;
    }
    return res.result;
  }

  return {
    executeSbvzSearch,
  };
}
