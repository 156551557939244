import { Appointment, Participant } from "@/models/Appointment";
import { useShareData } from "./shareData";
import { Patient } from "@/models/Patient";
import { AppointmentApi } from "@/libraries/repositories/appointmentRepositoryUsingApi";

export function useShareAppointment() {
  const { readData, saveData } = useShareData("appointment");

  const readAppointment = (
    patient: Patient | undefined = undefined,
  ): Appointment | AppointmentApi | undefined => {
    if (patient) {
      const appointment = readData();
      if (
        patient.zis_number ===
        appointment.participants.find((p: Participant) => p.patient_zis_number)
      ) {
        return appointment;
      }
      return undefined;
    }
    return readData();
  };

  const saveAppointment = (appointment: Appointment | AppointmentApi) => {
    saveData(appointment);
  };

  return { readAppointment, saveAppointment };
}
