import { apiClient } from "@/libraries/utils/axios";
import {
  VektisHealthcareProfessionalAgb,
  vektisHealthcareProfessionalAgbScheme,
} from "@/models/VektisHealthcareProfessionalAgb";

export async function lookupAgbCode(
  agbCode: string,
): Promise<VektisHealthcareProfessionalAgb | undefined> {
  try {
    return apiClient
      .post(`/vektis_healthcare_professionals/${agbCode}`)
      .then((res) => {
        if (res.data.length === 0) {
          return;
        }
        const result = vektisHealthcareProfessionalAgbScheme.parse(res.data);
        return result;
      });
  } catch {
    return undefined;
  }
}
