<template>
  <div v-if="isEmpty()">
    <div class="whitespace-pre-line break-words">-</div>
  </div>
  <displayProseMirror
    v-else-if="encoding === 'json-prosemirror' && typeof content === 'object'"
    :json="content"
  />
  <div class="whitespace-pre-line break-words" v-else>{{ content }}</div>
</template>

<script lang="ts" setup>
import { Encoding } from "@/models/MedicalUpdate";
import { JSONContent } from "@tiptap/vue-3";
import displayProseMirror from "@/components/ui/displayProseMirror.vue";

const props = defineProps<{
  encoding: Encoding;
  content: string | JSONContent;
}>();

function isEmpty() {
  if (props.content == undefined) {
    return true;
  }

  if (typeof props.content === "string") {
    return props.content === "";
  }

  if (!props.content.content) {
    return true;
  }

  return !props.content.content.some((content) => content.content);
}
</script>
