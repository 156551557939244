import { ref } from "vue";
import { defineStore } from "pinia";
import { apiClient } from "../utils/axios";
import { Location } from "@/models/Location";

export const useLocationStore = defineStore("locations", () => {
  const cache = ref<Promise<Location[]>>();

  async function findAll(): Promise<Location[]> {
    cache.value ??= apiClient
      .get("/locations")
      .then(({ data }) => data.locations);

    return (await cache.value) ?? [];
  }

  function $reset() {
    cache.value = undefined;
  }

  return { findAll, $reset };
});
