<template>
  <div class="grid grid-cols-[max-content,1fr] items-center gap-1">
    <div>Question</div>
    <div><flux-input v-model="item.text"></flux-input></div>
    <div>Lower bound</div>
    <div><flux-input v-model="lowerBound"></flux-input></div>
    <div>Upper bound</div>
    <div><flux-input v-model="upperBound"></flux-input></div>
    <div>min Value</div>
    <div><flux-input v-model="minValue"></flux-input></div>
    <div>max Value</div>
    <div><flux-input v-model="maxValue"></flux-input></div>
    <div>Score mode</div>
    <div>
      <select v-model="scoreMode">
        <option v-for="scoreMode in scoreModes" :key="scoreMode">
          {{ scoreMode }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  DecimalItem,
  DisplayItem,
  EXTENSION,
  getExtension,
} from "@/libraries/questionnaires/item";
import { createBoundLabel, ExtensionHasCode } from "./helpers";
import { computed } from "vue";

const scoreModes = ["closest_node", "interval"] as const;
type ScoreMode = (typeof scoreModes)[number];

const props = defineProps<{
  item: DecimalItem;
}>();

function findBoundingChildItem(
  bound: "upper" | "lower",
): DisplayItem | undefined {
  const boundItem = props.item.item?.find((item) =>
    item.extension?.some((extension) =>
      ExtensionHasCode(extension, EXTENSION.ITEM_CONTROL, bound),
    ),
  );

  if (boundItem?.type !== "display") {
    return undefined;
  }

  return boundItem;
}

const scoreMode = computed({
  get: () => {
    return "interval";
  },
  set: (mode: ScoreMode) => {
    const extension = getExtension(props.item, {
      url: EXTENSION.VAS_SCORE_MODE,
    });

    if (props.item.extension === undefined) {
      props.item.extension = [];
    }

    if (extension === undefined) {
      props.item.extension.push({
        url: EXTENSION.VAS_SCORE_MODE,
        valueCodeableConcept: {
          coding: [
            {
              code: mode,
            },
          ],
        },
      });
    } else {
      extension.valueCodeableConcept!.coding[0].code = mode;
    }
  },
});

const lowerBound = computed({
  get: () => {
    return findBoundingChildItem("lower")?.text ?? "";
  },
  set: (val: string) => {
    if (findBoundingChildItem("lower") === undefined) {
      if (props.item.item === undefined) {
        props.item.item = [];
      }
      props.item.item.push(createBoundLabel("lower", val));
    } else {
      findBoundingChildItem("lower")!.text = val;
    }
  },
});

const upperBound = computed({
  get: () => {
    return findBoundingChildItem("upper")?.text ?? "";
  },
  set: (val: string) => {
    if (findBoundingChildItem("upper") === undefined) {
      if (props.item.item === undefined) {
        props.item.item = [];
      }
      props.item.item.push(createBoundLabel("upper", val));
    } else {
      findBoundingChildItem("upper")!.text = val;
    }
  },
});

const bounds = computed((): [number, number] => {
  if (!Array.isArray(props.item.extension)) {
    return [-Infinity, Infinity];
  }
  const minValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MIN_VALUE)
      ?.valueDecimal ?? -Infinity;

  const maxValue =
    props.item.extension?.find((ex) => ex.url === EXTENSION.MAX_VALUE)
      ?.valueDecimal ?? Infinity;

  return [minValue, maxValue];
});

const minValue = computed({
  get: () => bounds.value[0].toString(),
  set: (val: string) => {
    setMinMaxValue(parseInt(val, 10), "MIN_VALUE");
  },
});

const maxValue = computed({
  get: () => bounds.value[1].toString(),
  set: (val: string) => {
    setMinMaxValue(parseInt(val, 10), "MAX_VALUE");
  },
});

function setMinMaxValue(val: number, type: "MIN_VALUE" | "MAX_VALUE") {
  if (!props.item.extension) {
    props.item.extension = [];
  }

  if (props.item.extension.find((ex) => ex.url === EXTENSION[type])) {
    props.item.extension.find(
      (ex) => ex.url === EXTENSION[type],
    )!.valueDecimal = val;
  } else {
    props.item.extension.push({
      url: EXTENSION[type],
      valueDecimal: val,
    });
  }
}
</script>
