import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { patientLabelQueryKeys } from "@/queries/patientLabelKeys";
import { getPatientLabels } from "@/queries/patientLabels";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

export function usePatientLabels() {
  const invalidate = useInvalidatePatientLabels();

  const query = useQuery({
    staleTime: 3600,
    queryKey: patientLabelQueryKeys.patientLabels(),
    queryFn: ({ queryKey }) => getPatientLabels(),
    enabled: hasFeatureFlag("patient-labels"),
  });

  return { ...query, invalidate };
}

export function useInvalidatePatientLabels() {
  const queryClient = useQueryClient();

  function invalidatePatientLabels() {
    queryClient.invalidateQueries({
      queryKey: patientLabelQueryKeys.patientLabels(),
    });
  }

  return invalidatePatientLabels;
}
