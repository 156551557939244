/**
 * Utils
 */

import * as axios from "./axios";
import nprogress from "./nprogress";
import moment, { Moment } from "moment";
export { axios, nprogress };

export const addMomentToModel = <
  T extends { [index in K]: string | Date },
  K extends keyof T,
>(
  keys: K[],
  obj: T,
): T & { [index in K]: Moment } => {
  const keysMapped = keys.reduce(
    (acc, key) => {
      acc[key] = moment(obj[key]);
      return acc;
    },
    {} as { [index in K]: Moment },
  );

  return { ...obj, ...keysMapped };
};

export const getInitialsFromFirstNames = (firstNames: string): string => {
  return firstNames
    .split(" ")
    .filter((name) => name.length > 0)
    .map((name) => `${name[0].toUpperCase()}.`)
    .join("");
};

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function capitalizeFirstLetter(name: string): string {
  const head = name.substring(0, 1);
  const tail = name.substring(1);

  return `${head.toLocaleUpperCase()}${tail}`;
}

export function completeTime(time: string) {
  if (/^([0-1]?[0-9]|[2][0-3])$/.test(time)) {
    time = time + ":00";
  }
  if (/^\d:/.test(time)) {
    time = "0" + time;
  }
  if (/^\d[0-5]\d$/.test(time)) {
    time = "0" + time.substr(0, 1) + ":" + time.substr(1);
  }
  if (/^([0-1]?[0-9]|[2][0-3])[0-5]\d$/.test(time)) {
    time = time.substr(0, 2) + ":" + time.substr(2);
  }
  return time.replace(".", ":");
}

export function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

export function scrollIntoViewIfNeeded(
  element: Element,
  options?:
    | boolean
    | ScrollIntoViewOptions
    | ((entry: IntersectionObserverEntry) => ScrollIntoViewOptions),
): Promise<boolean> {
  return new Promise<boolean>((resolve, reject) => {
    const observer = new IntersectionObserver(
      ([intersection]) => {
        if (!intersection.isIntersecting) {
          const scrollIntoViewOptions =
            typeof options === "function" ? options(intersection) : options;

          element.scrollIntoView(scrollIntoViewOptions);
        }
        observer.disconnect();
        resolve(true);
      },
      {
        threshold: 1,
      },
    );
    observer.observe(element);
  });
}

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(
    `Unhandled discriminated union member: ${JSON.stringify(value)}`,
  );
}

export function euroToCent(price?: string | number): number {
  return Math.round(
    100 *
      parseFloat(
        (price?.toString().replace(/[eE€]/, "") ?? "").replace(",", "."),
      ),
  );
}
export function centToEuro(price: number): string {
  return (price / 100).toFixed(2).replace(".", ",");
}

export type ApiDate = `${number}-${number}-${number}`;
export function toApiDate(date: Date | moment.Moment): ApiDate {
  return moment(date).format("YYYY-MM-DD") as ApiDate;
}

export const pricePattern = /^[eE€]?-?[0-9]+([,.]([0-9]{1,2}|-|=))?$/;

export function dissoc<T, K extends keyof T>(key: K, obj: T): Omit<T, K> {
  const { [key]: _, ...rest } = obj;
  return rest;
}
