<template>
  <flux-tooltip>
    <i
      class="fas fa-info-square text-slate-500 hover:text-slate-600 dark:text-neutral-300 hover:dark:text-neutral-100"
    ></i>
    <template #content><slot></slot></template>
  </flux-tooltip>
</template>

<script lang="ts" setup></script>
