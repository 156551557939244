import { JSONContent } from "@tiptap/vue-3";
import { z } from "zod";

export const SalutationTemplateSchema = z.object({
  uuid: z.string().uuid(),
  language: z.string(),
  template: z.object({
    type: z.string(),
    content: z.any(),
  }),
  preview: z.string().optional(),
});

export type SalutationTemplate = z.infer<typeof SalutationTemplateSchema>;

export interface SalutationTemplateForm {
  uuid?: string;
  language: string;
  template: JSONContent;
}
