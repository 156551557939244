import { AxiosResponse } from "axios";
import { ZodIssue, ZodSchema } from "zod";
import { $t } from "../i18n";
import { MessageBag } from "./MessageBag";
import * as Sentry from "@sentry/vue";
import { useNotify } from "@/composables/notify";

const { notify } = useNotify();

export function isNotFoundErrorResponse(
  response: unknown,
): response is Record<"response", AxiosResponse & { status: 404 }> {
  return errorHasStatus(response, 404);
}

export function isValidationErrorResponse(
  response: unknown,
): response is Record<"response", AxiosResponse & { status: 422 }> {
  return errorHasStatus(response, 422);
}

export function isTooManyRequestsErrorResponse(
  response: unknown,
): response is Record<"response", AxiosResponse & { status: 429 }> {
  return errorHasStatus(response, 429);
}

export function isServerErrorResponse(
  response: unknown,
): response is Record<"response", AxiosResponse & { status: number }> {
  if (typeof response !== "object" || response === null) {
    return false;
  }

  if ("response" in response && "status" in (response as any).response) {
    return (
      (response as any).response.status >= 500 &&
      (response as any).response.status <= 599
    );
  }

  return false;
}

function errorHasStatus(response: unknown, status: number) {
  if (typeof response !== "object" || response === null) {
    return false;
  }

  if ("response" in response && "status" in (response as any).response) {
    return (response as any).response.status === status;
  }

  return false;
}

export function handleErrors({
  error,
  notifyUser = true,
  notificationSubject,
  notificationTitle,
}: {
  error: unknown;
  notifyUser?: boolean;
  notificationSubject?: string;
  notificationTitle?: string;
}): never {
  if (isNotFoundErrorResponse(error)) {
    handleNotFoundError(notifyUser, notificationSubject, notificationTitle);
    throw error;
  }

  if (isValidationErrorResponse(error)) {
    handleErrorMessagebag(
      MessageBag.fromResponse(error.response),
      notifyUser,
      notificationTitle,
    );
    throw error;
  }

  if (isTooManyRequestsErrorResponse(error)) {
    handleTooManyRequestsError(notifyUser);
    throw error;
  }

  if (isServerErrorResponse(error)) {
    handleServerError(notifyUser, notificationTitle);
    throw error;
  }

  throw error;
}

export function handleNotFoundError(
  notifyUser = true,
  notificationSubject?: string,
  notificationTitle?: string,
) {
  if (!notifyUser) {
    return;
  }

  if (notificationSubject) {
    notify({
      title: notificationTitle,
      showClose: true,
      type: "error",
      duration: 2000,
      message: $t("error.not_found", [notificationSubject]),
    });
  } else {
    notify({
      title: notificationTitle,
      showClose: true,
      type: "error",
      duration: 2000,
      message: $t("error.unknown_not_found", [notificationSubject]),
    });
  }
}

export function handleErrorMessagebag(
  errors: MessageBag,
  notifyUser = true,
  notificationTitle?: string,
) {
  if (!notifyUser) {
    return;
  }

  notify({
    showClose: true,
    type: "error",
    duration: errors.count() * 1000 + 1000,
    ...formatValidation(errors, notificationTitle),
  });
}

export function handleTooManyRequestsError(notifyUser = true) {
  if (!notifyUser) {
    return;
  }

  notify({
    showClose: true,
    type: "error",
    duration: 2000,
    message: $t("error.429"),
  });
}

export function handleServerError(
  notifyUser = true,
  notificationTitle?: string,
) {
  if (!notifyUser) {
    return;
  }

  notify({
    title: notificationTitle,
    showClose: true,
    type: "error",
    duration: 2000,
    message: $t("error.500"),
  });
}

export function handledZodParse<S extends ZodSchema, T>({
  schema,
  input,
  notifyUser = true,
  reportError = false,
  throwOnFail = true,
}: {
  schema: S;
  input: T;
  notifyUser?: boolean;
  reportError?: boolean;
  throwOnFail?: boolean;
}): ReturnType<S["parse"]> | T {
  try {
    return schema.parse(input);
  } catch (error) {
    if (reportError) {
      Sentry.captureException(error);
    }
    handleErrorMessagebag(MessageBag.fromZodError(error), notifyUser);
    if (throwOnFail) {
      throw error;
    }
    return input;
  }
}

function formatValidation(
  errors: MessageBag,
  title?: string,
): {
  title?: string;
  message: string;
} {
  const message = Object.values(errors.all())
    .flatMap((messages) => messages ?? [])
    .flatMap((message) => {
      try {
        return Object.values(JSON.parse(message));
      } catch {
        return message;
      }
    })
    .join("\n");

  return { title, message };
}

//See: https://zod.dev/ERROR_HANDLING
export function formatZodMessage(issue: ZodIssue) {
  const issuePath = issue.path.join(".");
  const i18nPath = "error.zod." + issue.code;

  if (issue.code === "invalid_type" || issue.code === "invalid_date") {
    return $t(i18nPath, [issuePath]);
  }

  let additionalInfo: string = "";

  if (issue.code === "invalid_enum_value") {
    additionalInfo = issue.options.join("\n");
    return $t(i18nPath, [issuePath, additionalInfo]);
  }

  if (issue.code === "invalid_string") {
    additionalInfo = issue.validation.toString();
    return $t(i18nPath, [issuePath, additionalInfo]);
  }

  if (issue.code === "too_small") {
    additionalInfo = (
      issue.inclusive ? issue.minimum : BigInt(issue.minimum) + BigInt(1)
    ).toString();
    return $t(i18nPath + "." + issue.type, [issuePath, additionalInfo]);
  }

  if (issue.code === "too_big") {
    additionalInfo = (
      issue.inclusive ? issue.maximum : BigInt(issue.maximum) - BigInt(1)
    ).toString();
    return $t(i18nPath + "." + issue.type, [issuePath, additionalInfo]);
  }

  return $t("error.unknown");
}
