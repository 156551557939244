import { z } from "zod";

export const letterTemplateTypes = [
  "MedicalUpdate",
  "LegalExport",
  "DTF",
  "EndEvaluation",
  "MedicalUpdateConsult",
  "MedicalUpdateConsultSOEP",
  "MedicalUpdateEvaluation",
  "MedicalUpdateScreening",
  "MedicalUpdateIntakeFlowIntake",
  "MedicalUpdateIntake",
] as const;

export type LetterTemplateType = (typeof letterTemplateTypes)[number];

export interface LetterTemplate {
  id: number;
  name: string;
  body: any;
  type: LetterTemplateType;
}

export const letterTemplateSchema = z.object({
  id: z.number(),
  name: z.string(),
  body: z.any(),
  type: z.enum(letterTemplateTypes),
});
export type ConsultSpecificButton = { field: string; translation?: string };
export type ConsultSpecificButtonObject = Record<
  string,
  ConsultSpecificButton[]
>;

export const letterTypeSpecificButtons: ConsultSpecificButtonObject = {
  MedicalUpdateConsult: [
    {
      field: "RvK",
    },
    {
      field: "A",
    },
    {
      field: "LO",
    },
    {
      field: "AO",
    },
    {
      field: "C",
    },
    {
      field: "B",
    },
  ],
  MedicalUpdateConsultSOEP: [
    {
      field: "S",
    },
    {
      field: "O",
    },
    {
      field: "E",
    },
    {
      field: "P",
    },
  ],
  MedicalUpdateEvaluation: [
    {
      field: "patient_is_present",
    },
    {
      field: "evaluation_type",
    },
    {
      field: "subjective",
    },
    {
      field: "objective",
    },
    {
      field: "main_goal",
    },
    {
      field: "progress",
      translation: "care_goal.progress.",
    },
    {
      field: "prognosis",
      translation: "care_plan.prognosis.",
    },
    {
      field: "anomalies",
    },
    {
      field: "recommendations",
    },
  ],
  MedicalUpdateScreening: [
    {
      field: "pluis",
    },
    {
      field: "vlag",
    },
    {
      field: "afwijkingen",
    },
    {
      field: "beleid",
    },
    {
      field: "indicatie",
    },
  ],
  MedicalUpdateIntake: [
    {
      field: "RvK",
    },
    {
      field: "A",
    },
    {
      field: "LO",
    },
    {
      field: "AO",
    },
    {
      field: "diagnose",
    },
    {
      field: "C",
    },
    {
      field: "pluis",
    },
    {
      field: "B",
    },
    {
      field: "bespreking",
    },
  ],
  MedicalUpdateIntakeFlowIntake: [
    {
      field: "RvK",
    },
    {
      field: "hulpvraag",
    },
    {
      field: "anamnese",
    },
    {
      field: "LO",
    },
    {
      field: "AO",
    },
    {
      field: "diagnose",
    },
    {
      field: "conclusie",
    },
    {
      field: "behandelplan",
    },
    {
      field: "indicatie",
    },
  ],
  DTF: [
    {
      field: "RvK",
    },
    {
      field: "hulpvraag",
    },
    {
      field: "anamnese",
    },
    {
      field: "LO",
    },
    {
      field: "AO",
    },
    {
      field: "diagnose",
    },
    {
      field: "conclusie",
    },
    {
      field: "behandelplan",
    },
    {
      field: "indicatie",
    },
  ],
  MedicalUpdateIntakeFlowScreening: [
    {
      field: "pluis",
    },
    {
      field: "vlag",
    },
    {
      field: "afwijkingen",
    },
    {
      field: "beleid",
    },
    {
      field: "indicatie",
    },
  ],
};
