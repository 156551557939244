export type ItemType = Item["type"];

export interface ResponseForm {
  [key: string]:
    | number
    | string
    | { valueCoding: Coding }
    | number[]
    | string[]
    | Array<{ valueCoding: Coding }>
    | null;
}

interface BaseItem {
  linkId: string;
  type: string;
  prefix?: string;
  text?: string;
  readonly?: boolean;
  required?: boolean;
  maxLength?: number;
  initial?: ValueX;
  answerOption?: any[];
  extension?: Extension[];
  item?: Item[];
  repeats?: boolean;
}

export type DisplayItem = BaseItem & {
  type: "display";
  text: string;
};

export type BooleanItem = BaseItem & {
  type: "boolean";
  text: string;
};

export type GroupItem = BaseItem & {
  type: "group";
  item: Item[];
};

export type OpenChoiceItem<T extends ImplementedTypes> = BaseItem & {
  type: "open-choice";
  answerOption: Array<T & { initialSelected?: boolean }>;
  initial?: ValueXTyped<T>;
  placeholder?: string;
};

export type ChoiceItem<T extends ImplementedTypes> = BaseItem & {
  type: "choice";
  answerOption: Array<T & { initialSelected?: boolean }>;
  initial?: ValueXTyped<T>;
};

export type IntegerItem = BaseItem & {
  type: "integer";
};

export type DecimalItem = BaseItem & {
  type: "decimal";
};

type FhirTypes = "boolean" | "decimal" | "integer" | "coding" | "string";
type ValueKey<Type extends FhirTypes> = `value${Capitalize<Type>}`;

export type ImplementedTypes = string | number | boolean | Coding;
type ValueXTyped<T extends ImplementedTypes> = ValueXFhirTyped<
  TypeToValueFhir<T>
>;
type ValueXFhirTyped<T extends FhirTypes> = Record<
  ValueKey<T>,
  TypeFromFhir<T>
>;
type ValueX = ValueXFhirTyped<FhirTypes>;

type TypeToValueFhir<T extends ImplementedTypes> = T extends string
  ? "string"
  : T extends number
    ? "decimal" | "integer"
    : T extends boolean
      ? "boolean"
      : T extends Coding
        ? "coding"
        : unknown;

type TypeFromFhir<T> = T extends "string"
  ? string
  : T extends "decimal" | "integer"
    ? number
    : T extends "boolean"
      ? boolean
      : T extends "coding"
        ? Coding
        : unknown;

export type TypedItem<T extends FhirTypes> = OpenChoiceItem<T> | ChoiceItem<T>;

export type Item = TypedItem<any> | UntypedItem;

export type UntypedItem =
  | DisplayItem
  | GroupItem
  | IntegerItem
  | BooleanItem
  | DecimalItem;

export interface Extension {
  url: string;
  valueCodeableConcept?: {
    coding: Coding[];
  };
  valueJson?: object | string[];
  valueDecimal?: number;
}

export interface Coding {
  system?: string;
  version?: string;
  code?: string | number | string[];
  display?: string | number;
  userSelected?: boolean;
}

export const VALUE_SET = {
  ITEM_CONTROL: "http://hl7.org/fhir/ValueSet/questionnaire-item-control",
  DISPLAY: "http://flux.healthcare/fhir/ValueSet/questionnaire-display",
  ORIENTATION: "http://flux.healthcare/fhir/ValueSet/questionnaire-orientation",
  DISPLAY_GRID_COLUMNS:
    "http://flux.healthcare/fhir/ValueSet/questionnaire-display-grid-columns",
  DISPLAY_TABLE_HEADERS:
    "http://flux.healthcare/fhir/ValueSet/questionnaire-table-headers",
};

export const EXTENSION = {
  DISPLAY:
    "http://flux.healthcare/fhir/StructureDefinition/questionnaire-display",
  CHOICE_ORIENTATION:
    "http://flux.healthcare/fhir/StructureDefinition/questionnaire-choice-orientation",
  ENABLED_WHEN: "http://flux.healthcare/fhir/StructureDefinition/enabled-when",
  VAS_SCORE_MODE:
    "http://flux.healthcare/fhir/StructureDefinition/questionnaire-vas-score-mode",
  MIN_VALUE: "http://hl7.org/fhir/StructureDefinition/minValue",
  MAX_VALUE: "http://hl7.org/fhir/StructureDefinition/maxValue",
  ITEM_CONTROL:
    "http://hl7.org/fhir/StructureDefinition/questionnaire-itemControl",
};

export function ExtensionHasCode(
  extension: Extension,
  url?: string,
  code?: string,
): boolean {
  if (extension.url !== url) {
    return false;
  }

  if (code === undefined) {
    return true;
  }

  return (
    extension.valueCodeableConcept?.coding.some(
      (coding) => coding.code === code,
    ) ?? false
  );
}

export function getExtension(
  item: Item,
  options: { url: string; system?: string; code?: string },
): Extension | undefined {
  return item.extension?.find((extension) =>
    ExtensionHasCode(extension, options.url, options.code),
  );
}

export function getFirstCode(
  item: Item,
  options: { url: string; system?: string; code?: string },
) {
  const code = getExtension(item, options)?.valueCodeableConcept?.coding.map(
    (coding) => coding.code,
  );
  if (Array.isArray(code) && code.length >= 1) {
    return code[0];
  }

  return undefined;
}

export function itemHasExtension(
  item: Item,
  options: { url: string; system?: string; code?: string },
): boolean {
  return (
    item.extension?.some((extension) =>
      ExtensionHasCode(extension, options.url, options.code),
    ) ?? false
  );
}

export function getChildItemWithExtension(
  item: Item,
  options: { url: string; system?: string; code: string },
): Item | undefined {
  return item.item?.find((child) => itemHasExtension(child, options));
}
