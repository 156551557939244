<template>
  <flux-tooltip
    v-if="['F', 'M', 'UNK', 'UN'].indexOf(props.gender) !== -1"
    :content="$t(`labels.gender.${props.gender}`)"
    :openDelay="450"
  >
    <i class="fal fa-venus" v-if="props.gender === 'F'"></i>
    <i class="fal fa-mars" v-else-if="props.gender === 'M'"></i>
    <i class="fal fa-genderless" v-else></i>
  </flux-tooltip>
</template>

<script lang="ts" setup>
import { GenderType } from "@/models/Patient";

const props = defineProps<{
  gender: GenderType;
}>();
</script>
