import { readonly, ref } from "vue";

const homeTabs = ["today", "recalls", "tasks", "worklist"] as const;
type HomeTab = (typeof homeTabs)[number];

const activeTab = ref<HomeTab>("today");
const cacheKey = "home-tab";

export function useHomeTabs() {
  function setActiveTab(newTab: string) {
    activeTab.value = getHomeTabFromString(newTab);
    sessionStorage.setItem(cacheKey, getHomeTabFromString(newTab));
  }

  function setActiveTabUsingSessionStorage() {
    const storageItem = getHomeTabFromString(sessionStorage.getItem(cacheKey));
    activeTab.value = storageItem;
  }

  return {
    activeTab: readonly(activeTab),
    setActiveTab,
    setActiveTabUsingSessionStorage,
  };
}

function getHomeTabFromString(string: string | null): HomeTab {
  if (!string || !stringIsHomeTab(string)) {
    return homeTabs[0];
  }
  return string;
}

function stringIsHomeTab(string: string): string is HomeTab {
  return homeTabs.some((tab) => tab === string);
}
