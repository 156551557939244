import { InjectionKey } from "vue";

export type TaxRate = {
  id: string;
  name: string;
  tax_rate_percentage: number;
};

export const taxRateRepositoryKey = Symbol() as InjectionKey<
  () => TaxRateRepository
>;

export interface TaxRateRepository {
  findAll(): Promise<TaxRate[]>;
  findById(id: string): Promise<TaxRate>;
}

export class TaxRateRepositoryUsingMemory implements TaxRateRepository {
  #taxRates: TaxRate[] = [
    {
      id: "nl.hoog20121001",
      tax_rate_percentage: 21,
      name: "21% btw",
    },
    {
      id: "nl.laag20190101",
      tax_rate_percentage: 9,
      name: "9% btw",
    },
    {
      id: "nl.vrijgesteld",
      tax_rate_percentage: 0,
      name: "Btw vrijgesteld",
    },
    {
      id: "nl.geen",
      tax_rate_percentage: 0,
      name: "Geen btw",
    },
  ];

  async findAll(): Promise<TaxRate[]> {
    return this.#taxRates;
  }

  async findById(id: string): Promise<TaxRate> {
    const taxRate = this.#taxRates.find((taxRate) => taxRate.id === id);
    if (!taxRate) {
      throw new Error("TaxRate not found");
    }

    return taxRate;
  }
}
