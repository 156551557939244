import { useQuery } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref, unref } from "vue";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import {
  TreatmentHistory,
  treatmentHistorySchema,
  PatientReferralTreatmentHistories,
  patientReferralTreatmentHistoriesSchema,
} from "@/models/treatmentHistory";

async function getTreatmentHistory(
  zisNumber: MaybeRef<number>,
  year: MaybeRef<string>,
): Promise<TreatmentHistory> {
  const { data } = await apiClient.get(
    `/patients/${unref(zisNumber)}/treatment_history`,
    {
      params: {
        years: [unref(year)],
      },
    },
  );
  return handledZodParse({
    schema: treatmentHistorySchema,
    input: data.treatment_histories?.at(0),
    notifyUser: false,
  });
}

async function getReferralTreatmentHistories(
  zisNumber: MaybeRef<number>,
): Promise<PatientReferralTreatmentHistories> {
  const { data } = await apiClient.get(
    `/patients/${unref(zisNumber)}/treatment_history/referrals`,
  );
  return handledZodParse({
    schema: patientReferralTreatmentHistoriesSchema,
    input: data,
    notifyUser: false,
  });
}

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useTreatmentHistory(zisNumber: Ref<number>, year: Ref<string>) {
  const query = useQuery({
    gcTime,
    staleTime,
    queryKey: ["treatmentHistory", zisNumber, year] as const,
    queryFn: ({ queryKey }) => getTreatmentHistory(queryKey[1], queryKey[2]),
  });

  return query;
}

export function useReferralTreatmentHistories(zisNumber: Ref<number>) {
  const query = useQuery({
    gcTime,
    staleTime,
    queryKey: ["referralTreatmentHistories", zisNumber] as const,
    queryFn: ({ queryKey }) => getReferralTreatmentHistories(queryKey[1]),
  });

  return query;
}
