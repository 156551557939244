import { Ref } from "vue";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { fetchVektisHealthcareProfessionalAgb } from "@/queries/vektisHealthcareProfessionalAgb/vektisHealthcareProfessionalAgb";
import { vektisHealthcareProfessionalAgbQueryKeys } from "@/queries/vektisHealthcareProfessionalAgb/vektisHealthcareProfessionalAgbKeys";

export function useVektisHealthcareProfessionalAgbSearch(name: Ref<string>) {
  return useQuery({
    queryKey: vektisHealthcareProfessionalAgbQueryKeys.lookup(name),
    staleTime: Infinity,
    gcTime: 1000 * 60 * 60,
    queryFn: async ({ queryKey }) => {
      if (queryKey[1].trim().length === 0) {
        return [];
      }

      return fetchVektisHealthcareProfessionalAgb(queryKey[1]);
    },
    placeholderData: keepPreviousData,
  });
}
