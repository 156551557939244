<template>
  <div>
    <div style="text-align: center; margin-top: 40px">
      <img
        src="../assets/images/warning.svg"
        style="width: 100%; max-width: 500px"
      />
    </div>
    <div style="text-align: center; margin-top: 14px">
      {{ $t("error.something_went_wrong") }}
      <a href="/">{{ $t("error.reload") }}</a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useErrorPageRedirects } from "@/composables/errorPage";
import { $t } from "@/libraries/i18n";

useErrorPageRedirects();
</script>
