import { AxiosResponse } from "axios";
import { ZodError } from "zod";
import { formatZodMessage } from "./errorHandling";

export type Messages = { [key: string]: string[] | undefined };

export class MessageBag {
  messages: Messages;

  constructor(messages: Messages = {}) {
    this.messages = messages;
  }

  static fromResponse(response: AxiosResponse): MessageBag {
    if ("errors" in response.data) {
      return new MessageBag(response.data.errors);
    }

    if (response.status === 422) {
      return new MessageBag(response.data.errors);
    }

    if (response.status === 403) {
      return new MessageBag({ errors: [response.data.message] });
    }

    throw new Error(
      "Could not create MessageBag from response. Unexpected status code.",
    );
  }

  static fromZodError(errors: ZodError): MessageBag {
    const zodMessages: Messages = {};
    errors.errors.forEach((error) => {
      const path = error.path.join(".");
      zodMessages[path] ??= [];
      zodMessages[path]!.push(formatZodMessage(error));
    });
    return new MessageBag(zodMessages);
  }

  get(key: string): string[] {
    return this.messages[key] ?? [];
  }

  getTranslatedMessages(attributeTranslator: (attribute: string) => string) {
    return Object.entries(this.messages).flatMap(([key, messages]) =>
      messages?.map((message) =>
        message.replace(key.replace("_", " "), attributeTranslator(key)),
      ),
    );
  }

  all(): Messages {
    return this.messages;
  }

  isEmpty(): boolean {
    return Object.keys(this.messages).length === 0;
  }

  keys(): string[] {
    return Object.keys(this.messages);
  }

  count(): number {
    return this.keys().length;
  }
}
