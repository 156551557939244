<template>
  <flux-button
    v-for="action in actions"
    type="text"
    size="mini"
    @click="emit('execute', action.command)"
  >
    {{ action.description }}
  </flux-button>
</template>

<script setup lang="ts">
import { WorkListAction, WorkListActionCommand } from "@/composables/workList";

const props = defineProps<{
  actions: WorkListAction[];
}>();

const emit = defineEmits<{
  (e: "execute", c: WorkListActionCommand): void;
}>();
</script>
