<template>
  <div class="contents">
    <flux-short-form v-flux-loading="loading">
      <flux-short-form-item
        :label="
          $t('patient.create.form.step.healthcare_professionals.AGB_code')
        "
      >
        <flux-agb-code-input
          v-model:modelValue="form.AGB_code"
          @select="autofillForm"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="
          $t('patient.create.form.step.healthcare_professionals.fullname')
        "
      >
        <flux-input v-model:modelValue="form.fullname" />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.healthcare_professionals.role')"
      >
        <flux-select v-model:modelValue="form.role">
          <option
            v-for="(role, index) in HealthcareProfessionalRoles"
            :key="index"
            :value="role"
            :label="$t(`healthcare_professional.role.${role}`)"
          >
            {{ $t(`healthcare_professional.role.${role}`) }}
          </option>
        </flux-select>
      </flux-short-form-item>
      <flux-short-form-item
        :label="
          $t('patient.create.form.step.healthcare_professionals.specialism')
        "
      >
        <flux-select v-model:modelValue="form.specialism_code">
          <option
            v-for="(specialism, index) in specialisms"
            :key="index"
            :value="specialism.code"
            :label="specialism.display_name_NL"
          >
            {{ specialism.display_name_NL }}
          </option>
        </flux-select>
      </flux-short-form-item>
      <flux-short-form-item
        :label="
          $t('patient.create.form.step.healthcare_professionals.UZI_code')
        "
      >
        <flux-input v-model:modelValue="form.UZI_code" />
      </flux-short-form-item>
    </flux-short-form>
    <slot name="actions"></slot>
  </div>
</template>
<script lang="ts" setup>
import { pinia } from "@/libraries/store";
import { Specialism, useSpecialismStore } from "@/libraries/store/Specialisms";
import { HealthcareProfessional } from "@/models/HealthcareProfessional";
import { onMounted, ref } from "vue";
import { HealthcareProfessionalRoles } from "@/models/HealthcareProfessional";
import { watch } from "vue";
import { isValidAgb } from "@/libraries/utils/validators/agb";
import { VektisHealthcareProfessionalAgb } from "@/models/VektisHealthcareProfessionalAgb";
import { lookupAgbCode } from "@/queries/vektis";

const props = defineProps<{
  form: Omit<HealthcareProfessional, "id">;
}>();

const specialisms = ref<Specialism[]>([]);
const specialismStore = useSpecialismStore(pinia);
const loading = ref(false);

onMounted(() =>
  specialismStore.findAll().then((res) => {
    specialisms.value = res;
  }),
);

watch(
  () => props.form.AGB_code,
  async () => {
    const agbCode = props.form.AGB_code;

    if (agbCode === undefined) {
      return;
    }

    if (!isValidAgb(agbCode)) {
      return;
    }

    const result = await lookupAgbCode(agbCode);
    if (result === undefined) {
      return;
    }

    autofillForm(result);
  },
);

function autofillForm(person: VektisHealthcareProfessionalAgb): void {
  props.form.fullname = `${person.initials} ${person.surname}`;
  if ((person.agb_code as string).startsWith("01")) {
    props.form.specialism_code = "01.015";
  }
}
</script>
