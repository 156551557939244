import { careGoalSchema } from "./../CareGoal/CareGoal";
import { parseISO } from "date-fns";
import { z } from "zod";
import { consentSchema } from "@/models/Consent";

export const carePlanStatus = ["active", "on_hold", "completed"] as const;

export type CarePlanStatus = (typeof carePlanStatus)[number];

export const carePlanPrognosis = [
  "full_recovery",
  "reduction_of_symptoms",
  "stabilisation",
  "indeterminable",
] as const;

export type CarePlanPrognosis = (typeof carePlanPrognosis)[number];

export const carePlanSchema = z.object({
  id: z.string(),
  referral_id: z.number(),
  status: z.enum(carePlanStatus),
  prognosis: z.enum(carePlanPrognosis),
  description: z.string(),
  user_ids: z.array(z.number()),
  care_goals: z.array(careGoalSchema),
  start_date: z.string().optional(),
  end_date: z.string().optional(),
  main_goal: z.string().optional(),
  template_id: z.string().optional(),
  consents: z.array(consentSchema).optional(),
  explanation: z
    .object({
      type: z.literal("doc"),
      content: z.array(
        z.any().refine((data): data is object => typeof data === "object"),
      ),
    })
    .optional(),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
});

export const carePlanFormSchema = carePlanSchema.omit({
  id: true,
  referral_id: true,
  care_goals: true,
  consents: true,
  created_at: true,
  updated_at: true,
});

export const carePlanCopyWithDelaySchema = z.object({
  delay: z.number(),
  start_date: z.string(),
  activate: z.boolean(),
  description: z.string(),
});

export type CarePlan = z.output<typeof carePlanSchema>;
export type CarePlanApi = z.input<typeof carePlanSchema>;
export type CarePlanForm = z.infer<typeof carePlanFormSchema>;
export type CarePlanCopyWithDelay = z.infer<typeof carePlanCopyWithDelaySchema>;
