import { z } from "zod";

export const vektisHealthcareProfessionalAgbScheme = z.object({
  agb_code: z.string(),
  first_names: z.string().optional(),
  surname: z.string().optional(),
  surname_prefix: z.string().optional(),
  maiden_name: z.string().optional(),
  maiden_name_prefix: z.string().optional(),
  fullname: z.string().optional(),
  nickname: z.string().optional(),
  initials: z.string().optional(),
});

export type VektisHealthcareProfessionalAgb = z.infer<
  typeof vektisHealthcareProfessionalAgbScheme
>;
