<template>
  <div
    class="flex h-full w-screen flex-col items-end overflow-scroll md:w-full md:overflow-visible"
  >
    <flux-button v-if="!newTaskOpen" type="primary" @click="newTaskOpen = true">
      Nieuwe Taak
      <i class="fal fa-plus w-3 text-center"></i>
    </flux-button>
    <TaskForm
      v-if="newTaskOpen"
      :task="emptyTask"
      @close="newTaskOpen = false"
      @update="emit('update')"
    />
    <flux-table class="w-full" :data="tasks" expandKey="id">
      <template #expand="{ row, close }">
        <TaskForm :task="row" @close="close" @update="emit('update')" />
      </template>
      <flux-table-column prop="tilte" label="Titel" tdClass="w-1/4">
        <template #row="row: Task">{{ row.title }}</template>
      </flux-table-column>
      <flux-table-column prop="due_date" label="Datum" primary
        ><template #row="row: Task">
          {{ dateFilter(row.due_date) }}
        </template>
      </flux-table-column>
      <flux-table-column prop="status" label="Status">
        <template #row="row: Task">
          <TaskStatus @update="emit('update')" :task="row" />
        </template>
      </flux-table-column>
      <flux-table-column prop="assignee_id" label="Uitvoerder">
        <template #row="row: Task"
          >{{ row["assignee"] ? fullName(row["assignee"]) : "..." }}
        </template>
      </flux-table-column>
      <flux-table-column prop="patient_zis_number" label="Patient">
        <template #row="row: Task">
          <patient-link
            v-if="row.patient"
            :patient="row.patient"
          ></patient-link>
        </template>
      </flux-table-column>
    </flux-table>
  </div>
</template>
<script setup lang="ts">
import { fullName } from "@/models/Person";
import { ref, PropType } from "vue";
import TaskForm from "@/components/Tasks/TaskForm.vue";
import TaskStatus from "@/components/Tasks/TaskStatus.vue";
import { getUser } from "@/libraries/plugins/getUser";
import { Task } from "@/models/Task";
import { Patient } from "@/composables/patient";
import { dateFilter } from "@/filters";

const props = defineProps({
  tasks: {
    type: Array as PropType<Task[]>,
    required: true,
  },
  patient: {
    type: Object as PropType<Patient>,
    required: false,
  },
});

const emit = defineEmits<{
  (e: "update"): void;
}>();

const emptyTask = ref<Task>({
  status: "to_do",
  due_date: Temporal.Now.plainDateISO(),
  title: "",
  healthcare_provider_id: getUser().healthcare_provider.id,
  assignee_id: getUser().id,
  assignee: getUser(),
  ...(props.patient
    ? { patient_zis_number: props.patient.zis_number, patient: props.patient }
    : {}),
});

const newTaskOpen = ref<boolean>(false);
</script>
