<template>
  <div>
    <flux-label type="neutral">
      <template v-if="maxTreatments">
        <span class="whitespace-nowrap">
          {{ maxTreatments }}
          {{ $t("general.treatment", maxTreatments).toLowerCase() }}
        </span>
        <template v-if="maxManualTreatments">
          , {{ maxManualTreatments }} manueel </template
        >&nbsp;
      </template>
      <template v-else-if="maxCompensation"> € {{ maxCompensation }} </template>
      <clickable-icon
        v-if="displayDetails"
        icon="fal fa-info-square cursor-pointer"
        content="Klik voor extra info"
        @click="showDetails = !showDetails"
      ></clickable-icon>
    </flux-label>
    <div
      class="my-4 text-sm text-slate-800"
      v-if="showDetails && displayDetails === 'expand' && zorgvergoedingResult"
    >
      <ZorgvergoedingDetails :zorgvergoedingResult="zorgvergoedingResult" />
    </div>
    <flux-modal v-if="zorgvergoedingResult" v-model:visible="showModal">
      <ZorgvergoedingDetails :zorgvergoedingResult="zorgvergoedingResult" />
    </flux-modal>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import ZorgvergoedingDetails from "./ZorgvergoedingDetails.vue";
import { ZorgvergoedingResult } from "@/models/zorgvergoeding";
import { $t } from "@/libraries/i18n";

type DisplayDetails = "modal" | "expand" | false;

const props = defineProps<{
  zorgvergoedingResults: ZorgvergoedingResult[];
  displayDetails: DisplayDetails;
}>();

const zorgvergoedingResult = computed(() => props.zorgvergoedingResults.at(0));

const maxTreatments = computed(() =>
  props.zorgvergoedingResults.reduce(
    (max, zv) => Math.max(max, zv.data.aantal ?? 0),
    0,
  ),
);

const maxManualTreatments = computed(() =>
  props.zorgvergoedingResults.reduce(
    (max, zv) => Math.max(max, zv.data.mtaant ?? 0),
    0,
  ),
);

const maxCompensation = computed(() =>
  props.zorgvergoedingResults.reduce(
    (max, zv) => Math.max(max, zv.data.bedrag ?? 0),
    0,
  ),
);

const showDetails = ref(false);

const showModal = computed({
  get: () => showDetails.value && props.displayDetails === "modal",
  set: () => (showDetails.value = false),
});
</script>
