import { Store } from "vuex";
import { apiClient } from "../libraries/utils/axios";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { useFormAutoSaveManager } from "@/composables/formAutoSaveManager";

export interface LoginCredentials {
  username: string;
  password: string;
}

type LoginResult =
  | { type: "success" }
  | { type: "challenge"; challenge_id: string }
  | { type: "errors"; errors: MessageBag };

type ChallengeResult =
  | { type: "success" }
  | { type: "error"; errors: MessageBag };

/**
 * @param {Store<any>} store Vuex store
 */
export function authModule(store: Store<any>) {
  return {
    /** User object will let us check authentication status */
    user: {
      authenticated: false,
    },

    /**
     * Log in user and redirect afterwards.
     * @param {Object} creds Credentials of user
     * @param {string} creds.username
     * @param {string} creds.password
     */
    async login(creds: LoginCredentials): Promise<LoginResult> {
      try {
        const response = await apiClient.post("/auth/login", {
          username: creds.username,
          password: creds.password,
        });
        if (response.data.challenge) {
          return {
            type: "challenge",
            challenge_id: response.data.challenge.id,
          };
        }
        this.setToken(response.data.access_token);
        this.setSyncData(response.data);
        this.checkAuth();
        return { type: "success" };
      } catch (e) {
        if (isValidationErrorResponse(e)) {
          return {
            type: "errors",
            errors: MessageBag.fromResponse(e.response),
          };
        } else {
          return {
            type: "errors",
            errors: new MessageBag({ error: [e.response.data.message] }),
          };
        }
      }
    },

    async completeChallenge(
      creds: LoginCredentials,
      challengeId: string,
      totp: string,
    ): Promise<ChallengeResult> {
      try {
        const response = (await apiClient.post(
          "/auth/login/completeChallenge",
          {
            username: creds.username,
            password: creds.password,
            method: "totp",
            challenge_id: challengeId,
            data: {
              totp,
            },
          },
        )) as any;
        this.setToken(response.data.access_token);
        this.setSyncData(response.data);
        this.checkAuth();
        return { type: "success" };
      } catch (e) {
        return { type: "error", errors: MessageBag.fromResponse(e.response) };
      }
    },

    /**
     * Set the token in the Vuex storage.
     */
    setToken(token: string, useLocalStorage: boolean = true) {
      store.state.token = token;
      if (useLocalStorage) {
        localStorage.setItem("flux_token", token);
      }
      this.user.authenticated = true;
      apiClient.defaults.headers.common.Authorization = `Bearer ${store.state.token}`;
    },

    setSyncData(data: any, useLocalStorage: boolean = true) {
      if (data.user) {
        const { healthcare_provider_id: _, ...user } = data.user;
        store.state.user = user;
      }
    },

    unsetToken() {
      store.state.token = null;
      this.user.authenticated = false;

      const persistentKeys = ["read_release_notes", "user_theme"];
      const persistentData = persistentKeys.reduce(
        (acc, key) => {
          const value = localStorage.getItem(key);
          if (value) acc[key] = value;
          return acc;
        },
        {} as Record<string, string>,
      );

      localStorage.clear();

      Object.entries(persistentData).forEach(([key, value]) =>
        localStorage.setItem(key, value),
      );

      apiClient.defaults.headers.common.Authorization = null;

      const { deInitialize: deinitializeForm } = useFormAutoSaveManager();
      deinitializeForm();
    },
    /**
     * Log out by removing the token from the Vuex storage.
     */
    async logout(preReload?: () => void) {
      try {
        if (store.state.token) {
          await apiClient.post("/users/logout");
        }
      } finally {
        this.unsetToken();
        if (preReload) {
          await preReload();
        }
        window.location.reload();
      }
    },

    /**
     * Check if user is authenticated.
     * The result is saved in `this.user.authenticated`
     */
    checkAuth(): boolean {
      let jwt = store.state.token;

      if (!jwt && localStorage.getItem("flux_token")) {
        jwt = localStorage.getItem("flux_token");
        this.setToken(jwt);
      }

      if (jwt) {
        this.user.authenticated = true;
      } else {
        this.user.authenticated = false;
      }

      const storedTheme = localStorage.getItem("user_theme");
      document.documentElement.classList.toggle("dark", storedTheme === "dark");

      return this.user.authenticated;
    },
  };
}
