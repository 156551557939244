import EncryptedStorage from "../storage/encryptedStorage";
import { FluxStorage } from "../storage/fluxStorage";
import { FluxStorageManager } from "../storage/fluxStorageManager";

export interface FormData {
  date: number;
  url: string;
  formData: any;
}

export default class FormAutoSaveManager {
  private storageManager: FluxStorageManager;
  private storage?: FluxStorage<string, FormData>;
  private userUuid: string;
  private expiry: number;

  constructor(storageManager: FluxStorageManager, userUuid: string) {
    this.storageManager = storageManager;
    this.userUuid = userUuid;
    // Two weeks
    this.expiry = 1000 * 86400 * 14;
  }

  private async ensureStorage(): Promise<FluxStorage<string, FormData>> {
    if (this.storage === undefined) {
      this.storage = await this.storageManager.getScopedInstance<
        string,
        FormData
      >("savedForms", this.userUuid);
    }
    return this.storage;
  }

  public async storeForm(key: string, url: string, data: any): Promise<void> {
    const storage = await this.ensureStorage();
    const formData: FormData = {
      date: Date.now(),
      url: url,
      formData: data,
    };
    storage.setItem(key, formData, Date.now() + this.expiry);
  }

  public async getStoredForm(key: string): Promise<any> {
    const storage = await this.ensureStorage();
    const data = await storage.getItem(key);
    return data?.formData;
  }

  public async getStoredForms(): Promise<{ key: string; data: FormData }[]> {
    const storage = await this.ensureStorage();
    return await storage.getAll();
  }

  public async deleteStoredForm(key: string): Promise<void> {
    const storage = await this.ensureStorage();
    storage.removeItem(key);
  }

  public async setEncryptionKeyForStorage(key: string): Promise<void> {
    const storage = await this.ensureStorage();
    if (storage instanceof EncryptedStorage) {
      storage.setEncryptionKey(key);
    }
  }
}
