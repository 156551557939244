import debounce from "debounce";
import { Ref, ref, watch } from "vue";

export function useDebouncedValue(value: Ref<any>, interval: number) {
  const innerValue = ref(value.value);

  function update() {
    innerValue.value = value.value;
  }

  const debouncedUpdate = debounce(update, interval);

  watch(value, debouncedUpdate);

  return innerValue;
}
