import { Extension } from "@tiptap/core";
import SnippetList from "@/components/ui/Input/SnippetList.vue";
import Suggestion from "@tiptap/suggestion";
import { VueRenderer } from "@tiptap/vue-3";
import { PluginKey } from "@tiptap/pm/state";
import tippy, { Instance, Props } from "tippy.js";

export interface TextSnippetOptions {
  items: (query: string) => { name: string; value: any[] }[];
}

const textSnippetPluginKey = new PluginKey("textSnippetSuggestion");

export const TextSnippet = Extension.create<TextSnippetOptions>({
  name: "textSnippet",

  addOptions() {
    return {
      items: () => [],
    };
  },

  addProseMirrorPlugins(this) {
    return [
      Suggestion<{ name: string; value: any[] }>({
        char: "@",
        editor: this.editor,
        allowSpaces: true,
        startOfLine: false,
        pluginKey: textSnippetPluginKey,
        render: () => {
          let component: VueRenderer | undefined;
          let popup: Instance<Props> | undefined;

          return {
            onStart: (cmdProps) => {
              component = new VueRenderer(SnippetList, {
                props: cmdProps,
                editor: cmdProps.editor,
              });

              popup = tippy(document.body, {
                getReferenceClientRect:
                  cmdProps.clientRect === null ||
                  cmdProps.clientRect === undefined
                    ? null
                    : (): DOMRect => {
                        if (
                          cmdProps.clientRect === undefined ||
                          cmdProps.clientRect === null
                        ) {
                          throw new Error("Invalid clientRect function");
                        }
                        const rect = cmdProps.clientRect();
                        if (rect === null) {
                          throw new Error("Invalid clientRect result");
                        }
                        return rect;
                      },
                appendTo: () => document.body,
                content: component.element ?? undefined,
                showOnCreate: true,
                interactive: true,
                trigger: "manual",
                placement: "bottom-start",
              });
            },

            onUpdate(cmdProps) {
              component?.updateProps(cmdProps);

              if (
                cmdProps.clientRect === null ||
                cmdProps.clientRect === undefined
              ) {
                return;
              }

              popup?.setProps({
                getReferenceClientRect: (): DOMRect => {
                  if (
                    cmdProps.clientRect === undefined ||
                    cmdProps.clientRect === null
                  ) {
                    throw new Error("Invalid clientRect function");
                  }
                  const rect = cmdProps.clientRect();
                  if (rect === null) {
                    throw new Error("Invalid clientRect result");
                  }
                  return rect;
                },
              });
            },

            onKeyDown(cmdProps) {
              if (cmdProps.event.key === "Escape") {
                popup?.hide();

                return true;
              }

              return component?.ref?.onKeyDown(cmdProps);
            },

            onExit() {
              popup?.destroy();
              popup = undefined;
              component?.destroy();
              component = undefined;
            },
          };
        },
        command: ({ editor, range, props }) => {
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(" ");

          if (overrideSpace) {
            range.to += 1;
          }

          const nodeBefore = editor.view.state.selection.$from.nodeBefore;
          const emptyParagraph = (nodeBefore?.content?.childCount ?? 0) === 0;
          if (emptyParagraph) {
            range.from -= 1;
          }

          editor.chain().focus().insertContentAt(range, props.value).run();

          window.getSelection()?.collapseToEnd();
        },
        items: ({ query }): { name: string; value: any[] }[] => {
          const allItems = this.options.items("");
          return allItems.filter((item) =>
            item.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
          );
        },
      }),
    ];
  },
});
