import { z } from "zod";

export type HealthcareProfessionalRole =
  (typeof HealthcareProfessionalRoles)[number];

export const HealthcareProfessionalRoles = [
  "RESP",
  "REF",
  "PRF",
  "SPRF",
  "CON",
  "ATND",
  "OTH",
] as const;

export const healthcareProfessionalSchema = z.object({
  id: z.number(),
  fullname: z.string(),
  AGB_code: z.string().optional(),
  UZI_code: z.string().optional(),
  role: z.enum(HealthcareProfessionalRoles),
  healthcare_provider: z.string().optional(),
  specialism_code: z.string().optional(),
});
export interface HealthcareProfessional {
  id: number;
  fullname: string;
  AGB_code?: string;
  UZI_code?: string;
  specialism_code?: string;
  healthcare_provider?: string;
  role: HealthcareProfessionalRole;
}
