type BsnInvalidReason = "invalid_length" | "nonnumeric" | "checksum_failed";

interface BsnResponse {
  success: boolean;
  reason?: BsnInvalidReason;
}

export const bsnIsValid = (bsn: string): BsnResponse => {
  if (bsn.length !== 8 && bsn.length !== 9) {
    return {
      success: false,
      reason: "invalid_length",
    };
  }

  // append zeros if bsn is too short
  if (bsn.length < 9) {
    return bsnIsValid("0".repeat(9 - bsn.length) + bsn);
  }

  if (!/^\d+$/.test(bsn)) {
    return {
      success: false,
      reason: "nonnumeric",
    };
  }

  const digits = bsn.split("").map((digit) => parseInt(digit, 10));

  // Note that the last number is -1!
  const weights = [9, 8, 7, 6, 5, 4, 3, 2, -1];

  const digitSum = digits.reduce(
    (sum, digit, index) => sum + digit * weights[index],
    0,
  );

  if (digitSum % 11 !== 0) {
    return {
      success: false,
      reason: "checksum_failed",
    };
  }

  return {
    success: true,
  };
};

export const documentNumberIsValid = (
  documentNumber: string,
): { success: boolean; reason?: string } => {
  documentNumber = documentNumber.toLowerCase();
  if (documentNumber.length !== 9) {
    return {
      success: false,
      reason: "invalid_length",
    };
  }

  if (documentNumber.includes("o")) {
    return {
      success: false,
      reason: "includes_o",
    };
  }

  const regex = /^[a-z]{2}[a-z0-9]{6}[0-9]$/;

  if (regex.test(documentNumber)) {
    return {
      success: true,
    };
  }

  return {
    success: false,
    reason: "general_invalid_format",
  };
};
