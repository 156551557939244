<template>
  <div
    class="group my-2 border-l-2 p-2"
    :class="{
      'border-indigo-100 focus-within:border-indigo-500': item.type !== 'group',
      'border-orange-100 focus-within:border-orange-500': item.type === 'group',
    }"
  >
    <div class="mb-1 flex gap-2">
      <div
        class="inline-block cursor-pointer rounded px-2 py-1 text-xs"
        @click="collapsed = !collapsed"
        :class="{
          'bg-indigo-100 text-indigo-600 hover:bg-indigo-200':
            item.type !== 'group',
          'bg-orange-100 text-orange-600 hover:bg-orange-200':
            item.type === 'group',
        }"
      >
        <i
          class="fal fa-chevron-down transform duration-150"
          :class="{ 'rotate-180': collapsed }"
        ></i>
        <template v-if="hasItemControl('NRS', item)"> NRS </template>
        <template v-else-if="hasItemControl('VAS', item)"> VAS </template>
        <template v-else>
          {{ item.type }}
        </template>
        <span v-if="item.item !== undefined">({{ item.item.length }})</span>
      </div>
      <div
        class="inline-block rounded px-2 py-1 text-xs"
        :class="{
          'bg-indigo-100 text-indigo-600': item.type !== 'group',
          'bg-orange-100 text-orange-600': item.type === 'group',
        }"
      >
        LinkID
        <input class="ml-1 px-1" v-model="item.linkId" />
      </div>
      <div
        class="inline-block cursor-pointer items-center rounded bg-red-100 px-2 py-1 text-xs text-red-500 hover:bg-red-500 hover:text-red-50"
        @click="$emit('remove')"
      >
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div v-if="!collapsed">
      <Group v-if="item.type == 'group'" :item="item"></Group>
      <Display v-else-if="item.type == 'display'" :item="item"></Display>
      <OpenChoice
        v-else-if="item.type == 'open-choice'"
        :item="item"
      ></OpenChoice>
      <Choice v-else-if="item.type == 'choice'" :item="item"></Choice>
      <Boolean v-else-if="item.type == 'boolean'" :item="item"></Boolean>
      <NRS
        v-else-if="item.type == 'integer' && hasItemControl('NRS', item)"
        :item="item"
      ></NRS>
      <VAS
        v-else-if="item.type == 'decimal' && hasItemControl('VAS', item)"
        :item="item"
      ></VAS>
      <Number
        v-else-if="item.type == 'decimal' || item.type == 'integer'"
        :item="item"
      ></Number>
      <div v-else>???</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Display from "./display.vue";
import NRS from "./input/NRS.vue";
import VAS from "./input/VAS.vue";
import Group from "./group.vue";
import OpenChoice from "./input/OpenChoice.vue";
import Choice from "./input/Choice.vue";
import Boolean from "./input/Boolean.vue";
import Number from "./input/Number.vue";
import { EXTENSION, Item, VALUE_SET } from "@/libraries/questionnaires/item";
import { ref } from "vue";

defineProps<{
  item: Item;
}>();

const collapsed = ref(false);

function hasItemControl(itemControl: string, item: Item): boolean {
  if (!Array.isArray(item.extension)) {
    return false;
  }

  const code = item.extension
    .find((ext) => ext.url == EXTENSION.ITEM_CONTROL)
    ?.valueCodeableConcept?.coding.find(
      (coding) => coding.system == VALUE_SET.ITEM_CONTROL,
    )?.code;

  return code === itemControl;
}
</script>
