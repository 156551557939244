<template>
  <div>
    <div>
      <flux-card class="mb-4" shadow="never">
        <div>
          <div class="options" style="float: right">
            <clickable-icon-group>
              <clickable-icon
                v-if="objectIsExpired(payerInsurer)"
                icon="fa-redo"
                ref="btn_copy_to_this_year"
                @click="renew()"
                :content="
                  $t('patient.edit.payer_insurer.copy', {
                    year: new Date().getFullYear(),
                  })
                "
              ></clickable-icon>
              <clickable-icon
                v-if="state == 'read'"
                icon="fa-pencil"
                ref="btn_start_edit"
                @click="setState('update')"
              ></clickable-icon>
              <clickable-icon
                v-if="state == 'read'"
                icon="fa-trash-alt"
                ref="btn_start_remove"
                @click="confirmRemove()"
              ></clickable-icon>
            </clickable-icon-group>
          </div>
          <flux-card-button-header
            class="mb-4"
            :title="payerInsurer.insurer_name ?? ''"
          />
          <flux-form v-if="state == 'update'" :errors="errors">
            <PayerInsurerFormComponent
              v-model:modelValue="form"
              @enter="emit('enter')"
            />
            <flux-button-group class="mt-8 justify-end">
              <flux-button
                ref="btn_cancel"
                size="small"
                type="default"
                @click="cancel"
                >{{ $t("general.cancel") }}</flux-button
              >
              <flux-submit-button
                ref="btn_update"
                size="small"
                icon="fal fa-pencil"
                @click="update"
                type="primary"
                >{{ $t("general.update") }}</flux-submit-button
              >
            </flux-button-group>
          </flux-form>

          <div class="view-payer-insurer" v-else-if="state == 'read'">
            <flux-short-form>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.end_date')"
                span="col-span-6"
              >
                {{ dateFilter(payerInsurer.end_date) }}
              </flux-short-form-item>
              <flux-short-form-item
                :label="
                  $t('patient.create.form.step.payer_insurers.insurer_number')
                "
                span="col-span-6"
              >
                <template v-if="payerInsurer.insurer_number">
                  {{ payerInsurer.insurer_number }}
                </template>
                <template v-else>
                  <i
                    class="fas fa-exclamation-circle"
                    style="color: #ffdb89; margin-right: 4px"
                  ></i>
                  <span style="color: #cdcfd2">{{
                    $t("general.unknown")
                  }}</span>
                </template>
              </flux-short-form-item>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.number')"
                span="col-span-12"
              >
                <template v-if="payerInsurer.number">
                  {{ payerInsurer.number }}
                </template>
                <template v-else>
                  <i
                    class="fas fa-exclamation-circle"
                    style="color: #ffdb89; margin-right: 4px"
                  ></i>
                  <span style="color: #cdcfd2">{{
                    $t("general.unknown")
                  }}</span>
                </template>
              </flux-short-form-item>
              <flux-short-form-item
                :label="$t('patient.create.form.step.payer_insurers.type')"
                span="col-span-6"
              >
                <template v-if="payerInsurer.type">{{
                  $t("labels.insurance_type." + payerInsurer.type)
                }}</template>
              </flux-short-form-item>
              <flux-short-form-item
                :label="
                  $t('patient.create.form.step.payer_insurers.package_name')
                "
                span="col-span-6"
              >
                {{ payerInsurer.package_name || "-" }}
              </flux-short-form-item>
            </flux-short-form>
          </div>
        </div>
      </flux-card>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { ref } from "vue";
import PayerInsurerFormComponent from "../Patient/PayerInsurerForm.vue";
import {
  PayerInsurerPersisted,
  PayerInsurerForm,
} from "../../models/PayerInsurer";
import { Patient } from "@/composables/patient";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { useConfirm } from "@/composables/confirm";
import { useNotify } from "@/composables/notify";
import { $t } from "@/libraries/i18n";
import { handleErrors } from "@/libraries/utils/errorHandling";
import { objectIsExpired } from "@/libraries/utils/objectIsActive";
import { dateFilter } from "@/filters";
import { endOfYear, format } from "date-fns";

const { confirm } = useConfirm();
const { notify } = useNotify();

const props = defineProps<{
  payerInsurer: PayerInsurerPersisted;
  patient: Patient;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "updated"): void;
  (e: "updating"): void;
  (e: "enter"): void;
}>();

const loading = ref<boolean>(false);
const state = ref<string>("read");
const errors = ref<MessageBag>();
const form = ref<PayerInsurerForm>(getEmptyForm());

async function cancel() {
  emit("cancel");
  state.value = "read";
}

function renew() {
  form.value = props.payerInsurer;
  form.value.end_date = format(endOfYear(new Date()), "yyyy-MM-dd");
  update();
}

async function update() {
  emit("updating");
  try {
    const res = await apiClient.put(
      "/patients/:zis_number/payer_insurers/:id",
      form.value,
      {
        params: {
          zis_number: props.patient.zis_number,
          id: props.payerInsurer.id,
        },
      },
    );
    if (!res.data.success) {
      return false;
    }
    emit("updated");
    setState("read");
    notify({
      message: $t("payer_insurer.updated") as string,
      type: "success",
    });
    errors.value = undefined;
    return true;
  } catch (e) {
    handleErrors({ error: e });
    throw e;
  }
}

async function confirmRemove() {
  const shouldRemove = await confirm({
    message: $t("payer_insurer.confirm.text") as string,
    title: $t("payer_insurer.confirm.title") as string,
    type: "delete",
  });

  if (!shouldRemove) {
    return false;
  }

  return remove();
}

async function remove() {
  emit("updating");
  try {
    await apiClient.delete("/patients/:zis_number/payer_insurers/:id", {
      params: {
        zis_number: props.patient.zis_number,
        id: props.payerInsurer.id,
      },
    });
    emit("updated");
    notify({
      message: $t("payer_insurer.removed") as string,
      type: "success",
    });
    loading.value = false;
    return true;
  } catch (e) {
    loading.value = false;
  }
}

function setState(newState: "update" | "read") {
  switch (newState) {
    case "update":
      form.value = Object.assign({}, props.payerInsurer);
      state.value = "update";
      break;

    case "read":
      state.value = "read";
      break;
  }
}

function getEmptyForm(): PayerInsurerForm {
  return {
    number: "",
    type: "B",
    start_date: "",
    end_date: "",
    insurer_name: "",
    insurer_number: "",
  };
}
</script>
