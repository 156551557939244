<template>
  <div>
    Operator:
    <select v-model="item.operator">
      <option
        v-for="operatorName in operatorNames"
        :key="operatorName"
        :value="operatorName"
      >
        {{ operatorName }}
      </option>
      <option :value="null">None</option>
    </select>
    <div class="flex flex-row gap-2">
      <div>
        Left:
        <EditorScore
          v-if="item.left"
          :score="item.left"
          @remove="item.left = undefined"
        ></EditorScore>
        <div
          class="col-span-full grid grid-cols-6 gap-1 opacity-40 transition-opacity duration-75 focus-within:opacity-100 hover:opacity-100"
        >
          <NumericExpressionVue v-if="!item.left" @item="setLeft" />
        </div>
      </div>
      <div>
        Right:
        <EditorScore
          v-if="item.right"
          :score="item.right"
          @remove="item.right = undefined"
        ></EditorScore>
        <div
          class="col-span-full grid grid-cols-6 gap-1 opacity-40 transition-opacity duration-75 focus-within:opacity-100 hover:opacity-100"
        >
          <NumericExpressionVue v-if="!item.right" @item="setRight" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  NumericExpression,
  operatorNames,
  NumericComparison,
} from "@/libraries/questionnaires/score";
import NumericExpressionVue from "../score/NumericExpression.vue";

const props = defineProps<{
  item: Partial<NumericComparison>;
}>();

function setLeft(item: NumericExpression) {
  props.item.left = item;
}

function setRight(item: NumericExpression) {
  props.item.right = item;
}
</script>
