import { useQueryClient, useQuery } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";
import { Ref } from "vue";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";

async function getSbvzLog(patientZisNumber: number): Promise<SbvzLogs> {
  const result = await apiClient.get("apps/sbv-z/logs", {
    params: {
      patient_zis_number: Number(patientZisNumber),
    },
  });

  return sbvzLogsScheme.parse(result.data);
}

const sbvzLogScheme = z.object({
  id: z.number(),
  certificate_uuid: z.string(),
  at: z.string().transform((val) => new Date(val)),
  payload: z.object({
    header: z.object({
      at: z.object({
        date: z.string(),
        timezone: z.string(),
        timezone_type: z.number(),
      }),
      by: z.object({
        type: z.string(),
        user_id: z.number(),
        healthcare_provider_id: z.number(),
      }),
      type: z.string(),
    }),
    payload: z.object({
      at: z.string(),
      actor: z.object({
        type: z.string(),
        user_id: z.number(),
        healthcare_provider_id: z.number(),
      }),
      patient_zis_number: z.number().optional(),
    }),
  }),
  created_at: z.string().transform((val) => new Date(val)),
  updated_at: z.optional(z.string().transform((val) => new Date(val))),
});

const sbvzLogsScheme = z.array(sbvzLogScheme);

export type SbvzLog = z.infer<typeof sbvzLogScheme>;
export type SbvzLogs = z.infer<typeof sbvzLogsScheme>;

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useSbvzLog(patientZisNumber: Ref<number>) {
  const queryClient = useQueryClient();

  function invalidateSbvzLog(patientZisNumber: number) {
    queryClient.invalidateQueries({
      queryKey: ["sbvzLog", patientZisNumber],
    });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    enabled: hasFeatureFlag("sbv-z"),
    retry: 1,
    queryKey: ["sbvzLog", patientZisNumber] as const,
    queryFn: ({ queryKey }) => getSbvzLog(queryKey[1]),
  });

  return { ...query, invalidateSbvzLog };
}
