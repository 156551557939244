import { apiClient } from "../utils/axios";
import { Language } from "@/models/Language";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useLanguageStore = defineStore("languages", () => {
  const cache = ref<Promise<Language[]>>();

  const getCache = () =>
    (cache.value ??= apiClient
      .get("/languages")
      .then(({ data }) => data.languages ?? []));

  async function findAll(): Promise<Language[]> {
    return await getCache();
  }

  return { findAll };
});
