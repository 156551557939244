<template>
  <label
    class="relative box-border inline-flex cursor-pointer items-baseline gap-2 border transition duration-75 focus-within:z-20 focus-within:border-blue-600 focus-within:ring focus:z-10 focus:outline-none"
    :class="[
      checked
        ? 'z-10 border-blue-500 bg-blue-500 text-white'
        : 'border-gray-300 bg-white text-gray-700 hover:bg-gray-50 hover:shadow-inner dark:border-neutral-600 dark:bg-neutral-700 dark:text-neutral-300 hover:dark:bg-neutral-600',
      disabled ? 'cursor-default opacity-50' : 'hover:shadow-inner',
      collapse
        ? [
            'first:rounded-tl first:rounded-tr sm:first:rounded-bl sm:first:rounded-tr-none',
            'last:rounded-bl last:rounded-br sm:last:rounded-bl-none sm:last:rounded-tr',
            '-mt-px justify-center first:mt-0 sm:-ml-px sm:mt-0 sm:justify-start sm:first:ml-0',
          ]
        : '-ml-px first:ml-0 first:rounded-l last:rounded-r',
      getSizeClass(),
      textSize,
    ]"
  >
    <input
      class="sr-only m-0 box-border"
      v-model="modelValue"
      type="radio"
      :name="name"
      :value="optionValue"
      :disabled="disabled"
    />
    <slot></slot>
    <i
      v-if="icon"
      :class="[icon, iconPosition === 'right' ? 'order-last' : 'order-first']"
    ></i>
  </label>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";
import { TextSizes } from "@/libraries/UI/text";

const modelValue = defineModel<T>({ required: true });

const props = withDefaults(
  defineProps<{
    optionValue: T;
    icon?: string;
    iconPosition?: "left" | "right";
    disabled?: boolean;
    name?: string;
    collapse?: boolean;
    size?: "md" | "sm" | "xs";
    textSize?: TextSizes;
  }>(),
  { textSize: "text-sm" },
);

const checked = computed(() => {
  return props.optionValue === modelValue.value;
});

function getSizeClass() {
  switch (props.size) {
    case "xs":
      return "px-2 py-1";
    case "sm":
      return "px-3 py-1.5";
    case "md":
    default:
      return "px-4 py-2 font-medium";
  }
}
</script>
