import { z } from "zod";
import { apiClient } from "@/libraries/utils/axios";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { unref } from "vue";

export async function getBsn(zisNumber: MaybeRef<number>): Promise<BsnResult> {
  const result = await apiClient.get(`/patients/${unref(zisNumber)}/bsn`);
  return BsnSchema.parse(result.data);
}

const BsnSchema = z.object({
  bsn: z.optional(
    z
      .union([z.string(), z.number()])
      .transform((val): string => val.toString()),
  ),
  success: z.boolean(),
});

export type BsnResult = z.infer<typeof BsnSchema>;
