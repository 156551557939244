<template>
  <div v-show="isActive">
    <template v-if="!lazyLoading || hasLoaded">
      <slot></slot>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { TabManager } from "@/libraries/managers/TabManager";

const props = defineProps({
  badgeColor: {
    type: String,
  },
  label: {
    default: "Untitled Tab",
    type: String,
  },
  lazyLoading: {
    type: Boolean,
  },
  name: {
    required: true,
    type: String,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
});

const manager: TabManager | undefined = inject("tabManager");
if (!manager) {
  throw new Error("Expected Tabmanager");
}

const tab = ref<NonNullable<typeof manager>["tabs"][number]>();
const isActive = computed(() => manager.activeTabName === props.name);
const hasLoaded = ref(false);

onMounted(() => {
  tab.value = {
    name: props.name,
    label: props.label,
    badgeColor: props.badgeColor ?? undefined,
    disabled: props.disabled,
  };
  manager.registerTab({
    name: props.name,
    label: props.label,
    badgeColor: computed(() => props.badgeColor),
    disabled: computed(() => props.disabled),
  });
});

watch(
  isActive,
  () => {
    if (isActive.value) {
      hasLoaded.value = true;
    }
  },
  { immediate: true },
);

watch(
  () => props.badgeColor,
  () => {
    if (!tab.value) {
      return;
    }
    tab.value.badgeColor = props.badgeColor;
  },
);

onBeforeUnmount(() => manager.unregisterTab(props.name));
</script>
