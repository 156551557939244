<template>
  <div>
    <flux-card-button-header
      class="m-2"
      :title="$t('patient.edit.payer_insurers.new_insurance')"
      icon="fal fa-plus"
    />
    <flux-card class="mb-5" shadow="never" ref="payer_insurer_card">
      <PayerInsurerFormComponent
        v-model:modelValue="newPayerInsurer"
        formPropRoot="payer_insurers"
        @error="emit('error', $event)"
      />
      <flux-button-group class="mt-8 justify-end">
        <flux-button
          ref="btn_cancel"
          size="small"
          type="default"
          @click="cancel"
          >{{ $t("general.cancel") }}</flux-button
        >
        <flux-submit-button
          ref="btn_update"
          size="small"
          icon="fal fa-pencil"
          @click="create"
          type="primary"
          >{{ $t("general.create") }}</flux-submit-button
        >
      </flux-button-group>
    </flux-card>
  </div>
</template>
<script lang="ts" setup>
import PayerInsurerFormComponent from "../Patient/PayerInsurerForm.vue";
import { ref } from "vue";
import { PayerInsurerForm } from "../../models/PayerInsurer";
import { apiClient } from "@/libraries/utils/axios";
import { useNotify } from "@/composables/notify";
import { MessageBag } from "@/libraries/utils/MessageBag";
import { Patient } from "@/composables/patient";
import { $t } from "@/libraries/i18n";
import { handleErrors } from "@/libraries/utils/errorHandling";
import { endOfYear, format, startOfYear } from "date-fns";

const { notify } = useNotify();

const props = defineProps<{
  patient: Patient;
}>();

const emit = defineEmits<{
  (e: "cancel"): void;
  (e: "created"): void;
  (e: "error", error: boolean): void;
}>();

const newPayerInsurer = ref<PayerInsurerForm>(createEmptyForm());
const errors = ref<MessageBag | null>(null);

function createEmptyForm() {
  const yearStart = format(startOfYear(new Date()), "yyyy-MM-dd");
  const yearEnd = format(endOfYear(new Date()), "yyyy-MM-dd");
  let emptyPayerInsurer: PayerInsurerForm = {
    number: "", // ?: string;
    type: "B", // ?: InsuranceTypes;
    insurer_id: undefined,
    insurer_name: "",
    insurer_number: "",
    package_name: "",
    start_date: yearStart,
    end_date: yearEnd, // ?: Date;
  };
  return emptyPayerInsurer;
}

async function cancel() {
  emit("cancel");
}

async function create() {
  try {
    const res = await apiClient.post(
      "/patients/:zis_number/payer_insurers/",
      newPayerInsurer.value,
      {
        params: {
          zis_number: props.patient.zis_number,
        },
      },
    );
    if (!res.data.success) {
      return false;
    }
    notify({
      message: $t("payer_insurer.created") as string,
      type: "success",
    });
    emit("created");
    errors.value = null;
    return true;
  } catch (e) {
    handleErrors({ error: e });
  }
}
</script>
