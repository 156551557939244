<template>
  <div
    class="relative rounded-lg border border-gray-300 bg-white py-1 text-sm shadow-lg dark:border-neutral-600 dark:bg-neutral-700"
  >
    <template v-if="shownItems.length">
      <span
        class="mb-2 mt-2 px-2 text-xs font-medium uppercase text-slate-900 dark:text-white"
        >Snippets</span
      >
      <div class="space-y-0.5">
        <button
          class="m-0 block w-full cursor-pointer bg-transparent px-2 py-1 text-left font-sans text-sm hover:bg-slate-100 hover:dark:bg-neutral-600"
          v-for="(item, index) in shownItems"
          :class="[
            index === selectedIndex
              ? 'bg-slate-100 text-slate-900 dark:bg-neutral-700 dark:text-white'
              : 'text-slate-700 dark:text-neutral-300',
          ]"
          :key="index"
          @click="selectItem(index)"
        >
          {{ item.name }}
        </button>
      </div>
    </template>
    <div
      class="m-0 block w-full px-2 py-1 text-left text-sm text-slate-800 dark:text-white"
      v-else
    >
      Geen resultaten gevonden
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from "vue";
import { ref } from "vue";
const props = defineProps({
  items: {
    type: Array<{ name: string; value: string }>,
    required: true,
  },
  command: {
    type: Function,
    required: true,
  },
});

const maxItems = 20;
const shownItems = computed(() => {
  return props.items.slice(0, maxItems);
});

const selectedIndex = ref(0);
watch(
  () => shownItems,
  () => (selectedIndex.value = 0),
);
defineExpose({ onKeyDown, upHandler, downHandler, enterHandler });
function onKeyDown({ event }: { event: KeyboardEvent }) {
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }
  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }
  if (event.key === "Enter") {
    enterHandler();
    return true;
  }
  return false;
}
function upHandler() {
  selectedIndex.value =
    (selectedIndex.value + shownItems.value.length - 1) %
    shownItems.value.length;
}
function downHandler() {
  selectedIndex.value = (selectedIndex.value + 1) % shownItems.value.length;
}
function enterHandler() {
  selectItem(selectedIndex.value);
}
function selectItem(index: number) {
  const item = shownItems.value[index];
  if (item) {
    props.command(item);
  }
}
</script>
