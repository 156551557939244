import { z } from "zod";

export const treatmentHistorySchema = z.object({
  year: z.string(),
  treatment_count: z.number(),
  appointment_count: z.number(),
  planned_appointment_count: z.number(),
  declaration_count: z.number(),
  imported_declaration_count: z.number(),
});

export type TreatmentHistory = z.infer<typeof treatmentHistorySchema>;

export const patientReferralTreatmentHistorySchema = z.object({
  appointment_count: z.number(),
  declaration_count: z.number(),
  declaration_without_appointment_count: z.number(),
  imported_declaration_count: z.number(),
  is_chronic: z.boolean(),
  chronic_count: z.number(),
  start_of_referral: z.string().optional(),
});

export type PatientReferralTreatmentHistory = z.output<
  typeof patientReferralTreatmentHistorySchema
>;

export const patientReferralTreatmentHistoriesSchema = z.array(
  patientReferralTreatmentHistorySchema.merge(
    z.object({ referral_id: z.number(), referral_diagnosis: z.string() }),
  ),
);

export type PatientReferralTreatmentHistories = z.output<
  typeof patientReferralTreatmentHistoriesSchema
>;
