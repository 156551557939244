<template>
  <div class="flex select-none flex-row flex-wrap gap-1">
    <ItemLabel
      v-for="(label, index) in props.labels"
      :name="label.name"
      :color="label.color"
      :unplannable="label.unplannable"
      :size="size"
      @click="emit('click', label)"
      :key="label.name + index"
    />
  </div>
</template>

<script
  lang="ts"
  setup
  generic="
    T extends {
      name: string;
      color: ColorStyleColor;
      unplannable?: boolean;
    }
  "
>
import ItemLabel from "@/components/ItemLabel.vue";
import { ColorStyleColor } from "@/models/ColorStyles";
import { computed } from "vue";

const props = defineProps<{
  labels: T[];
  alwaysExpanded?: true;
}>();

const emit = defineEmits<{ (e: "click", v: T): void }>();

const size = computed(() => {
  if (props.alwaysExpanded) {
    return "normal";
  }
  return props.labels.length !== 1 ? "small" : "normal";
});
</script>
