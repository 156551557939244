import { z } from "zod";

export const zorgvergoedingResultSchema = z.object({
  uuid: z.string().uuid().optional(),
  data: z.object({
    bvav: z.union([z.literal("AV"), z.literal("BV"), z.literal("TV")]),
    verg: z.string(),
    bijz: z.string().optional(),
    machtiging: z.string().optional(),
    debit: z.string().optional(),
    verwijzing: z.string().optional(),
    aantal: z.number().optional(),
    bedrag: z.number().optional(),
    aantal2: z.number().optional(),
    bedrag2: z.number().optional(),
    mtaant: z
      .any()
      .transform((val: any): undefined | number =>
        typeof val === "string" || typeof val === "number"
          ? Number(val)
          : undefined,
      ),
    jrint: z.string().optional(),
    mtjrind: z.string().optional(),
    scrint: z.string().optional(),
    decltel: z.string().optional(),
    declmail: z.string().optional(),
    dtf: z.string().optional(),
    verz: z.string().optional(),
  }),
});

export type ZorgvergoedingResult = z.infer<typeof zorgvergoedingResultSchema>;
