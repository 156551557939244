<template>
  <div class="patient-edit-forms">
    <div class="space-y-2">
      <div v-for="(contacts, index) in form.contacts">
        <ContactComponent
          v-if="patient"
          v-model:modelValue="form.contacts[index]"
          :patient="patient"
          @removed="form.contacts.splice(index, 1)"
        >
        </ContactComponent>
      </div>
    </div>
    <div class="add-more">
      <template v-if="form.contacts.length > 0">
        <flux-button @click="add" type="text" icon="fal fa-plus"
          >{{ $t("patient.create.form.step.contacts.add_more") }}
        </flux-button>
      </template>
      <template v-else>
        <flux-card shadow="never">
          <div style="text-align: center">
            <img
              src="../../assets/images/connected.svg"
              style="width: 100%; max-width: 300px"
            />
          </div>
          <div style="text-align: center; margin-top: 14px">
            {{ $t("patient.edit.contacts.empty") }}
          </div>
          <div style="text-align: center; margin-top: 14px">
            <flux-button
              icon="fal fa-plus"
              ref="btn_add_more"
              type="primary"
              @click="add"
              >{{
                $t("patient.create.form.step.contacts.add_first")
              }}</flux-button
            >
          </div>
        </flux-card>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, toRef, watch } from "vue";
import { ContactForm } from "../../models/Contact";
import ContactComponent from "../Patient/Contact.vue";
import { Patient, usePatient } from "@/composables/patient";
import { toRaw } from "vue";
import { $t } from "@/libraries/i18n";

const props = defineProps<{
  zisNumber: number;
}>();

const { data: patient } = usePatient(toRef(props, "zisNumber"));

const form: { contacts: (Patient["contacts"][number] | ContactForm)[] } =
  reactive({
    contacts: [],
  });

watch(
  patient,
  () => {
    if (!patient.value) {
      return;
    }
    form.contacts = toRaw(patient.value.contacts);
  },
  { immediate: true, deep: false },
);

function add() {
  form.contacts.push({
    fullname: "",
    relationship: "BRO",
    role: "1",
    phone_numbers: [],
    email_addresses: [],
    is_payer: false,
  });
}
</script>
