import { PatientLabel, patientLabelSchema } from "@/models/PatientLabel";
import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import { z } from "zod";

export async function getPatientLabels(): Promise<PatientLabel[]> {
  const { data } = await apiClient.get("/labels");

  return handledZodParse({
    schema: z.array(patientLabelSchema),
    input: data.labels,
    notifyUser: false,
  });
}
