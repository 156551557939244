import { getUser } from "@/libraries/plugins/getUser";
import { apiClient } from "@/libraries/utils/axios";
import { useQueryClient } from "@tanstack/vue-query";
import { Ref } from "vue";
import { z } from "zod";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { useTanPagePaginator } from "./tanCursorPaginator";
import { pagePaginatorScheme } from "@/models/Paginator";

const categories = ["finance", "quality"] as const;

const apiCallCommand = z.object({
  type: z.literal("api"),
  data: z.any(),
  url: z.string(),
  method: z.enum(["PUT", "GET", "POST", "PATCH", "DELETE"]),
});

const navigateCommand = z.object({
  type: z.literal("navigate"),
  name: z.string(),
  data: z.record(z.string(), z.any()).optional(),
  hash: z.string().optional(),
});

const commandScheme = z.discriminatedUnion("type", [
  apiCallCommand,
  navigateCommand,
]);

const workListActionScheme = z.object({
  description: z.string(),
  command: commandScheme,
});

export type WorkListAction = z.infer<typeof workListActionScheme>;
export type WorkListActionCommand = z.infer<typeof commandScheme>;

const itemScheme = z.object({
  hash: z.string(),
  assigned_user_id: z.number(),
  description: z.string(),
  categories: z.array(z.enum(categories)),
  date: z.optional(z.string().transform((v) => new Date(v))),
  patient_zis_number: z.number().optional(),
  actions: z.array(workListActionScheme),
});

export type WorkListItem = z.infer<typeof itemScheme>;

async function fetchWorkListForUser(page: number, options: { userId: number }) {
  const res = await apiClient.get(
    `work_list/${options.userId}/items?page=${page}`,
  );
  return {
    page_paginator: pagePaginatorScheme
      .extend({ data: z.array(itemScheme) })
      .parse(res.data),
  };
}

export function useWorkListUserItems(options: Ref<{ userId: number }>) {
  const client = useQueryClient();
  const { query, paginationData, paginator } = useTanPagePaginator(
    "work_list",
    (page: number, options: { userId: number }) =>
      fetchWorkListForUser(page, options),
    options,
    hasFeatureFlag("work-list-owner") ||
      (hasFeatureFlag("work-list") && getUser().id === options.value.userId),
    3_000,
  );

  const invalidate = () => {
    client.invalidateQueries({ queryKey: ["work_list"] });
  };

  return { ...query, paginationData, paginator, invalidate };
}
