import { GenderType } from "./../../models/Patient";
import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";
import * as Sentry from "@sentry/vue";

export async function sbvzSearch(
  bsn?: string,
  zisNumber?: number,
): Promise<SbvzResponse> {
  const { data } = await apiClient.post("/apps/sbv-z/search", {
    patient_zis_number: zisNumber,
    query: bsn, // test bsn '123456782'
  });
  const parsedResult = responseSchema.safeParse(data);
  if (!parsedResult.success) {
    Sentry.captureException(parsedResult.error);
    return data;
  } else {
    return parsedResult.data;
  }
}

const failedResponseSchema = z.object({
  success: z.literal(false),
  messages: z.array(z.string()),
  result: z.undefined(),
});

const successResponseSchema = z.object({
  success: z.literal(true),
  messages: z.array(z.string()),
  result: z.object({
    BSN: z.string(),
    Voornamen: z.string(),
    AdellijkeTitelPredikaat: z.string(),
    VoorvoegselGeslachtsnaam: z.string(),
    Geslachtsnaam: z.string(),
    Geboortedatum: z.string(),
    Geboorteplaats: z.string(),
    Geboorteland: z.string(),
    Geslachtsaanduiding: z
      .enum(["M", "V", "O"])
      .transform(
        (g): GenderType =>
          g.replace("V", "F").replace("O", "UNK") as GenderType,
      ),
    GemeenteVanInschrijving: z.string(),
    FunctieAdres: z.string(),
    Gemeentedeel: z.string(),
    Straatnaam: z.string(),
    Huisnummer: z.string(),
    Huisletter: z.string(),
    Huisnummertoevoeging: z.string(),
    AanduidingBijHuisnummer: z.string(),
    Postcode: z.string(),
    Locatiebeschrijving: z.string(),
    LandVanwaarIngeschreven: z.string(),
    Woonplaatsnaam: z.string(),
    Regel1AdresBuitenland: z.string(),
    Regel2AdresBuitenland: z.string(),
    Regel3AdresBuitenland: z.string(),
    LandAdresBuitenland: z.string(),
    DatumAanvangAdresBuitenland: z.string(),
  }),
});

const responseSchema = z.discriminatedUnion("success", [
  failedResponseSchema,
  successResponseSchema,
]);

type SbvzResponse = z.infer<typeof responseSchema>;
