<template>
  <flux-input
    v-model:modelValue="modelValue"
    type="tel"
    :prop="prop"
    pattern="^([0-9\(\)\/\+ \-]*)$"
  ></flux-input>
</template>

<script lang="ts" setup>
const modelValue = defineModel<string>("modelValue", { required: true });

defineProps<{ prop?: string }>();
</script>
