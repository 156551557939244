<template>
  <div
    @click="(ev) => $emit('click', ev)"
    :class="[
      'flex cursor-pointer select-none border-l-2 py-3 pl-4 hover:text-blue-400',
      active
        ? 'border-blue-500 text-blue-500 hover:text-blue-500'
        : 'border-transparent',
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],
  setup() {},
});
</script>
