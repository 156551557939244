import { handledZodParse, handleErrors } from "@/libraries/utils/errorHandling";
import { AxiosInstance } from "axios";
import { InjectionKey } from "vue";
import {
  createPatientTreatmentCountSchema,
  CreatePatientTreatmentCount,
  PatientTreatmentCount,
  patientTreatmentCountSchema,
  patientTreatmentCountListSchema,
} from "./patientTreatmentCount";

export const patientTreatmentCountRepositoryKey = Symbol() as InjectionKey<
  () => PatientTreatmentCountRepository
>;

export interface PatientTreatmentCountRepository {
  findAllForPatient(
    patientZisNumber: number,
  ): Promise<PatientTreatmentCount[] | void>;
  create(
    patientZisNumber: number,
    form: CreatePatientTreatmentCount,
  ): Promise<CreatePatientTreatmentCount | void>;
}

export class PatientTreatmentCountRepositoryUsingApi
  implements PatientTreatmentCountRepository
{
  constructor(protected apiClient: AxiosInstance) {}
  async findAllForPatient(
    patientZisNumber: number,
  ): Promise<PatientTreatmentCount[] | void> {
    try {
      const { data } = await this.apiClient.get(
        `/patients/${patientZisNumber}/treatment_counts`,
      );
      return handledZodParse({
        schema: patientTreatmentCountListSchema,
        input: data.patient_treatment_counts,
        notifyUser: false,
        reportError: true,
      });
    } catch (error) {
      handleErrors({
        error,
      });
    }
  }

  async create(
    patientZisNumber: number,
    form: CreatePatientTreatmentCount,
  ): Promise<CreatePatientTreatmentCount | void> {
    try {
      handledZodParse({
        schema: createPatientTreatmentCountSchema,
        input: form,
      });
      const { data } = await this.apiClient.put(
        `/patients/${patientZisNumber}/treatment_counts`,
        form,
      );
      return handledZodParse({
        schema: patientTreatmentCountSchema,
        input: data.patient_treatment_count,
        notifyUser: false,
        reportError: true,
      });
    } catch (error) {
      handleErrors({
        error,
      });
    }
  }
}

export class PatientTreatmentCountRepositoryUsingMemory
  implements PatientTreatmentCountRepository
{
  constructor(private treatmentCounts: PatientTreatmentCount[] = []) {}

  async findAllForPatient(
    patientZisNumber: number,
  ): Promise<void | PatientTreatmentCount[]> {
    return this.treatmentCounts.filter(
      ({ patient_zis_number }) => patient_zis_number === patientZisNumber,
    );
  }

  async create(patientZisNumber: number, form: CreatePatientTreatmentCount) {
    this.treatmentCounts.push({
      patient_zis_number: patientZisNumber,
      amount: form.amount,
      source: "manual",
      reference_year: form.reference_year,
      created_at: new Date(),
    });
    return form;
  }
}
