<template>
  <div
    class="flex h-4 items-center gap-1 rounded-full border border-gray-200 px-2.5 py-0.5 text-xs font-medium dark:border-neutral-600"
  >
    <i class="text-sm" :class="[textClass, icon]" />
    <span class="text-gray-800 dark:text-neutral-300">{{ text }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  icon: string;
  color: "rose" | "lime" | "amber" | "purple";
  text: string;
}>();

const textClass = computed(() => {
  if (props.color === "amber") {
    return "text-amber-500";
  }

  if (props.color === "lime") {
    return "text-lime-500";
  }

  if (props.color === "purple") {
    return "text-purple-500";
  }

  return "text-rose-500";
});
</script>
