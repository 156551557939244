import { JSONContent } from "@tiptap/vue-3";
import { $t } from "../i18n";
import { assertNever } from "../utils";
import { Options } from "./ReplacePlaceholders";
import { MedicalUpdateWithAttachements } from "@/models/MedicalUpdate";
import moment from "moment";
import { Patient } from "@/composables/patient";

export const medicalUpdateToJsonContent = ({
  medicalUpdate,
}: Required<Pick<Options, "medicalUpdate">>): JSONContent[] => {
  if (medicalUpdate.type === "MedicalUpdateText") {
    return [...transformField(medicalUpdate.data.body), getBreak()];
  } else if (medicalUpdate.type == "MedicalUpdateConsult") {
    return [
      getLabel("Reden van komst"),
      ...transformField(medicalUpdate.data.body.RvK),

      getBreak(),
      getLabel("Anamnese"),
      ...transformField(medicalUpdate.data.body.A),

      getBreak(),
      getLabel("Lichamelijk onderzoek"),
      ...transformField(medicalUpdate.data.body.LO),

      getBreak(),
      getLabel("Aanvullend onderzoek"),
      ...transformField(medicalUpdate.data.body.AO),

      getBreak(),
      getLabel("Conclusie"),
      ...transformField(medicalUpdate.data.body.C),

      getBreak(),
      getLabel("Beleid"),
      ...transformField(medicalUpdate.data.body.B),
      getBreak(),
    ];
  } else if (medicalUpdate.type == "MedicalUpdateConsultSOEP") {
    return [
      getLabel("Subjectief"),
      ...transformField(medicalUpdate.data.body.S),

      getBreak(),
      getLabel("Objectief"),
      ...transformField(medicalUpdate.data.body.O),

      getBreak(),
      getLabel("Evaluatie"),
      ...transformField(medicalUpdate.data.body.E),

      getBreak(),
      getLabel("Plan"),
      ...transformField(medicalUpdate.data.body.P),
      getBreak(),
    ];
  } else if (medicalUpdate.type === "MedicalUpdateScreening") {
    if (medicalUpdate.data.body.pluis === "Pluis") {
      return [
        getLabel("Screening"),
        ...transformField(medicalUpdate.data.body.screening),

        getBreak(),
        getLabel("Pluis"),
        ...transformField(medicalUpdate.data.body.pluis),

        getBreak(),
        getLabel("Indicatie verder onderzoek"),
        ...transformField(medicalUpdate.data.body.verder_onderzoek),
        getBreak(),
      ];
    } else {
      return [
        getLabel("Screening"),
        ...transformField(medicalUpdate.data.body.screening),

        getBreak(),
        getLabel("Pluis"),
        ...transformField(medicalUpdate.data.body.pluis),

        getBreak(),
        getLabel("Vlag"),
        ...transformField(medicalUpdate.data.body.vlag),

        getBreak(),
        getLabel("Afwijkingen"),
        ...transformField(medicalUpdate.data.body.afwijkingen),

        getBreak(),
        getLabel("Beleid"),
        ...transformField(medicalUpdate.data.body.beleid),

        getBreak(),
        getLabel("Indicatie verder onderzoek"),
        ...transformField(medicalUpdate.data.body.verder_onderzoek),
        getBreak(),
      ];
    }
  } else if (medicalUpdate.type === "MedicalUpdateIntakeFlowScreening") {
    if (medicalUpdate.data.body.pluis === "Pluis") {
      return [
        getLabel("Pluis"),
        ...transformField(medicalUpdate.data.body.pluis),

        getBreak(),
        getLabel("Indicatie verder onderzoek"),
        ...transformField(medicalUpdate.data.body.indicatie),

        getBreak(),
        getLabel("Toestemming gegevens delen en overleg met arts"),
        ...transformField(medicalUpdate.data.body.consent),
        getBreak(),
      ];
    } else {
      return [
        getLabel("Pluis"),
        ...transformField(medicalUpdate.data.body.pluis),

        getBreak(),
        getLabel("Vlag"),
        ...transformField(medicalUpdate.data.body.vlag),

        getBreak(),
        getLabel("Afwijkingen"),
        ...transformField(medicalUpdate.data.body.afwijkingen),

        getBreak(),
        getLabel("Beleid"),
        ...transformField(medicalUpdate.data.body.beleid),

        getBreak(),
        getLabel("Indicatie verder onderzoek"),
        ...transformField(medicalUpdate.data.body.indicatie),

        getBreak(),
        getLabel("Toestemming gegevens delen en overleg met arts"),
        ...transformField(medicalUpdate.data.body.consent),
        getBreak(),
      ];
    }
  } else if (medicalUpdate.type === "MedicalUpdateIntake") {
    return [
      getLabel("Reden van komst"),
      ...transformField(medicalUpdate.data.body.RvK),

      getBreak(),
      getLabel("Anamnese"),
      ...transformField(medicalUpdate.data.body.A),

      getBreak(),
      getLabel("Lichamelijk onderzoek"),
      ...transformField(medicalUpdate.data.body.LO),

      getBreak(),
      getLabel("Aanvullend onderzoek"),
      ...transformField(medicalUpdate.data.body.AO),

      getBreak(),
      getLabel("Diagnose"),
      ...transformField(medicalUpdate.data.body.diagnose),

      getBreak(),
      getLabel("Conclusie"),
      ...transformField(medicalUpdate.data.body.C),

      getBreak(),
      getLabel("Indicatie"),
      ...transformField(medicalUpdate.data.body.pluis),

      getBreak(),
      getLabel("Beleid"),
      ...transformField(medicalUpdate.data.body.B),

      getBreak(),
      getLabel("Bespreking"),
      ...transformField(medicalUpdate.data.body.bespreking),
      getBreak(),
    ];
  } else if (medicalUpdate.type === "MedicalUpdateIntakeFlowIntake") {
    const content = [
      getLabel("Reden van komst"),
      ...transformField(medicalUpdate.data.body.RvK),
    ];

    if (medicalUpdate.data.body.hulpvraag) {
      content.push(
        getBreak(),
        getLabel("Hulpvraag"),
        ...transformField(medicalUpdate.data.body.hulpvraag),
      );
    }

    return [
      ...content,
      getBreak(),
      getLabel("Anamnese"),
      ...transformField(medicalUpdate.data.body.anamnese),

      getBreak(),
      getLabel("Lichamelijk onderzoek"),
      ...transformField(medicalUpdate.data.body.LO),

      getBreak(),
      getLabel("Aanvullend onderzoek"),
      ...transformField(medicalUpdate.data.body.AO),

      getBreak(),
      getLabel("Diagnose"),
      ...transformField(medicalUpdate.data.body.diagnose),

      getBreak(),
      getLabel("Conclusie"),
      ...transformField(medicalUpdate.data.body.conclusie),

      getBreak(),
      getLabel("Indicatie behandelen"),
      ...transformField(medicalUpdate.data.body.indicatie),
      getBreak(),
    ];
  } else if (medicalUpdate.type === "MedicalUpdateEvaluation") {
    return [
      getLabel("Patiënt aanwezig"),
      ...transformField(
        medicalUpdate.data.body.patient_is_present
          ? $t("general.yes")
          : $t("general.no"),
      ),

      getBreak(),
      getLabel("Soort evaluatie"),
      ...transformField(medicalUpdate.data.body.evaluation_type),

      getBreak(),
      getLabel("Subjectief"),
      ...transformField(medicalUpdate.data.body.subjective),

      getBreak(),
      getLabel("Objectief"),
      ...transformField(medicalUpdate.data.body.objective),

      getBreak(),
      getLabel("Hoofddoel"),
      ...transformField(medicalUpdate.data.body.main_goal),

      getBreak(),
      getLabel("Progressie"),
      ...transformField(
        $t("care_goal.progress." + medicalUpdate.data.body.progress),
      ),

      getBreak(),
      getLabel("Prognose"),
      ...transformField(
        $t("care_plan.prognosis." + medicalUpdate.data.body.prognosis),
      ),

      getBreak(),
      getLabel("Afwijkingen"),
      ...transformField(medicalUpdate.data.body.anomalies),

      getBreak(),
      getLabel("Aanbevelingen"),
      ...transformField(medicalUpdate.data.body.recommendations),
      getBreak(),
    ];
  } else if (
    medicalUpdate.type === "MedicalUpdateQuestionnaireResponse" ||
    medicalUpdate.type === "unlinkedAppointment"
  ) {
    throw new Error("unexpected medical update of type " + medicalUpdate.type);
  } else {
    assertNever(medicalUpdate.type);
  }
};

export const referralToLegalExportJsonContent = (
  referral: Patient["referrals"][0],
  medicalUpdates: MedicalUpdateWithAttachements[],
): JSONContent[] => {
  const screening = medicalUpdates
    .filter(
      (value) =>
        value.referral_id === referral.id &&
        (value.type === "MedicalUpdateScreening" ||
          value.type === "MedicalUpdateIntakeFlowScreening") &&
        value.appointment !== undefined,
    )
    .at(0);
  const intake = medicalUpdates
    .filter(
      (value) =>
        value.referral_id === referral.id &&
        (value.type === "MedicalUpdateIntake" ||
          value.type === "MedicalUpdateIntakeFlowIntake") &&
        value.appointment !== undefined,
    )
    .at(0);
  const output: JSONContent[] = [];
  if (screening !== undefined) {
    output.push(
      getLabel(
        "Screening (" +
          moment(screening.appointment?.start).format("DD-MM-YYYY") +
          ")",
      ),
    );
    output.push(...medicalUpdateToJsonContent({ medicalUpdate: screening }));
  }

  if (intake !== undefined) {
    output.push(
      getLabel(
        "Intake (" +
          moment(intake.appointment?.start).format("DD-MM-YYYY") +
          ")",
      ),
    );
    output.push(...medicalUpdateToJsonContent({ medicalUpdate: intake }));
  }

  for (const update of medicalUpdates
    .filter(
      (value) =>
        value.referral_id == referral.id &&
        (value.appointment !== undefined || value.is_imported) &&
        ![
          "MedicalUpdateScreening",
          "MedicalUpdateIntakeFlowScreening",
          "MedicalUpdateIntake",
          "MedicalUpdateIntakeFlowIntake",
          "MedicalUpdateQuestionnaireResponse",
          "unlinkedAppointment",
        ].includes(value.type),
    )
    .sort((a, b): number => {
      const startA = moment(a.appointment?.start ?? a.created_at);
      const startB = moment(b.appointment?.start ?? b.created_at);
      return startA.isSame(startB) ? 0 : startA.isBefore(startB) ? 1 : -1;
    })) {
    let consultName = "Consult";
    switch (update.type) {
      case "MedicalUpdateConsultSOEP":
        consultName = "SOEPConsult";
        break;
      case "MedicalUpdateEvaluation":
        consultName = "Evaluatie Consult";
        break;
    }
    const at = moment(
      update.appointment?.start ??
        update.import_created_at ??
        update.created_at,
    ).format("DD-MM-YYYY");

    output.push(
      getBreak(),
      getLabel(consultName + " (" + at + ")"),
      ...medicalUpdateToJsonContent({ medicalUpdate: update }),
    );
  }

  return output;
};

const transformField = (field: string | JSONContent): JSONContent[] => {
  if (typeof field === "string") {
    return field.length === 0
      ? []
      : [
          {
            type: "paragraph",
            content: [
              {
                type: "text",
                text: field,
              },
            ],
          },
        ];
  }

  const content = JSON.parse(JSON.stringify(field));
  if (content.type == "doc") {
    content.type = "paragraph";
    return content.content;
  }
  return [content];
};

function getLabel(label: string): JSONContent {
  return {
    type: "paragraph",
    content: [
      {
        type: "text",
        text: label,
        marks: [{ type: "bold" }],
      },
    ],
  };
}

function getBreak(): JSONContent {
  return { type: "paragraph" };
}
