interface Item {
  prop: string;
}

export default class Manager<T extends Item> {
  public items: T[] = [];

  register(item: T) {
    this.items.push(item);
  }

  unregister(prop: string) {
    this.items = this.items.filter((item) => item.prop !== prop);
  }
}
