import { LaravelPaginator, Paginator } from "@/models/Paginator";
import {
  useQueryClient,
  useQuery,
  keepPreviousData,
} from "@tanstack/vue-query";
import { Ref, ref, computed, watch, MaybeRefOrGetter } from "vue";

export function useTanPagePaginator<
  T,
  QueryOptions extends Record<string, any>,
>(
  uniqueQueryKey: string,
  queryFn: (
    page: number,
    options: QueryOptions,
  ) => Promise<{
    page_paginator: { data: T[] } & LaravelPaginator;
  }>,
  options: Ref<QueryOptions>,
  enabled?: MaybeRefOrGetter<boolean>,
  staleTime = 30_000,
) {
  const page = ref<number>(1);

  const queryClient = useQueryClient();

  function prefetchPage(page: number, options: QueryOptions) {
    queryClient.prefetchQuery({
      queryKey: [uniqueQueryKey, page, options] as const,
      queryFn: ({ queryKey }) => queryFn(queryKey[1], queryKey[2]),
      staleTime,
      gcTime: 60_000,
    });
  }

  function useObjectQueryKey<T>(
    options: unknown,
    queryFn: (
      page: number,
      options: any,
    ) => Promise<{
      page_paginator: { data: T[] } & LaravelPaginator;
    }>,
  ) {
    return useQuery({
      queryKey: [uniqueQueryKey, page, options] as const,
      queryFn: ({ queryKey }) => queryFn(queryKey[1], queryKey[2]),
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
      staleTime,
      gcTime: 60_000,
      throwOnError: true,
      enabled,
    });
  }

  const query = useObjectQueryKey<T>(options, queryFn);

  watch(
    () => query.data.value?.page_paginator.data.length,
    (count) => {
      if (count === 0 && page.value > 1) {
        page.value = 1;
        queryClient.invalidateQueries({ queryKey: [uniqueQueryKey] });
      }
    },
  );

  const lastPage = computed((): number | undefined =>
    query.data.value && "last_page" in query.data.value.page_paginator
      ? query.data.value.page_paginator.last_page
      : Infinity,
  );
  const nextPage = computed((): number | undefined => {
    const candidate = (query.data.value?.page_paginator.current_page ?? 0) + 1;
    if (lastPage.value === undefined) {
      return candidate;
    }
    if (candidate <= lastPage.value) {
      return candidate;
    }

    return undefined;
  });
  const previousPage = computed((): number | undefined => {
    const candidate = (query.data.value?.page_paginator.current_page ?? 0) - 1;
    if (candidate > 0) {
      return candidate;
    }

    return undefined;
  });

  watch(
    nextPage,
    () => nextPage.value && prefetchPage(nextPage.value, options.value),
  );
  watch(
    previousPage,
    () => previousPage.value && prefetchPage(previousPage.value, options.value),
  );

  function next() {
    if (query.isLoading.value || nextPage.value === undefined) {
      return;
    }
    page.value = nextPage.value;
  }

  function prev() {
    if (query.isLoading.value || previousPage.value === undefined) {
      return;
    }
    page.value = previousPage.value;
  }

  const paginator = computed((): Paginator | undefined => {
    const paginator = query.data.value?.page_paginator;
    if (paginator === undefined) {
      return;
    }
    return {
      ...paginator,
      next,
      prev,
    };
  });

  return {
    query,
    paginationData: computed(
      (): undefined | T[] => query.data.value?.page_paginator.data,
    ),
    paginator,
    prefetchPage,
  };
}

export function useTanCursorPaginator<T>(
  dataView: Ref<string>,
  usedFilter: Ref<Record<string, string | boolean>>,
  queryFn: (
    cursor: string | undefined,
    usedFilter: Record<string, string | boolean>,
    filterLogic: "all" | "any",
  ) => Promise<any>,
  filterLogic: Ref<"AND" | "OR">,
) {
  const cursor = ref<string | undefined>();

  const queryClient = useQueryClient();

  function prefetchPage(
    cursor: string | undefined,
    usedFilter: Record<string, string | boolean>,
  ) {
    queryClient.prefetchQuery({
      queryKey: ["data_explorer", dataView, cursor, usedFilter, filterLogic],
      queryFn: () =>
        queryFn(
          cursor,
          usedFilter,
          filterLogic.value === "AND" ? "all" : "any",
        ),
      staleTime: 30_000,
      gcTime: 60_000,
    });
  }

  const { data, isLoading, isFetching } = useQuery({
    queryKey: [
      "data_explorer",
      dataView,
      cursor,
      usedFilter,
      filterLogic,
    ] as const,
    queryFn: (context) =>
      queryFn(
        context.queryKey[2],
        context.queryKey[3],
        filterLogic.value === "AND" ? "all" : "any",
      ),
    refetchOnWindowFocus: false,
    staleTime: 30_000,
    gcTime: 60_000,
    placeholderData: keepPreviousData,
  });

  const nextCursor = computed(() => data.value?.cursor_paginator.next_cursor);
  const previousCursor = computed(
    () => data.value?.cursor_paginator.prev_cursor,
  );

  watch(nextCursor, () => prefetchPage(nextCursor.value, usedFilter.value));
  watch(previousCursor, () =>
    prefetchPage(previousCursor.value, usedFilter.value),
  );

  function next() {
    if (isLoading.value || nextCursor.value === undefined) {
      return;
    }
    cursor.value = nextCursor.value;
  }

  function prev() {
    if (isLoading.value || previousCursor.value === undefined) {
      return;
    }
    cursor.value = previousCursor.value;
  }

  return {
    isLoading,
    data: computed((): T[] => data.value?.cursor_paginator.data),
    availableFilters: computed(() => data.value?.available_filters),
    next,
    prev,
    nextPageAvailable: computed(() => nextCursor.value !== undefined),
    prevPageAvailable: computed(() => previousCursor.value !== undefined),
    prefetchPage,
    isFetching,
  };
}
