<template>
  <div class="flex items-center md:grid md:grid-cols-[1fr,2fr,1fr] md:px-4">
    <div class="hidden gap-2 md:flex">
      <img
        class="w-8"
        v-if="!canViewPatient"
        src="./../assets/images/logo.svg"
      />
      <router-link :to="canViewPatient ? '/' : '/calendar'">
        <h2 class="m-0 -mb-1 text-xl font-black text-black dark:text-white">
          Flux <template v-if="showQaWarning">QA</template>
        </h2>
        <span class="text-sm font-light text-black dark:text-white">{{
          healthcareProviderName()
        }}</span>
      </router-link>
    </div>
    <div class="block h-8 w-8 px-2 md:hidden">
      <router-link :to="canViewPatient ? '/' : '/calendar'">
        <img src="./../assets/images/logo.svg" />
      </router-link>
    </div>
    <div class="relative block flex-grow p-2 md:hidden">
      <PatientTypeAhead
        v-if="canViewPatient"
        v-model:modelValue="searchInput"
        ref="typeahead_mobile"
        icon="far fa-search"
        :typeahead="typeahead"
        @select="selectPatient"
        @submit="search"
        :placeholder="$t('general.search')"
      />
    </div>
    <div class="hidden p-2 md:block">
      <PatientSearchBar v-if="canViewPatient" />
    </div>
    <div
      class="flex items-center justify-end md:hidden dark:bg-neutral-900 dark:text-white"
    >
      <flux-dropdown
        v-if="canViewPatient"
        :iconButton="true"
        icon="fal fa-bars"
        buttonSize="medium"
        direction="left"
      >
        <flux-dropdown-item>
          <router-link class="p-2 text-gray-700 dark:text-white" to="/calendar">
            {{ $t("calendar.title") }}
          </router-link>
        </flux-dropdown-item>
        <flux-dropdown-item>
          <router-link class="p-2 text-gray-700 dark:text-white" to="/patients">
            {{ $t("general.patients") }}
          </router-link>
        </flux-dropdown-item>
        <flux-dropdown-item>
          <router-link class="p-2 text-gray-700 dark:text-white" to="/finance">
            {{ $t("finance.invoice.invoices") }}
          </router-link>
        </flux-dropdown-item>
        <flux-dropdown-item>
          <router-link class="p-2 text-gray-700 dark:text-white" to="/admin">
            {{ $t("general.settings") }}
          </router-link>
        </flux-dropdown-item>
        <flux-dropdown-item @click="toggleDarkMode">
          <span class="p-2 text-gray-700 dark:text-white">
            {{
              isDark
                ? $t("general.lightmode_short")
                : $t("general.darkmode_short")
            }}
          </span>
        </flux-dropdown-item>
        <flux-dropdown-item @click="logout">
          <span class="p-2 text-gray-700 dark:text-white">
            {{ $t("general.logout") }}
          </span>
        </flux-dropdown-item>
      </flux-dropdown>
    </div>
    <div class="hidden items-center justify-end space-x-4 md:flex">
      <HeaderProposedAppointments></HeaderProposedAppointments>
      <HeaderUpdateNotificationComponent></HeaderUpdateNotificationComponent>
      <HeaderProfileMenuComponent></HeaderProfileMenuComponent>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onBeforeMount, computed, Ref, inject } from "vue";
import { useRouter } from "vue-router";
import { authModule } from "@/auth";
import { Patient } from "@/models/Patient";
import PatientTypeAhead from "./ui/Input/PatientTypeAhead.vue";
import HeaderProfileMenuComponent from "./HeaderProfileMenu.vue";
import HeaderUpdateNotificationComponent from "./HeaderUpdateNotification.vue";
import HeaderProposedAppointments from "./HeaderProposedAppointments.vue";
import { getUser } from "@/libraries/plugins/getUser";
import store from "@/libraries/store";
import { $t } from "@/libraries/i18n";
import { PatientRepositoryUsingApi } from "@/libraries/repositories/patientRepositoryUsingApi";
import { RouterLink } from "vue-router";
import PatientSearchBar from "@/components/PatientSearchBar.vue";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { useShowQaWarning } from "@/composables/showQaWarning";

const router = useRouter();
const searchInput = ref("");
const activeLink = ref("");
const { showQaWarning } = useShowQaWarning();
const isDark = inject<Ref<boolean>>("isDark");
const toggleDarkMode = inject<() => void>("toggleDarkMode");

const canViewPatient = computed(() => hasPermission("view-patient"));

function healthcareProviderName(): string {
  return getUser().healthcare_provider.name;
}

onBeforeMount(() => {
  activeLink.value = router.currentRoute.value.path;
});

/**
 * Logs user out and sends user to login page.
 */
function logout() {
  authModule(store).logout();
  router.push("/login");
}

function search() {
  const query = { query: searchInput.value };
  router
    .push({
      name: "PatientSearchResults",
      query: query,
    })
    .catch(() => {
      // NavigationDuplicated
    });
}

async function typeahead() {
  return await new PatientRepositoryUsingApi().search(searchInput.value);
}

function selectPatient(patient: Patient) {
  searchInput.value = "";
  router.push(getPath(patient)).catch(() => {
    // NavigationDuplicated
  });
}

function getPath(patient: Patient): string {
  return "/patient/" + patient.zis_number;
}
</script>
