import { ref } from "vue";

export type ConfirmType = "danger" | "delete" | "default";

interface ConfirmOptions {
  title?: string;
  message: string;
  type?: ConfirmType;
  confirmButtonText?: string;
  cancelButtonText?: string;
  canCancel?: boolean;
}

interface ConfirmProps {
  customHeader?: string;
  customBody?: string;
  type?: ConfirmType;
  confirmButtonText?: string;
  cancelButtonText?: string;
  canCancel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}

const visible = ref<boolean>(false);
const props = ref<ConfirmProps>({
  customBody: "",
  type: "default",
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel",
  canCancel: true,
});
export function useConfirm() {
  const confirm = ({
    title,
    message,
    type,
    confirmButtonText,
    cancelButtonText,
    canCancel,
  }: ConfirmOptions) => {
    props.value.customHeader = title;
    props.value.customBody = message;
    props.value.canCancel = canCancel ?? true;
    props.value.type = type;
    props.value.confirmButtonText = confirmButtonText;
    props.value.cancelButtonText = cancelButtonText;
    visible.value = true;
    return new Promise<boolean>((resolve) => {
      const onConfirm = () => {
        resolve(true);
        visible.value = false;
      };
      const onCancel = () => {
        resolve(false);
        visible.value = false;
      };
      props.value.onConfirm = onConfirm;
      props.value.onCancel = onCancel;
    });
  };

  return { confirm, visible, props };
}
