import axios from "restyped-axios";
import { Store } from "vuex";
import { authModule } from "../../auth/index";
import { FluxApi } from "@/apis/flux";
import { $t } from "../i18n";
import { Router } from "vue-router";
import { useNotify } from "@/composables/notify";
import * as Sentry from "@sentry/vue";
import { z } from "zod";

const { notify } = useNotify();

const apiClientFunction = () => {
  let baseURL = "/api/v1";
  if (
    import.meta.env.VITE_API_BASE_URL !== undefined &&
    import.meta.env.VITE_API_BASE_URL !== ""
  ) {
    const pattern = import.meta.env.PROD ? /^https:\/\// : /^http[s]?:\/\//;
    baseURL = import.meta.env.VITE_API_BASE_URL + "/api/v1";
    if (!pattern.test(baseURL)) {
      throw new Error(
        `!Invalid baseURL: ${baseURL} and env.PROD: ${import.meta.env.PROD}`,
      );
    }
  }
  return axios.create<FluxApi>({
    baseURL,
  });
};

export const apiClient = apiClientFunction();

type Primitive = string | number | boolean | undefined;

type ReplaceNullWithUndefined<T> = T extends null
  ? undefined
  : T extends Primitive
    ? T
    : T extends (infer U)[]
      ? ReplaceNullWithUndefined<U>[]
      : T extends object
        ? { [K in keyof T]: ReplaceNullWithUndefined<T[K]> }
        : T;

export function replaceNull<T>(input: T): ReplaceNullWithUndefined<T> {
  if (input === null) {
    return undefined as ReplaceNullWithUndefined<T>;
  }

  if (Array.isArray(input)) {
    return input.map(replaceNull) as ReplaceNullWithUndefined<T>;
  }

  if (typeof input === "object" && input !== null) {
    return Object.fromEntries(
      Object.entries(input).map(([key, value]) => [key, replaceNull(value)]),
    ) as ReplaceNullWithUndefined<T>;
  }

  return input as ReplaceNullWithUndefined<T>;
}

// Configure axios
export function configureApiClient(store: Store<any>, router: Router) {
  // Add an interceptor to catch unauthorised requests.
  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      handleSentry(error);

      const isLoginRequest =
        error.config &&
        error.config.url &&
        (error.config.url as string).includes("auth/login");
      const is2FaConfirmRequest =
        error.config &&
        error.config.url &&
        (error.config.url as string).includes("2fa_devices");
      if (error.response && !isLoginRequest && !is2FaConfirmRequest) {
        if (error.response.status === 401) {
          authModule(store).logout();
          if (router.currentRoute.value.path !== "/login") {
            router.replace("/login");
          }
        } else if (error.response.status === 403) {
          if (error.response?.data?.error === "No active subscription") {
            // This is gebeund
            notify({
              message: "No active subscription",
            });
            router.push("/");
            return;
          } else {
            notify({
              message: $t("error.403"),
              type: "error",
            });
            throw error;
          }
        }
      }
      throw error;
    },
  );

  // Replace `null` with `undefined`
  apiClient.interceptors.response.use((response) => {
    // Fix for incorrect typing: response can be undefined.
    if (!response) {
      return response as any;
    }

    if (response.data instanceof Blob) {
      return response;
    }

    if (
      response.headers &&
      response.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return response;
    }

    return {
      ...response,
      data: replaceNull(response.data),
    };
  });

  // Replace `null` with `undefined`
  apiClient.interceptors.response.use((response) => {
    // Fix for incorrect typing: response can be undefined.
    if (!response) {
      return response as any;
    }
    if (response.data instanceof Blob) {
      return response;
    }
    if (
      response.headers &&
      response.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      return response;
    }
    return {
      ...response,
      data: JSON.parse(JSON.stringify(response.data), (_, value) =>
        value === null ? undefined : value,
      ),
    };
  });

  // Replace params in URL with params in request instance.
  apiClient.interceptors.request.use((request) => {
    const params = request.params as { [key: string]: any } | undefined;
    const url = request.url;
    if (!url || !params) {
      return request;
    }
    // Replace params in URL
    const replaceParams = Object.keys(params).filter((param) =>
      url.includes(`:${param}`),
    );

    request.url = Object.entries(params)
      .filter(([key]) => replaceParams.includes(key))
      .reduce((acc, [key, value]) => acc.replace(`:${key}`, value), url);

    // Remove params that have been replaced in URL. Now /users/:id will
    // become /users/1 instead of /users/:id?id=1
    request.params = Object.fromEntries(
      Object.entries(params).filter(([key]) => !replaceParams.includes(key)),
    );

    return request;
  });

  // Replace param arrays with json encoded strings.
  apiClient.interceptors.request.use((request) => {
    const params = request.params as { [key: string]: any } | undefined;
    if (!request.url || !params) {
      return request;
    }
    request.params = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        key,
        Array.isArray(value) ? JSON.stringify(value) : value,
      ]),
    );
    return request;
  });

  return apiClient;
}

const errorScheme = z.object({
  config: z
    .object({
      url: z.string(),
      method: z.string(),
    })
    .optional(),
  request: z.unknown(),
  response: z
    .object({
      data: z.unknown(),
      status: z.number(),
    })
    .optional(),
  message: z.string().optional(),
});
function handleSentry(errorReceived: unknown) {
  const error = errorScheme.safeParse(errorReceived);
  if (error.success === false || error.data === undefined) {
    Sentry.captureEvent(error.error);
    return;
  }

  if (error.data.message === "cancel") {
    return;
  }

  const response = error.data.response;

  if (response?.status === 422) {
    Sentry.addBreadcrumb({
      category: "debug",
      message: "Received 422",
      level: "info",
      data: {
        data: "data" in response ? response.data : undefined,
      },
    });
  }

  Sentry.withScope((scope) => {
    if (error.data.config && error.data.response?.status) {
      const url = error.data.config.url;
      const fingerprint = [
        error.data.response.status.toString(),
        simplifyUrl(url),
      ];
      scope.setFingerprint(fingerprint);
    }
    Sentry.captureException(errorReceived);
  });
}

/**
 * This function removes data that is not relevant to the error, such as the complete query string.
 * We also strip numeric and uuids values as they are probably ids and not relevant to the error.
 */
function simplifyUrl(url: string): string {
  const [path] = url.split("?");

  return (
    path.substring(0, "/api/v1".length) +
    path
      .substring("/api/v1".length)
      .replace(/[a-f0-9]{8}-([a-f0-9]{4}-){3}[a-f0-9]{12}/g, "{uuid}")
      .replace(/\d+/g, "{number}")
  );
}
