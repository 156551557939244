<template>
  <div
    class="col-span-full my-4 whitespace-pre-line first:mt-0 last:mb-0"
    v-if="display == 'paragraph'"
  >
    <flux-callout class="col-span-full p-4" type="info">
      <div class="text-sm leading-snug">{{ item.text }}</div>
    </flux-callout>
  </div>
  <div class="col-span-full my-4 text-lg first:mt-0 last:mb-0" v-else>
    <span class="col-span-full">{{ item.text }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import {
  DisplayItem,
  EXTENSION,
  getExtension,
} from "@/libraries/questionnaires/item";

const props = defineProps<{
  item: DisplayItem;
}>();

const display = computed(
  (): "heading" | "paragraph" =>
    (getExtension(props.item, {
      url: EXTENSION.DISPLAY,
    })?.valueCodeableConcept!.coding[0].code as any) ?? "heading",
);
</script>
