<template>
  <div v-if="showBadge">
    <flux-dropdown direction="left" :stopPropagation="true">
      <template #button="slotProps">
        <flux-badge
          color="bg-rose-500"
          :content="contentBadge"
          :hidden="!showBadge"
        >
          <div
            class="group mt-1 flex h-8 w-8 cursor-pointer items-center justify-center rounded text-center transition duration-75 hover:bg-slate-100 hover:dark:bg-neutral-800"
          >
            <svg
              class="box-border h-6 w-6 rounded-full text-slate-800 group-hover:text-slate-900 dark:text-neutral-100 group-hover:dark:text-white"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="bell"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              @click="slotProps.handleClick"
            >
              <g class="fa-group">
                <path
                  class="fa-secondary"
                  fill="currentColor"
                  d="M448 384c-.1 16.4-13 32-32.1 32H32.08C13 416 .09 400.4 0 384a31.25 31.25 0 0 1 8.61-21.71c19.32-20.76 55.47-52 55.47-154.29 0-77.7 54.48-139.9 127.94-155.16V32a32 32 0 1 1 64 0v20.84C329.42 68.1 383.9 130.3 383.9 208c0 102.3 36.15 133.53 55.47 154.29A31.27 31.27 0 0 1 448 384z"
                  opacity="0.4"
                ></path>
                <path
                  class="fa-primary"
                  fill="currentColor"
                  d="M160 448h128a64 64 0 0 1-128 0z"
                ></path>
              </g>
            </svg>
          </div>
        </flux-badge>
      </template>
      <template #default>
        <div class="-mt-1 mb-[calc(-0.25rem-1px)]">
          <ProposedAppointmentsDropdown />
        </div>
      </template>
    </flux-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import ProposedAppointmentsDropdown from "@/components/calendar/ProposedAppointmentsDropdown.vue";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { getUser } from "@/libraries/plugins/getUser";
import store, { pinia } from "@/libraries/store";
import { useAppointmentTypeStore } from "@/libraries/store/AppointmentTypes";

const interval = ref<number>();
const appointmentTypeStore = useAppointmentTypeStore(pinia);

onMounted(() => {
  if (!enabled.value) {
    return;
  }
  appointmentTypeStore.findAll();
  store.dispatch("calendar/getAppointmentProposals");
  interval.value = setInterval(() => {
    store.dispatch("calendar/getAppointmentProposals");
  }, 60000);
});

onBeforeUnmount(() => {
  if (interval.value) {
    clearInterval(interval.value);
  }
});

const enabled = computed(() => {
  return (
    hasFeatureFlag("online-scheduling") &&
    hasFeatureFlag("appointment-types") &&
    getUser().healthcare_provider.online_scheduling_settings.enabled
  );
});

const appointments = computed(() => store.state.calendar.appointmentProposals);

const contentBadge = computed(() => `+${appointments.value.length}`);

const showBadge = computed(
  () => enabled.value && appointments.value.length > 0,
);
</script>
