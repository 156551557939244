import { InvoiceListEntry } from "@/libraries/repositories/invoiceRepository";
import { Ref, computed } from "vue";
import { apiClient } from "@/libraries/utils/axios";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { hasPermission } from "@/libraries/utils/hasPermission";

const url =
  "/invoices?page=1&status=[%22draft%22,%22open%22,%22paid%22,%22reminded%22,%22expired%22,%22void%22,%22declared%22,%22rejected%22,%22undeclared%22,%22partially_granted%22,%22granted%22,%22collection_draft%22]&number=";

async function getInvoices(query: string): Promise<InvoiceListEntry[]> {
  const result = await apiClient.get(url + query);

  return result.data.data;
}

export function useInvoiceSearch(queryString?: Ref<string | undefined>) {
  const enabled = computed(
    () =>
      /^[0-9A-Za-z]{3,10}-?[0-9]{0,4}$/.test(queryString?.value ?? "") &&
      hasPermission("view-invoice"),
  );

  const result = useQuery({
    queryFn: async ({ queryKey }): Promise<InvoiceListEntry[]> =>
      await getInvoices(queryKey[2] ?? ""),
    queryKey: ["invoice", "search", queryString] as const,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60,
    enabled: enabled,
    placeholderData: keepPreviousData,
  });

  return { ...result, enabled };
}
