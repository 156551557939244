import { AppointmentStatus } from "@/models/Appointment";
import {
  AppointmentDataApiResponse,
  AppointmentRepositoryBody,
} from "./appointmentRepositoryUsingApi";
import { InjectionKey } from "vue";
import { Router } from "vue-router";

export interface AppointmentRequest {
  start: string;
  end: string;
  user_ids?: number[];
  location_ids?: number[];
  patient_zis_numbers?: number[];
  company_division_ids?: string[];
  status?: AppointmentStatus;
}

export type TrashedAppointment = Awaited<
  ReturnType<AppointmentRepository["findTrashed"]>
>["appointments"][number];

export type AppointmentPatient = Awaited<
  ReturnType<AppointmentRepository["findTrashed"]>
>["patients"][number];

export type AppointmentUser = Awaited<
  ReturnType<AppointmentRepository["findTrashed"]>
>["users"][number];

export type AppointmentLocation = Awaited<
  ReturnType<AppointmentRepository["findTrashed"]>
>["locations"][number];

export const AppointmentRepositoryKey = Symbol() as InjectionKey<
  () => AppointmentRepository
>;

export interface AppointmentRepository {
  findAll(req: AppointmentRequest): Promise<AppointmentDataApiResponse>;

  findTrashed(req: AppointmentRequest): Promise<AppointmentDataApiResponse>;

  updateStatus(
    appointment: AppointmentRepositoryBody,
    status: AppointmentStatus,
    router: Router,
  ): Promise<void>;
}
