<!-- src/components/Login.vue -->
<template>
  <div
    class="landing-page-bg flex min-h-screen w-full items-center justify-around bg-white dark:bg-neutral-900"
  >
    <Notifications />
    <div class="box-border flex w-full max-w-md flex-col">
      <div
        class="rounded-lg border border-gray-300 px-8 py-12 pb-20 dark:border-neutral-600"
        v-flux-loading="loading"
      >
        <div>
          <h2 class="text-center text-3xl font-normal">
            <img class="h-20 w-20" src="@/assets/images/logo.svg" />
          </h2>
        </div>
        <flux-alert v-if="showQaWarning" type="error" :closable="false">
          Dit is een testomgeving. Ga naar
          <a href="https://flux.healthcare">flux.healthcare</a> om toegang te
          krijgen tot patiëntgegevens.
        </flux-alert>

        <div v-if="challengeId === undefined">
          <div>
            <h2 class="mb-8 mt-4 text-center font-normal">
              {{ $t("login.title") }}
            </h2>
          </div>
          <flux-form
            class="login-form mt-4"
            :submit-on-enter="true"
            ref="login-form"
          >
            <ValidationErrors
              class="row-span-full my-4"
              v-if="errors"
              :errors="errors"
            />
            <flux-short-form>
              <flux-short-form-item :label="$t('login.username')">
                <flux-input
                  v-model:modelValue="credentials.username"
                  :disabled="loading"
                  type="email"
                  prop="username"
                />
              </flux-short-form-item>
              <flux-short-form-item :label="$t('login.password')">
                <flux-input
                  v-model:modelValue="credentials.password"
                  :disabled="loading"
                  type="password"
                  prop="password"
                />
              </flux-short-form-item>
              <flux-button-group
                class="col-span-full items-center justify-between"
              >
                <flux-button
                  :disabled="loading"
                  type="text"
                  size="small"
                  @click="forgotPasswordDialog = true"
                  >{{ $t("login.forgotpassword.link") }}</flux-button
                >
                <flux-submit-button
                  @click="submit()"
                  size="small"
                  :disabled="loading"
                  icon="fal fa-long-arrow-right"
                  iconPosition="right"
                >
                  {{ loading ? $t("login.loading") : "Login" }}
                </flux-submit-button>
              </flux-button-group>
            </flux-short-form>
          </flux-form>
        </div>
        <div v-else>
          <div>
            <h2 class="mb-8 mt-4 text-center font-normal">
              {{ $t("login.2fa.title") }}
            </h2>
          </div>

          <ValidationErrors
            class="row-span-full my-4"
            v-if="errors"
            :errors="errors"
          />

          <flux-form
            class="login-form"
            :submit-on-enter="true"
            @submit.prevent="completeChallenge()"
          >
            <p class="text-sm">
              {{ $t("login.2fa.help") }}
            </p>
            <div class="mt-2 flex flex-grow space-x-2">
              <flux-input
                class="flex-grow"
                v-model:modelValue="totp"
                icon="fal fa-lock"
                :disabled="loading"
                ref="totp_input"
                :maxLength="6"
                :onlyIntegerInput="true"
                :placeholder="$t('login.2fa.placeholder')"
              ></flux-input>
              <flux-button
                size="small"
                type="primary"
                icon="fal fa-long-arrow-right"
                iconPosition="right"
                :disabled="loading"
                native-type="submit"
                @click="completeChallenge()"
                >{{ $t("login.2fa.verify") }}</flux-button
              >
            </div>
          </flux-form>
        </div>
      </div>
      <span
        class="mt-4 flex justify-between text-xs text-gray-400 dark:text-neutral-300"
      >
        <span><a href="https://joinflux.nl">Join Flux</a></span>
        <span>
          Engineered with
          <div class="relative inline-block">
            <BirthdayVue
              type="hearts"
              :immediate="false"
              :triggerOnMouseEnter="true"
              ><span class="relative">love</span></BirthdayVue
            >
          </div>
          in Delft</span
        >
      </span>
    </div>
    <flux-modal
      v-model:visible="forgotPasswordDialog"
      :title="$t('login.forgotpassword.title')"
    >
      <div class="my-2 max-w-prose">{{ $t("login.forgotpassword.text") }}</div>
      <flux-input
        class="my-4"
        v-model:modelValue="credentials.username"
        ref="forgotpasswordinput"
        :placeholder="$t('login.username')"
      ></flux-input>
      <template #footer>
        <flux-button-group>
          <flux-button
            size="small"
            :disabled="loadingForgotPassword"
            @click="forgotPasswordDialog = false"
          >
            {{ $t("general.cancel") }}
          </flux-button>
          <flux-button
            type="primary"
            size="small"
            :disabled="loadingForgotPassword"
            @click="handleForgotPassword()"
          >
            {{ $t("login.forgotpassword.send") }}
          </flux-button>
        </flux-button-group>
      </template>
    </flux-modal>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { authModule, LoginCredentials } from "./../auth";
import { MessageBag } from "@/libraries/utils/MessageBag";
import ValidationErrors from "@/components/ValidationErrors.vue";
import BirthdayVue from "./Birthday.vue";
import { nextTick, watch, ref } from "vue";
import store from "@/libraries/store";
import { useRouter } from "vue-router";
import { $t } from "@/libraries/i18n";
import Notifications from "./ui/Notifications.vue";
import { useNotify } from "@/composables/notify";
import { useShowQaWarning } from "@/composables/showQaWarning";
import { hasPermission } from "@/libraries/utils/hasPermission";

const { notify } = useNotify();
const router = useRouter();
const { showQaWarning } = useShowQaWarning();

const forgotpasswordinput = ref<HTMLInputElement>();
const totp_input = ref<HTMLInputElement>();

const forgotPasswordDialog = ref(false);
const loadingForgotPassword = ref(false);

const credentials = ref<LoginCredentials>({
  username: "",
  password: "",
});

const loading = ref(false);

const errors = ref<MessageBag>();

const totp = ref("");

const challengeId = ref<string>();

watch(forgotPasswordDialog, async (newValue: boolean) => {
  await nextTick();
  if (newValue == true) {
    forgotpasswordinput.value?.focus();
  }
});

watch(totp, async () => {
  if (totp.value.length == 6) {
    completeChallenge();
  }
});

async function submit() {
  loading.value = true;
  const auth = authModule(store);
  const cred = {
    username: credentials.value.username,
    password: credentials.value.password,
  };

  const loginResult = await auth.login(cred);

  switch (loginResult.type) {
    case "success":
      showPatientInfoWarningNotification();
      goToMainPage();
      break;

    case "errors":
      errors.value = loginResult.errors;
      break;

    case "challenge":
      errors.value = undefined;
      challenge(loginResult.challenge_id);
      break;
  }
  loading.value = false;
}

function challenge(newChallengeId: string) {
  challengeId.value = newChallengeId;
  nextTick(() => totp_input.value?.focus());
}

async function completeChallenge() {
  loading.value = true;
  if (!challengeId.value) {
    throw new Error("cannot comlete challenge without challengeId");
  }
  const auth = authModule(store);
  const res = await auth.completeChallenge(
    credentials.value,
    challengeId.value,
    totp.value,
  );
  if (res.type === "success") {
    showPatientInfoWarningNotification();
    goToMainPage();
  } else {
    errors.value = res.errors;
  }
  loading.value = false;
}

function handleForgotPassword() {
  loadingForgotPassword.value = true;
  apiClient
    .post("/password/email", {
      email: credentials.value.username,
    })
    .then((res) => {
      forgotPasswordDialog.value = false;
      loadingForgotPassword.value = false;
      if (res.data.success) {
        notify({
          title: "Success",
          message: $t("login.forgotpassword.success") as string,
          type: "success",
        });
      } else {
        notify({
          type: "error",
          title: "Error",
          message: $t("error.unknown") as string,
        });
      }
    })
    .catch((error) => {
      loadingForgotPassword.value = false;
      notify({
        type: "error",
        title: "Error",
        message: $t("error.unknown") as string,
      });
    });
}

function showPatientInfoWarningNotification() {
  notify({
    title: $t("login.notification.title") + " " + getRandomSuccessEmoji(),
    message: $t("login.notification.message") as string,
    type: "success",
    duration: 8000,
  });
}

function getRandomSuccessEmoji() {
  const emojis = ["🚀", "⭐️", "🌟", "🙌", "💪", "🎉", "🪄", "🏋️"];
  return emojis[Math.floor(Math.random() * emojis.length)];
}

async function goToMainPage() {
  await sync();
  if (!hasPermission("view-patient")) {
    router.push("/calendar");
    return;
  }
  router.push("/");
}

async function sync() {
  const auth = authModule(store);
  const res = await apiClient.get("/sync");
  if (!res.data.authenticated) {
    auth.unsetToken();
  }
  store.commit("sync", res);
}
</script>
