<template>
  <div>
    <div
      class="error mt-16 flex flex-col items-center justify-center tracking-widest"
    >
      <span class="text-4xl">Pagina niet gevonden</span>
      <div class="mt-20 text-sm">
        Terug naar <a href="/">Flux.healthcare</a>
      </div>
    </div>
  </div>
</template>
