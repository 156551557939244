<template>
  <div
    class="inline-flex items-center space-x-2"
    :class="[disabled ? '' : 'cursor-pointer']"
    @click="toggle"
  >
    <button
      class="box-border appearance-none rounded-full p-0 outline-none ring-offset-2 focus:ring dark:ring-offset-neutral-800"
      type="button"
      :disabled="disabled"
      :class="[
        size === 'medium' ? 'h-5 w-9' : 'h-4 w-7',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        modelValue
          ? disabled
            ? 'bg-slate-300'
            : 'bg-blue-600 dark:bg-blue-700'
          : disabled
            ? 'bg-gray-200'
            : 'bg-slate-300 dark:bg-neutral-600',
      ]"
    >
      <div
        class="box-border transform rounded-full bg-white ease-out"
        :class="[
          size == 'medium'
            ? modelValue
              ? 'h-4 w-4 translate-x-[calc(1rem+1px)] duration-300'
              : 'h-4 w-4 translate-x-[calc(0.25rem-1px)] duration-300'
            : modelValue
              ? 'h-3 w-3 translate-x-[calc(0.75rem+1px)] duration-200'
              : 'h-3 w-3 translate-x-[calc(0.25rem-1px)] duration-200',
        ]"
      ></div>
    </button>
    <div class="text-gray-700 dark:text-neutral-300" v-if="activeText">
      {{ activeText }}
    </div>
  </div>
</template>

<script setup lang="ts">
const modelValue = defineModel<boolean>({ required: true });

const props = defineProps<{
  activeText?: string;
  disabled?: boolean;
  size?: "medium" | "mini";
}>();

function toggle() {
  if (props.disabled) return;
  modelValue.value = !modelValue.value;
}
</script>
