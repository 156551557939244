import { apiClient } from "@/libraries/utils/axios";
import { parseISO } from "date-fns";
import { InjectionKey } from "vue";
import { z } from "zod";

export const declarationEntryAnnotationSchema = z.object({
  id: z.string(),
  user_id: z.number(),
  data: z.any(),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.string().transform((s) => parseISO(s)),
  deleted_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
});

const declarationEntryAnnotationListSchema = z.array(
  declarationEntryAnnotationSchema,
);

export type DeclarationEntryAnnotation = ReturnType<
  (typeof declarationEntryAnnotationSchema)["parse"]
>;

export const DeclarationEntryAnnotationRepositoryKey = Symbol() as InjectionKey<
  () => DeclarationEntryAnnotationRepository
>;

export interface DeclarationEntryAnnotationRepository {
  findForDeclarationEntry(
    patientZisNumber: number,
    declarationEntryId: string,
  ): Promise<DeclarationEntryAnnotation[]>;
  save(data: any, declarationEntryId: string, id?: string): Promise<void>;
  delete(id: string): Promise<void>;
  restore(id: string): Promise<void>;
}

export class DeclarationEntryAnnotationRepositoryUsingApi
  implements DeclarationEntryAnnotationRepository
{
  async findForDeclarationEntry(
    patientZisNumber: number,
    declarationEntryId: string,
  ): Promise<DeclarationEntryAnnotation[]> {
    const { data } = await apiClient.get(
      `/patients/${patientZisNumber}/declaration_entries/${declarationEntryId}/annotations`,
    );
    return declarationEntryAnnotationListSchema.parse(data);
  }

  async save(
    data: any,
    declarationEntryId: string,
    id?: string | undefined,
  ): Promise<void> {
    if (id) {
      await apiClient.put(`/declaration_entry_annotations/${id}`, {
        data,
      });
      return;
    }
    await apiClient.post(`/declaration_entry_annotations`, {
      declaration_entry_id: declarationEntryId,
      data,
    });
  }

  async delete(id: string): Promise<void> {
    await apiClient.delete(`/declaration_entry_annotations/${id}`);
  }
  async restore(id: string): Promise<void> {
    await apiClient.post(`/declaration_entry_annotations/${id}/restore`);
  }
}
