import { apiClient } from "../utils/axios";
import { Invoice } from "@/models/Invoice";
import { Token } from "@/models/Model";
import { useNotify } from "@/composables/notify";

const { notify } = useNotify();

export default (vue: any) => {
  // (vue.prototype as Vue)
  vue.config.globalProperties.getInvoiceToken = async function (
    invoice: Invoice,
  ): Promise<Token | undefined> {
    try {
      const {
        data: { token },
      } = await apiClient.post(
        `/invoices/:id/token`,
        {},
        {
          params: {
            id: invoice.id,
          },
        },
      );
      return token;
    } catch {
      notify({
        message: "De download kon niet gestart worden.",
        type: "error",
      });
    }
  };
  // (vue.prototype as Vue)
  vue.config.globalProperties.downloadInvoice = async function (
    invoice: Invoice,
  ): Promise<void> {
    const token = await this.getInvoiceToken(invoice);
    if (!token) {
      notify({
        message: "De download kon niet gestart worden.",
        type: "error",
      });
      return;
    }
    const body = document.getElementsByTagName("body")[0];
    const iframe = document.createElement("iframe");
    iframe.src = await this.getInvoiceURL(invoice, token);
    iframe.style.display = "none";
    body.appendChild(iframe);
    window.setTimeout(() => {
      body.removeChild(iframe);
    }, 60000);
  };

  // (vue.prototype as Vue)
  vue.config.globalProperties.getInvoiceURL = async function (
    invoice: Invoice,
    token: Token | undefined,
    preview: boolean = false,
  ) {
    if (!token) {
      token = await this.getInvoiceToken(invoice);
    }
    if (!token) {
      throw new Error("could not get token");
    }
    const filename = invoice.number || "concept";
    let query = `?token=${token.token}`;
    if (preview) {
      query += `&preview=${preview}`;
    }
    return (
      (import.meta.env.VITE_API_BASE_URL ?? "") +
      `/invoices/${invoice.id}/${filename}.pdf${query}`
    );
  };
};
