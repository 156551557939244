export function useShareData(storageKey: string) {
  const saveData = (data: any) => {
    const expirationTimestamp = Date.now() + 10000;
    const dataToStore = {
      value: data,
      expiration: expirationTimestamp,
    };
    try {
      localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    } catch (error) {
      console.error("Failed to store parameter in sessionStorage:", error);
    }
  };

  const readData = () => {
    try {
      const parameterValue = localStorage.getItem(storageKey);
      if (parameterValue === null) {
        return null;
      }
      const parsedData = JSON.parse(parameterValue);
      const { value, expiration } = parsedData;

      localStorage.removeItem(storageKey);
      if (expiration && expiration < Date.now()) {
        return null;
      }
      return value;
    } catch (error) {
      return null;
    }
  };
  return { readData, saveData };
}
