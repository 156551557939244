<template>
  <div v-for="(error, index) in errors" :key="index">
    <template v-for="messages in error">
      <template v-if="Array.isArray(messages)">
        <div v-for="(message, index) in messages" :key="index">
          {{ message }}
        </div>
      </template>
      <template v-else>
        {{ messages }}
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MessageBag } from "@/libraries/utils/MessageBag";

export default defineComponent({
  props: {
    errors: {
      type: Object as PropType<MessageBag>,
      required: true,
    },
  },
  setup() {},
});
</script>
