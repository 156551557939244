import { parseISO } from "date-fns";
import { z } from "zod";

export const patientTreatmentCountSchema = z.object({
  patient_zis_number: z.number(),
  amount: z.number().nonnegative().max(99),
  reference_year: z.number().min(2000).max(2100),
  source: z.string(),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
});

export const patientTreatmentCountListSchema = z.array(
  patientTreatmentCountSchema,
);

export const createPatientTreatmentCountSchema =
  patientTreatmentCountSchema.pick({
    amount: true,
    reference_year: true,
  });

export type PatientTreatmentCount = z.output<
  typeof patientTreatmentCountSchema
>;
export type PatientTreatmentCountApi = z.input<
  typeof patientTreatmentCountSchema
>;
export type CreatePatientTreatmentCount = z.infer<
  typeof createPatientTreatmentCountSchema
>;
