import { mergeAttributes, Node } from "@tiptap/vue-3";
import { $t } from "../i18n";

interface ButtonPlaceholderOptions {
  field: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    buttonPlaceholder: {
      /**
       * Add an image
       */
      setButtonPlaceholder: (options: { field: string }) => ReturnType;
    };
  }
}

export const ButtonPlaceholder = Node.create<ButtonPlaceholderOptions>({
  name: "buttonPlaceholder",
  renderHTML({ HTMLAttributes }) {
    const text = $t(`templates.placeholder.${HTMLAttributes.field}`);
    return [
      "span",
      mergeAttributes(HTMLAttributes, {
        class: "ProseMirror-ButtonPlaceholder",
      }),
      text,
    ];
  },
  parseHTML() {
    return [
      {
        tag: "span.ProseMirror-TemplatePlaceholder",
      },
    ];
  },
  addAttributes() {
    return {
      field: "default",
    };
  },
  inline: true,

  group: "inline",

  addCommands() {
    return {
      setButtonPlaceholder:
        (options) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "buttonPlaceholder",
            attrs: options,
          });
        },
    };
  },
});
