<template>
  <BasicTootipGrid
    v-if="patient"
    :allowOverflow="true"
    :properties="properties"
  >
    <template #icon>
      <PatientPhoto
        v-if="patient"
        :zisNumber="patient.zis_number"
        size="20px"
      />
    </template>
    <template #name>
      <div class="text-sm font-medium">{{ fullName(patient) }}</div>
    </template>
  </BasicTootipGrid>
</template>

<script lang="ts" setup>
import { usePatient } from "@/composables/patient";
import { fullName } from "@/models/Person";
import { computed } from "vue";
import moment from "moment";
import BasicTootipGrid from "./BasicTootipGrid.vue";
import { $t } from "@/libraries/i18n";
import PatientPhoto from "@/components/Patient/PatientPhoto.vue";

const props = defineProps<{ zisNumber: number; allowOverflow?: boolean }>();
const { suspense } = usePatient(computed(() => props.zisNumber));

const patient = (await suspense()).data;

const age = computed((): string | undefined => {
  if (!patient?.date_of_birth) {
    return undefined;
  }

  const referenceDate = patient.date_of_death ?? new Date();

  const years = moment(referenceDate).diff(patient.date_of_birth, "years");
  const months =
    moment(referenceDate).diff(patient.date_of_birth, "months") % 12;

  if (years > 17) {
    return `${years} ${$t("patient.overview.years_old")}`;
  }

  if (years > 0) {
    return `${years} ${$t("patient.overview.years_old")}, ${months} ${$t("patient.overview.months_old", months)}`;
  }

  return getMonthDaysDiff(patient.date_of_birth);
});

const getMonthDaysDiff = (patientDate: Date) => {
  const date = moment(patientDate);
  const months = moment().diff(date, "months");
  date.add(months, "months");
  const days = moment().diff(date, "days");

  return `${months} ${$t("patient.overview.months_old", months)}, ${days} ${$t("patient.overview.days_old", days)}`;
};

const properties = computed((): string[] => {
  return [
    patient ? $t(`labels.gender.${patient.gender}`) : undefined,
    patient?.deceased === true
      ? "Overleden op " + moment(patient.date_of_death).format("DD-MM-YYYY")
      : age.value,
    ...(patient?.labels?.map((label) => label.name) ?? []),
  ].filter((x): x is string => x !== undefined && x !== "");
});
</script>
