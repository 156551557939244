/**
 * My plugins
 */
import installDownloadInvoice from "./downloadInvoice";
import installRouterHooks from "./routerHooks";
import { install as installGetUser } from "./getUser";
import { install as installHasFeatureFlag } from "./hasFeatureFlag";
import { Moment } from "moment";
import { Store } from "vuex";
import { State, UserFromSync } from "../store/interfaces";
import { Invoice } from "@/models/Invoice";
import { Token } from "@/models/Model";
import { Feature } from "@/models/Feature";
import { AppName } from "@/models/App";
import { Permission } from "@/models/Permission";
import { User } from "@/models/User";
import { Role } from "@/models/Role";

// TODO find a way to remove this.
declare module "@vue/runtime-core" {
  interface Vue {
    moment: Moment;
    $moment: Moment;

    store: Store<State>;

    getUser: () => UserFromSync;
    hasApp: (app: AppName) => boolean;

    userHasPermission: (
      user: User & { roles: Role[] },
      permission: string | Permission,
    ) => boolean;
    hasFeatureFlag: (feature: string | Feature) => boolean;
    getInvoiceToken: (invoice: Invoice) => Promise<Token | undefined>;
    getInvoiceURL: (
      invoice: Invoice,
      token?: Token,
      preview?: boolean,
    ) => Promise<string>;
    downloadInvoice: (invoice: Invoice) => Promise<void>;
    viewInvoice: (invoice: Invoice) => Promise<void>;
  }
}

export default (store: any) => {
  return {
    install(ctx: any) {
      installRouterHooks(ctx);
      installDownloadInvoice(ctx);
      installGetUser(ctx);
      installHasFeatureFlag(ctx);
      ctx.config.globalProperties.store = store;
    },
  };
};
