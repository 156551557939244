import { GenderType, genderTypes } from "./Patient";
import { z } from "zod";

export const PatientTitleSchema = z.object({
  uuid: z.string().uuid(),
  language: z.string(),
  short_form: z.string(),
  long_form: z.string(),
  default_for_gender: z.enum(genderTypes).optional(),
});

export type PatientTitle = z.infer<typeof PatientTitleSchema>;

export interface PatientTitleForm {
  uuid?: string;
  language: string;
  short_form: string;
  long_form: string;
  default_for_gender: GenderType | null;
}
