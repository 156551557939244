import {
  areIntervalsOverlapping,
  format,
  isWithinInterval,
  parseISO,
  isBefore,
} from "date-fns";

export function objectIsActive({
  object,
  referenceDate = format(new Date(), "yyyy-MM-dd"),
  referenceInterval,
}: {
  object: { start_date: string; end_date: string };
  referenceDate?: string;
  referenceInterval?: {
    start_date: string;
    end_date: string;
    type: "overlap" | "objectSurrounded" | "objectSurrounds";
  };
}): boolean {
  const objectInterval = objectToFnsInterval(object);

  if (referenceInterval === undefined) {
    return isWithinInterval(parseISO(referenceDate), objectInterval);
  }

  const refInterval = objectToFnsInterval(referenceInterval);

  switch (referenceInterval.type) {
    case "overlap":
      return areIntervalsOverlapping(objectInterval, refInterval);
    case "objectSurrounded":
      return (
        isWithinInterval(objectInterval.start, refInterval) &&
        isWithinInterval(objectInterval.end, refInterval)
      );
    case "objectSurrounds":
      return (
        isWithinInterval(refInterval.start, objectInterval) &&
        isWithinInterval(refInterval.end, objectInterval)
      );
  }
}

export function objectIsExpired(
  object: { end_date?: string },
  reference_date = format(new Date(), "yyyy-MM-dd"),
) {
  if (object.end_date) {
    return isBefore(parseISO(object.end_date), parseISO(reference_date));
  }
  return false;
}

function objectToFnsInterval(model: { start_date: string; end_date: string }): {
  start: Date;
  end: Date;
} {
  return {
    start: parseISO(model.start_date),
    end: parseISO(model.end_date),
  };
}
