import { PatientSearchResult } from "@/libraries/repositories/patientRepository";
import { PatientRepositoryUsingApi } from "@/libraries/repositories/patientRepositoryUsingApi";
import {
  useQuery,
  useQueryClient,
  keepPreviousData,
} from "@tanstack/vue-query";
import { Ref } from "vue";

export function usePatientSearch(query: Ref<string | undefined>) {
  const client = useQueryClient();

  function invalidate(specificQuery: string) {
    client.invalidateQueries({
      queryKey: ["patients", "search", specificQuery],
    });
  }
  const result = useQuery({
    queryFn: async ({ queryKey }): Promise<PatientSearchResult[]> =>
      await new PatientRepositoryUsingApi().search(queryKey[2] ?? ""),
    queryKey: ["patients", "search", query] as const,
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60,
    enabled: true,
    placeholderData: keepPreviousData,
  });

  return { ...result, invalidate };
}
