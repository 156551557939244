import { debounce } from "debounce";
import { computed, ref, watch } from "vue";

const delay = ref(450);
const openedTooltips = ref(0);

watch(openedTooltips, (newValue) => {
  if (newValue === 0) {
    resetDelay();
  }
  if (newValue > 0) {
    decreaseDelay();
  }
});

const decreaseDelay = () => (delay.value = 0);

const resetDelay = debounce(() => {
  delay.value = 450;
}, 1500);

export function useClickableIconDelays() {
  const increaseOpenedTooltips = () => {
    openedTooltips.value++;
  };

  const decreaseOpenedTooltips = () => {
    openedTooltips.value--;
  };

  return {
    delay: computed(() => delay.value),
    increaseOpenedTooltips,
    decreaseOpenedTooltips,
  };
}
