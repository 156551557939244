<template>
  <div style="display: contents">
    <label
      class="mr-2 self-center"
      v-if="item.text != null"
      :for="'b_' + uuid"
      >{{ item.text }}</label
    >
    <div>
      <input
        class="m-0 box-border h-4 w-4 cursor-pointer self-center rounded border border-gray-300 bg-gray-50 text-indigo-600 focus:ring-indigo-500 dark:border-neutral-500 dark:[color-scheme:dark]"
        v-model="response[item.linkId]"
        :id="'b_' + uuid"
        type="checkbox"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BooleanItem } from "@/libraries/questionnaires/item";

const uuid = crypto.randomUUID();

defineProps<{
  item: BooleanItem;
  response: any;
}>();
</script>
