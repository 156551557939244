import { apiClient } from "@/libraries/utils/axios";
import { taskScheme, Task } from "@/models/Task";
import { z } from "zod";

export async function fetchActiveUserTasks(userId: number): Promise<Task[]> {
  const res = await apiClient.get(`/tasks?assignee=${userId}&status=to_do`);
  const parsed = z.array(taskScheme).parse(res.data.tasks);
  return parsed;
}

export async function fetchTasks(): Promise<Readonly<Task[]>> {
  const res = await apiClient.get("/tasks");
  const parsed = z.array(taskScheme).readonly().parse(res.data.tasks);
  return parsed;
}

export async function saveTask(task: Task): Promise<Task> {
  let res;
  if (task.id) {
    res = await apiClient.put(`/tasks/${task.id}`, task);
  } else {
    res = await apiClient.post("/tasks", task);
  }
  return taskScheme.parse(res.data.task);
}
