import {
  createPatientTitle,
  getPatientTitles,
  mutatePatientTitle,
  PatientTitlesQueryKeys,
} from "@/queries/patientTitleQuery";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";

export function usePatientTitles() {
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: PatientTitlesQueryKeys.patientTitles(),
    queryFn: () => getPatientTitles(),
    enabled: true,
    refetchOnWindowFocus: false,
  });

  const creation = useMutation({
    mutationFn: (payload: any) => createPatientTitle(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PatientTitlesQueryKeys.patientTitles(),
      });
    },
  });

  const mutation = useMutation({
    mutationFn: (payload: { uuid: string; payload: any }) =>
      mutatePatientTitle(payload.uuid, payload.payload),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: PatientTitlesQueryKeys.patientTitles(),
      });
    },
  });

  return {
    ...query,
    createPatientTitle: (payload: any) => creation.mutateAsync(payload, {}),
    updatePatientTitle: (uuid: string, payload: any) =>
      mutation.mutateAsync({ uuid: uuid, payload: payload }),
  };
}
