<template>
  <div class="mr-2 w-10">
    <div
      class="box-border flex h-10 w-10 items-center justify-around rounded-full border border-dashed border-gray-600 bg-gray-50 text-gray-600 dark:text-neutral-300"
      v-if="patientParticipants.length === 0"
    >
      <i class="fal fa-question" />
    </div>
    <PatientPhoto v-else-if="patientZisNumber" :zisNumber="patientZisNumber" />
  </div>
  <div class="flex flex-col">
    <div>
      <template v-if="patientParticipants.length === 0">
        <span
          class="text-gray-800 dark:text-neutral-300"
          v-if="appointment.metadata"
        >
          {{ fullName(appointment.metadata.request) }}
        </span>
        <template v-else> Onbekend </template>
      </template>
      <span v-else>
        <patient-link
          v-if="appointmentPatient"
          :format="FormatName.fullName"
          :patient="appointmentPatient"
        />
        <async-patient-link
          v-else-if="patientZisNumber"
          :format="FormatName.fullName"
          :zisNumber="patientZisNumber"
        />
      </span>
    </div>
    <div class="text-sm text-gray-500 dark:text-white">
      Afspraak met
      <span
        class="text-gray-800 dark:text-neutral-300"
        v-if="!getUserFromAppointment(appointment)"
      >
        Geen gebruiker
      </span>
      <span class="text-gray-800 dark:text-neutral-300" v-else
        >{{ getUserFromAppointment(appointment) }}
      </span>
      op
      <span class="text-gray-800 dark:text-neutral-300">{{
        compactDatetimeHumanFilter(appointment.start)
      }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { compactDatetimeHumanFilter } from "@/filters";
import store from "@/libraries/store";
import {
  Appointment,
  AppointmentProposal,
  getPatientParticipants,
  getUserParticipants,
} from "@/models/Appointment";
import { Patient } from "@/models/Patient";
import { FormatName, fullName, fullNameInitials } from "@/models/Person";
import { computed } from "vue";
import PatientPhoto from "../Patient/PatientPhoto.vue";

const props = defineProps<{
  appointment: AppointmentProposal;
}>();

const appointmentPatient = computed(() => getPatient());

const patientParticipants = computed(() =>
  getPatientParticipants(props.appointment),
);

const patientZisNumber = computed(
  () => patientParticipants.value?.at(0)?.patient_zis_number,
);

function getPatient(): Patient | undefined {
  if (!patientZisNumber.value) {
    return;
  }
  return store.state.calendar.patients?.[patientZisNumber.value];
}

function getUserFromAppointment(appointment: Appointment) {
  const id = getUserParticipants(appointment)[0]?.user_id;

  return fullNameInitials(
    store.state.user?.healthcare_provider.users.find((user) => user.id === id),
  );
}
</script>
