import { apiClient } from "@/libraries/utils/axios";
import {
  VektisHealthcareProfessionalAgb,
  vektisHealthcareProfessionalAgbScheme,
} from "@/models/VektisHealthcareProfessionalAgb";
import { z } from "zod";

const resultScheme = z.object({
  count: z.number(),
  vektis_healthcare_professional_agb: z.array(
    vektisHealthcareProfessionalAgbScheme,
  ),
});

export async function fetchVektisHealthcareProfessionalAgb(
  name: string,
): Promise<VektisHealthcareProfessionalAgb[]> {
  const res = await apiClient.post(
    "/search/vektis_healthcare_professionals/agb",
    {
      name,
    },
  );
  return resultScheme.parse(res.data).vektis_healthcare_professional_agb;
}
