<template>
  <teleport to="body">
    <div
      class="fixed left-0 right-0 top-0 z-50 m-4 mx-auto flex w-full max-w-md flex-col gap-4"
    >
      <template v-for="[uuid, notification] in notifications.entries()">
        <Notification
          v-if="typeof notification.message === 'string'"
          :uuid="uuid"
          :key="uuid"
          :type="notification.type"
          :message="notification.message"
          :title="notification.title"
          :showClose="notification.showClose"
          :duration="notification.duration"
          :action="notification.action"
        />
        <Notification
          v-else
          :uuid="uuid"
          :key="uuid + '_else'"
          :type="notification.type"
          :customComponent="toRaw(notification.message.component)"
          :customComponentProps="notification.message.props"
          :title="notification.title"
          :showClose="notification.showClose"
          :duration="notification.duration"
          :action="notification.action"
        />
      </template>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { toRaw } from "vue";
import { useNotify } from "@/composables/notify";
import Notification from "@/components/ui/Notification.vue";

const { notifications } = useNotify();
</script>
