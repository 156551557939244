<template>
  <div>
    Text:
    <flux-input
      v-model:modelValue="item.text"
      :type="display == 'heading' ? 'text' : 'textarea'"
    ></flux-input>
    Type:
    <select class="border" v-model="display">
      <option value="heading">Heading</option>
      <option value="paragraph">Paragraph</option>
    </select>
  </div>
</template>

<script lang="ts" setup>
import {
  DisplayItem,
  EXTENSION,
  getExtension,
} from "@/libraries/questionnaires/item";
import { computed } from "vue";

const props = defineProps<{
  item: DisplayItem;
}>();

const display = computed({
  get: (): "heading" | "paragraph" =>
    (getExtension(props.item, {
      url: EXTENSION.DISPLAY,
    })?.valueCodeableConcept!.coding[0].code as any) ?? "heading",
  set: (mode: "heading" | "paragraph") => {
    const extension = getExtension(props.item, {
      url: EXTENSION.DISPLAY,
    });

    if (props.item.extension === undefined) {
      props.item.extension = [];
    }

    if (extension === undefined) {
      props.item.extension.push({
        url: EXTENSION.DISPLAY,
        valueCodeableConcept: {
          coding: [
            {
              code: mode,
            },
          ],
        },
      });
    } else {
      extension.valueCodeableConcept!.coding[0].code = mode;
    }
  },
});
</script>
