<template>
  <Group
    v-if="item.type == 'group'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <Display
    v-else-if="item.type == 'display'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <OpenChoice
    v-else-if="item.type == 'open-choice'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <Choice
    v-else-if="item.type == 'choice'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <BooleanVue
    v-else-if="item.type == 'boolean'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <NRS
    v-else-if="item.type == 'integer' && hasItemControl('NRS', item)"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <VAS
    v-else-if="
      (item.type == 'decimal' || item.type == 'integer') &&
      hasItemControl('VAS', item)
    "
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <Number
    v-else-if="item.type === 'decimal' || item.type === 'integer'"
    :item="item"
    :readonly="readonly"
    :groupType="groupType"
    :response="response"
  />
  <div v-else>???</div>
</template>

<script lang="ts" setup>
import Display from "./display.vue";
import NRS from "./input/NRS.vue";
import VAS from "./input/VAS.vue";
import Group from "./group.vue";
import OpenChoice from "./input/OpenChoice.vue";
import Choice from "./input/Choice.vue";
import BooleanVue from "./input/Boolean.vue";
import Number from "./input/Number.vue";
import { EXTENSION, Item, VALUE_SET } from "@/libraries/questionnaires/item";

withDefaults(
  defineProps<{
    item: Item;
    response: { [linkId: string]: any };
    readonly?: boolean;
    groupType?: number | string | string[];
  }>(),
  {
    readonly: false,
    groupType: "",
  },
);

function hasItemControl(itemControl: string, item: Item): boolean {
  if (!Array.isArray(item.extension)) {
    return false;
  }

  const code = item.extension
    .find((ext) => ext.url == EXTENSION.ITEM_CONTROL)
    ?.valueCodeableConcept?.coding.find(
      (coding) => coding.system == VALUE_SET.ITEM_CONTROL,
    )?.code;

  return code === itemControl;
}
</script>
