<template>
  <div
    :class="[
      'flex',
      collapse ? 'flex-col space-y-8 md:flex-row md:space-x-4' : 'space-x-4',
    ]"
  >
    <flux-select
      class="inherit md:hidden"
      v-if="collapse"
      :model-value="selectedItem"
      @update:model-value="selectItem($event)"
    >
      <option
        v-for="item in items"
        :key="item.prop"
        :label="item.label"
        :value="item.prop"
      >
        {{ item.label }}
      </option>
    </flux-select>
    <div
      :class="[
        'flex flex-col space-y-1 lg:pr-10',
        collapse ? 'hidden md:flex' : 'flex',
      ]"
    >
      <template v-for="item in items">
        <div
          :id="`item_${item.prop}`"
          @click="selectItem(item.prop)"
          :class="[
            'select-none rounded py-2 pl-5 pr-12 text-base duration-75',
            'box-border cursor-pointer whitespace-nowrap font-medium',
            itemManager.activeItem === item.prop
              ? 'bg-blue-100 text-blue-800 dark:bg-neutral-800 dark:text-neutral-100 '
              : 'hover:bg-slate-100 dark:text-neutral-300 hover:dark:bg-neutral-700 hover:dark:text-neutral-100',
          ]"
        >
          {{ item.label }}
        </div>
      </template>
    </div>
    <div class="flex-grow">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ItemManager } from "@/libraries/managers/ItemManager";
import { provide, ref, computed } from "vue";

const props = defineProps<{
  modelValue: string;
  collapse?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: string): void;
}>();

const itemManager = ref(new ItemManager(props.modelValue));
provide("itemManager", itemManager);

const items = computed(() => {
  return itemManager.value.items;
});

const selectedItem = computed({
  get() {
    itemManager.value.setActiveItem(props.modelValue);
    return props.modelValue;
  },
  set(value) {
    itemManager.value.setActiveItem(value);
    emit("update:modelValue", value);
  },
});

function selectItem(prop?: string) {
  if (!prop) {
    return;
  }
  itemManager.value.setActiveItem(prop);
  emit("update:modelValue", prop);
  selectedItem.value = prop;
}
</script>
