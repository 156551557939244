import { parseISO } from "date-fns";
import { z } from "zod";

export const countrySchema = z.object({
  id: z.number(),
  name: z.string(),
  code: z.string(),
  active: z.boolean(),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.string().transform((s) => parseISO(s)),
});

export type Country = z.infer<typeof countrySchema>;
