<template>
  <div
    v-if="props.size !== 'small'"
    :class="[
      'mx-0.5 mb-0 flex h-full max-w-[250px] items-center gap-1 overflow-hidden rounded-full px-1 text-xs font-medium',
      colorStyles[color].background,
      colorStyles[color].text,
    ]"
  >
    <div
      :class="[
        'h-2 w-2 flex-shrink-0 self-center rounded-full',
        colorStyles[color].active_background,
      ]"
    ></div>
    <div class="w-full overflow-hidden">
      <p class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
        {{ name }}
      </p>
    </div>
    <div class="cursor-pointer px-1" v-if="canBeRemoved">
      <i class="fal fa-times" @click="emit('remove')" />
    </div>
  </div>
  <div v-else>
    <div
      :title="name"
      :class="[
        'h-2 w-2 self-center rounded-full',
        colorStyles[color].active_background,
      ]"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { colorStyles, ColorStyleColor } from "@/models/ColorStyles";

const props = defineProps<{
  name: string;
  color: ColorStyleColor;
  size?: string;
  canBeRemoved?: boolean;
  unplannable?: boolean;
}>();

const emit = defineEmits<{ (e: "remove"): void }>();
</script>
