<template>
  <div v-if="!submitOnEnter">
    <slot></slot>
  </div>
  <form v-else action="#" @submit.prevent>
    <slot></slot>
  </form>
</template>

<script lang="ts" setup>
import { MessageBag } from "@/libraries/utils/MessageBag";
import FormManager from "@/libraries/managers/FormManager";
import { provide, watch } from "vue";

const props = withDefaults(
  defineProps<{
    errors?: MessageBag;
    submitOnEnter?: boolean;
  }>(),
  {
    errors: () => new MessageBag({}),
    submitOnEnter: false,
  },
);

const formManager = new FormManager({
  submitOnEnter: props.submitOnEnter,
});

provide("formManager", formManager);

watch(
  () => props.errors,
  () => {
    if (!props.errors) {
      return;
    }
    formManager.setErrors(props.errors);
  },
);
</script>
