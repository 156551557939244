import { Router } from "vue-router";
import { onMounted, onUnmounted, ref } from "vue";
import { authModule } from ".";
import store from "@/libraries/store";
import { useConfirm } from "@/composables/confirm";
import { useQueryClient } from "@tanstack/vue-query";

const { confirm } = useConfirm();

function getLastActivityFromLocalStorage(): number | undefined {
  const fromLocalStorage = localStorage.getItem("lastActivity");
  if (fromLocalStorage !== null && !isNaN(parseInt(fromLocalStorage))) {
    return parseInt(fromLocalStorage);
  }

  return undefined;
}

function removeLastActivityFromLocalStorage() {
  localStorage.removeItem("lastActivity");
}

const lastActivity = ref<number>(
  getLastActivityFromLocalStorage() ?? Date.now(),
);

export function updateLastActivity() {
  lastActivity.value = Date.now();
  localStorage.setItem("lastActivity", lastActivity.value.toString());
}

export function useCheckInactive(seconds: number, router: Router) {
  const queryClient = useQueryClient();

  let intervalHandler: number | undefined;

  onMounted(() => {
    document.addEventListener("click", updateLastActivity);
    document.addEventListener("mousemove", updateLastActivity);
    document.addEventListener("touchstart", updateLastActivity);
    document.addEventListener("keydown", updateLastActivity);
    intervalHandler = setInterval(() => checkInactivity(), 1000);
  });

  onUnmounted(() => {
    clearInterval(intervalHandler);
  });

  function isActive(): boolean {
    return (
      Date.now() - (getLastActivityFromLocalStorage() ?? lastActivity.value) <
      seconds * 1000
    );
  }

  function checkInactivity() {
    if (isActive()) {
      return;
    }

    if (authModule(store).checkAuth()) {
      logout();
    }
  }

  /**
   * Logs user out and sends user to login page.
   */
  function logout() {
    removeLastActivityFromLocalStorage();
    queryClient.clear();
    authModule(store).logout();
    confirm({
      title: "U bent uitgelogd",
      message: "U bent uitgelogd wegens inactiviteit.",
      canCancel: false,
      confirmButtonText: "Opnieuw inloggen",
    });
    router.replace("/login");
  }
}
