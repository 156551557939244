<template>
  <div v-flux-loading="isLoading">
    <flux-table
      v-if="data"
      :data="data"
      :noHover="noHover"
      :compact="compact"
      :clickableRows="clickableRows"
      :selectableRows="selectableRows"
      :expandKey="expandKey"
      :rowValue="rowValue"
      @clickRow="emit('clickRow', $event)"
      @sort="(e) => emit('sort', e)"
      :showActions="showActions"
      :xScroll="xScroll"
    >
      <slot></slot>
      <template #selectActions="{ selectedRows, resetSelection }">
        <slot
          name="selectActions"
          :selectedRows="selectedRows"
          :resetSelection="resetSelection"
        ></slot>
      </template>
      <template #emptyState><slot name="emptyState"></slot></template>
    </flux-table>
    <PaginationComponent
      class="mt-8"
      v-if="paginator && paginator.per_page > 0"
      background
      :paginator="paginator"
      :loading="isLoading ?? false"
    />
  </div>
</template>
<script lang="ts" setup generic="TRow extends Record<string, any>, X">
import PaginationComponent from "@/components/ui/Pagination.vue";
import { SortState } from "./tableHelper";
import { Paginator } from "@/models/Paginator";

defineProps<{
  data: TRow[];
  paginator?: Paginator;
  isLoading?: boolean;
  noHover?: boolean;
  clickableRows?: boolean;
  expandKey?: string;
  selectableRows?: boolean;
  rowValue?: (val: TRow) => X;
  showActions?: boolean;
  compact?: boolean;
  xScroll?: boolean;
}>();

const emit = defineEmits<{
  clickRow: [row: any];
  sort: [sortState: SortState<any> | undefined];
}>();
</script>
