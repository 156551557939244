<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script lang="ts" setup>
import { AppointmentRepositoryKey } from "./libraries/repositories/appointmentRepository";
import AppointmentRepositoryUsingApi from "./libraries/repositories/appointmentRepositoryUsingApi";
import { invoiceRepositoryKey } from "./libraries/repositories/invoiceRepository";
import { InvoiceRepositoryUsingApi } from "./libraries/repositories/InvoiceRepositoryUsingApi";
import { useDark } from "@vueuse/core";
import {
  taxRateRepositoryKey,
  TaxRateRepositoryUsingMemory,
} from "./libraries/repositories/TaxRate/TaxRateRepository";
import {
  companyAdministrationRepositoryKey,
  CompanyAdministrationRepositoryUsingApi,
} from "./libraries/repositories/companyAdministrationRepository";
import {
  dropdownManagerKey,
  useDropdownManager,
} from "./libraries/managers/DropdownManager";
import { provide } from "vue";
import {
  patientTreatmentCountRepositoryKey,
  PatientTreatmentCountRepositoryUsingApi,
} from "./libraries/repositories/PatientTreatmentCount/PatientTreatmentCountRepository";
import { apiClient } from "./libraries/utils/axios";
import { useCheckInactive } from "./auth/inactive";
import {
  DeclarationEntryRepositoryKey,
  DeclarationEntryRepositoryUsingApi,
} from "./libraries/repositories/DeclarationEntry/declarationEntryRepository";
import {
  DeclarationEntryAnnotationRepositoryKey,
  DeclarationEntryAnnotationRepositoryUsingApi,
} from "./libraries/repositories/DeclarationEntryAnnotations/DeclarationEntryAnnotationsRepository";
import {
  PatientFinancialHistoryRepositoryKey,
  PatientFinancialHistoryRepositoryUsingApi,
} from "./libraries/repositories/PatientFinancialHistory/PatientFinancialHistoryRepository";
import {
  ReferralRepositoryKey,
  ReferralRepositoryUsingApi,
} from "./libraries/repositories/Referral/ReferralRepository";
import { useRouter } from "vue-router";
import { AxiosInstance } from "axios";

const isDark = useDark({
  storageKey: "user_theme",
  valueDark: "dark",
  valueLight: "light",
  onChanged(dark: boolean) {
    document.documentElement
      .querySelector("body")!
      .classList.toggle("dark", dark);
  },
});

const toggleDarkMode = () => {
  isDark.value = !isDark.value;
};

provide("isDark", isDark);
provide("toggleDarkMode", toggleDarkMode);

const router = useRouter();
useCheckInactive(60 * 60 * 12, router);

provide(
  AppointmentRepositoryKey,
  () => new AppointmentRepositoryUsingApi(apiClient),
);
provide(
  invoiceRepositoryKey,
  () => new InvoiceRepositoryUsingApi(apiClient as AxiosInstance),
);
provide(
  patientTreatmentCountRepositoryKey,
  () => new PatientTreatmentCountRepositoryUsingApi(apiClient as AxiosInstance),
);
provide(taxRateRepositoryKey, () => new TaxRateRepositoryUsingMemory());

provide(
  companyAdministrationRepositoryKey,
  () => new CompanyAdministrationRepositoryUsingApi(apiClient as AxiosInstance),
);

provide(dropdownManagerKey, useDropdownManager());

provide(
  DeclarationEntryRepositoryKey,
  () => new DeclarationEntryRepositoryUsingApi(),
);
provide(
  DeclarationEntryAnnotationRepositoryKey,
  () => new DeclarationEntryAnnotationRepositoryUsingApi(),
);

provide(
  PatientFinancialHistoryRepositoryKey,
  () => new PatientFinancialHistoryRepositoryUsingApi(),
);

provide(ReferralRepositoryKey, () => new ReferralRepositoryUsingApi());
</script>
