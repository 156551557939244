<template>
  <div @click="anyClick">
    <slot :onClick="normalClick"></slot>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps<{
  url: string;
  query?: Record<string, string | string[]>;
}>();

const emit = defineEmits<{
  (e: "linkClick"): void;
}>();

function anyClick(click: MouseEvent) {
  if (click.ctrlKey || click.metaKey || click.shiftKey) {
    openNewTab();
  } else {
    normalClick();
  }
}

const openNewTab = () => {
  const route = router.resolve({
    path: props.url,
    query: props.query,
  });
  emit("linkClick");
  window.open(route.href, "_blank");
};

const normalClick = () => {
  emit("linkClick");
  router.push({
    path: props.url,
    query: props.query,
  });
};
</script>
