import { $t } from "@/libraries/i18n";
import { User } from "./User";
import { AppointmentTherapyType } from "./Appointment";

export const healthcareProfessionalTypes = [
  "physiotherapist",
  "speech_therapist",
  "dietician",
  "dentist",
  "podiatrist",
  "hapto_therapist",
] as const;
export type HealthcareProfessionalType =
  (typeof healthcareProfessionalTypes)[number];

export const userIsPhysiotherapist = (user: User) =>
  user.healthcare_professional_types.some((type) => type === "physiotherapist");
export const userIsDietician = (user: User) =>
  user.healthcare_professional_types.some((type) => type === "dietician");
export const userIsPodiatrist = (user: User) =>
  user.healthcare_professional_types.some((type) => type === "podiatrist");

export function userUsesCsiCode(user: User) {
  return user.healthcare_professional_types.some(
    (type) => type === "physiotherapist",
  );
}

export function displaySelfReferralFullName(type?: HealthcareProfessionalType) {
  switch (type) {
    case "podiatrist":
      return $t("patient.overview.referral.podiatry_self_referral_full");
    case "physiotherapist":
      return $t("patient.overview.referral.physiotherapy_self_referral_full");
    case "speech_therapist":
      return $t("patient.overview.referral.speech_therapy_self_referral_full");
    default:
      return $t("patient.overview.referral.self_referral_full");
  }
}

export function displaySelfReferralAbbreviation(
  type?: HealthcareProfessionalType,
) {
  switch (type) {
    case "podiatrist":
      return $t("patient.overview.referral.podiatry_self_referral");
    case "physiotherapist":
      return $t("patient.overview.referral.physiotherapy_self_referral");
    case "speech_therapist":
      return $t("patient.overview.referral.speech_therapy_self_referral");
    default:
      return $t("patient.overview.referral.self_referral");
  }
}

export function getHealthcareProfessionalTypeFromTherapyType(
  therapyType: AppointmentTherapyType,
): HealthcareProfessionalType {
  switch (therapyType) {
    case "childrens_physiotherapy":
    case "manual_therapy":
    case "physiotherapy":
    case "edema_therapy":
      return "physiotherapist";
    case "podiatry":
      return "podiatrist";
    case "preverbal_speech_therapy":
    case "speech_therapy":
    case "stutter_speech_therapy":
      return "speech_therapist";
    case "hapto_therapy":
      return "hapto_therapist";
  }
}
