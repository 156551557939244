import { z } from "zod";
import { addressSchema } from "./Address";
import { ColorStyleColor, colorStyles } from "./ColorStyles";

const locationStatus = ["ACTIVE", "SUSPENDED", "INACTIVE"] as const;
type LocationStatus = (typeof locationStatus)[number];

export interface LocationBody {
  name: string;
  status: LocationStatus;
  address_id?: number;
  color: ColorStyleColor;
  icon?: LocationIcon;
  calendar_settings?: {
    show_in_availability_overlay: boolean;
  };
  additional_email_info?: object;
}

export interface LocationForm {
  name: string;
  status: LocationStatus;
  address_id?: number;
  color: ColorStyleColor;
  icon?: LocationIcon;
  calendar_settings: {
    show_in_availability_overlay: boolean;
  };
  additional_email_info?: object;
}

export const locationIcons = [
  "fas fa-home-alt",
  "fas fa-clinic-medical",
  "fas fa-building",
  "fas fa-dumbbell",
  "fas fa-swimmer",
  "fas fa-running",
  "fas fa-biking",
  "fas fa-futbol",
  "fas fa-basketball-ball",
  "fas fa-map-marker-alt",
] as const;

export type LocationIcon = (typeof locationIcons)[number];

export const locationSchema = z.object({
  id: z.number(),
  uuid: z.string().optional(),
  name: z.string(),
  status: z.enum(locationStatus),
  address_id: z.number().optional(),
  color: z.string().refine((arg): arg is ColorStyleColor => arg in colorStyles),
  icon: z.enum(locationIcons).optional(),
  calendar_settings: z
    .object({
      show_in_availability_overlay: z.boolean(),
    })
    .optional(),
  address: addressSchema.optional(),
  additional_email_info: z
    .object({
      type: z.literal("doc"),
      content: z.array(
        z.any().refine((data): data is object => typeof data === "object"),
      ),
    })
    .optional(),
});

export type Location = z.infer<typeof locationSchema>;

export const locationColorStyles: Record<
  ColorStyleColor,
  { icon_background: string; line_background: string; text: string }
> = {
  rose: {
    icon_background: "bg-rose-200 dark:bg-rose-800",
    line_background: "bg-rose-200/70 dark:bg-rose-800/70",
    text: colorStyles.rose.text,
  },
  red: {
    icon_background: "bg-red-200 dark:bg-red-800",
    line_background: "bg-red-200/70 dark:bg-red-800/70",
    text: colorStyles.red.text,
  },
  orange: {
    icon_background: "bg-orange-200 dark:bg-orange-800",
    line_background: "bg-orange-200/70 dark:bg-orange-800/70",
    text: colorStyles.orange.text,
  },
  amber: {
    icon_background: "bg-amber-200 dark:bg-amber-800",
    line_background: "bg-amber-200/70 dark:bg-amber-800/70",
    text: colorStyles.amber.text,
  },
  yellow: {
    icon_background: "bg-yellow-200 dark:bg-yellow-800",
    line_background: "bg-yellow-200/70 dark:bg-yellow-200/70",
    text: colorStyles.yellow.text,
  },
  lime: {
    icon_background: "bg-lime-200 dark:bg-lime-800",
    line_background: "bg-lime-200/70 dark:bg-lime-800/70",
    text: colorStyles.lime.text,
  },
  green: {
    icon_background: "bg-green-200 dark:bg-green-800",
    line_background: "bg-green-200/70 dark:bg-green-80/70",
    text: colorStyles.green.text,
  },
  emerald: {
    icon_background: "bg-emerald-200 dark:bg-emerald-800",
    line_background: "bg-emerald-200/70 dark:bg-emerald-800/70",
    text: colorStyles.emerald.text,
  },
  teal: {
    icon_background: "bg-teal-200 dark:bg-teal-800",
    line_background: "bg-teal-200/70 dark:bg-teal-800/70",
    text: colorStyles.teal.text,
  },
  cyan: {
    icon_background: "bg-cyan-200 dark:bg-cyan-800",
    line_background: "bg-cyan-200/70 dark:bg-cyan-800/70",
    text: colorStyles.cyan.text,
  },
  sky: {
    icon_background: "bg-sky-200 dark:bg-sky-800",
    line_background: "bg-sky-200/70 dark:bg-sky-800/70",
    text: colorStyles.sky.text,
  },
  blue: {
    icon_background: "bg-blue-200 dark:bg-blue-800",
    line_background: "bg-blue-200/70 dark:bg-blue-800/70",
    text: colorStyles.blue.text,
  },
  indigo: {
    icon_background: "bg-indigo-200 dark:bg-indigo-800",
    line_background: "bg-indigo-200/70 dark:bg-indigo-800/70",
    text: colorStyles.indigo.text,
  },
  violet: {
    icon_background: "bg-violet-200 dark:bg-violet-800",
    line_background: "bg-violet-200/70 dark:bg-violet-800/70",
    text: colorStyles.violet.text,
  },
  purple: {
    icon_background: "bg-purple-200 dark:bg-purple-800",
    line_background: "bg-purple-200/70 dark:bg-purple-800/70",
    text: colorStyles.purple.text,
  },
  fuchsia: {
    icon_background: "bg-fuchsia-200 dark:bg-fuchsia-800",
    line_background: "bg-fuchsia-200/70 dark:bg-fuchsia-800/70",
    text: colorStyles.fuchsia.text,
  },
  pink: {
    icon_background: "bg-pink-200 dark:bg-pink-800",
    line_background: "bg-pink-200/70 dark:bg-pink-800/70",
    text: colorStyles.pink.text,
  },
  gray: {
    icon_background: "bg-gray-200 dark:bg-gray-800",
    line_background: "bg-gray-200/70 dark:bg-gray-800/70",
    text: colorStyles.gray.text,
  },
} as const;
