import { z } from "zod";

export const emailSentStatus = [
  "opened",
  "sent",
  "bounced",
  "delivered",
] as const;

export type EmailSentStatus = (typeof emailSentStatus)[number];

export const emailSentTypes = [
  "appointment.deleted",
  "appointment.reminder",
  "appointment.updated",
  "appointment.created",
  "appointment.proposal.rejected",
  "appointment.proposal.accepted",
  "invoice.patient.pm304.paid",
  "invoice.patient.pm304.open",
  "invoice.patient.pm304.correction",
  "invoice.patient.pm304.to_collect",
  "invoice.patient.pm304.first_recurring_payment",
  "invoice.patient.product.paid",
  "invoice.patient.product.open",
  "invoice.patient.product.to_collect",
  "invoice.patient.product.first_recurring_payment",
  "invoice.patient.subscription.open",
  "invoice.patient.subscription.to_collect",
  "invoice.patient.subscription.first_recurring_payment",
  "invoice.patient.subscription.paid",
  "invoice.company.product.open",
  "invoice.company.product.paid",
  "patient.recall",
  "questionnaire_response_request",
  "questionnaire_response_request_reminder",
] as const;

export const suppressableEmailSentTypes = [
  "appointment.deleted",
  "appointment.reminder",
  "appointment.updated",
  "appointment.created",
] as const satisfies EmailSentType[];

export type EmailSentType = (typeof emailSentTypes)[number];

export const emailSentScheme = z.object({
  id: z.number(),
  type: z.enum(emailSentTypes),
  email: z.string(),
  patient_zis_number: z.number().optional(),
  user_id: z.number().optional(),
  appointment_id: z.number().optional(),
  invoice_id: z.number().optional(),
  metadata: z.any().optional(),
  status: z.enum(emailSentStatus).optional(),
  sent_at: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  complained_at: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  bounced_at: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  opened_at: z
    .string()
    .transform((val) => new Date(val))
    .optional(),
  created_at: z.string().transform((val) => new Date(val)),
  updated_at: z.string().transform((val) => new Date(val)),
});

export type EmailSent = z.infer<typeof emailSentScheme>;
