import { apiClient } from "@/libraries/utils/axios";
import { z } from "zod";

export async function getCompanies(): Promise<CompanyListEntry[]> {
  const { data } = await apiClient.get("/companies");

  return z.array(companyListEntrySchema).parse(data.companies);
}

const companyListEntrySchema = z.object({
  uuid: z.string().uuid(),
  name: z.string(),
  patients: z.number(),
  company_divisions: z.array(
    z.object({
      uuid: z.string().uuid(),
      name: z.string(),
      company_administration_id: z.optional(z.string().uuid()),
    }),
  ),
  created_at: z.coerce.date(),
  deleted_at: z.coerce.date().optional(),
});

export type CompanyListEntry = z.infer<typeof companyListEntrySchema>;
