import { ref } from "vue";
import { defineStore } from "pinia";
import { apiClient } from "../utils/axios";
import { AppointmentType } from "@/models/Appointment";

export const useAppointmentTypeStore = defineStore("appointmentTypes", () => {
  const cache = ref<Promise<AppointmentType[]>>();

  const getCache = () =>
    (cache.value ??= apiClient
      .get("/appointment_types")
      .then(({ data }) => data.appointment_types));

  async function findAll(): Promise<AppointmentType[]> {
    return (
      (await getCache()).filter(({ deleted_at }) => deleted_at === undefined) ??
      []
    );
  }

  async function findAllWithTrashed(): Promise<AppointmentType[]> {
    return (await getCache()) ?? [];
  }

  async function findById(
    appointmentTypeId?: number,
  ): Promise<AppointmentType | undefined> {
    return (await getCache()).find(({ id }) => id === appointmentTypeId);
  }

  function $reset() {
    cache.value = undefined;
  }

  return {
    findAll,
    findAllWithTrashed,
    findById,
    $reset,
  };
});
