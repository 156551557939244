export const phoneNumberTypes = ["HP", "WP", "TMP", "MC", "PG"] as const;

export type PhoneNumberType = (typeof phoneNumberTypes)[number];

export interface PhoneNumber {
  id?: number;
  phone_number?: string;
  phone_number_type?: PhoneNumberType;
  healthcare_provider_id?: number;
  preferred?: boolean;
  readonly created_at?: Date;
  readonly updated_at?: Date;
}

export interface PhoneNumberPersisted {
  id: number;
  phone_number: string;
  phone_number_type: PhoneNumberType;
  healthcare_provider_id?: number;
  preferred: boolean;
  readonly created_at: Date;
  readonly updated_at: Date;
}
