<template>
  <div class="space-y-4" v-flux-loading="loading">
    <flux-card-button-header
      class="m-2"
      v-if="!patient.deleted_at"
      title="Archiveer de patiënt"
    />
    <flux-card-button-header
      class="m-2"
      v-else
      title="Dearchiveer de patiënt"
    />
    <flux-card shadow="never">
      <div class="space-y-5" v-if="!patient.deleted_at">
        <div>
          Je staat op het punt om een patiënt te archiveren. Houd rekening met
          het volgende voordat je doorgaat:
        </div>
        <div>
          <ul>
            <li>
              <b>Geen Toekomstige Afspraken:</b> Bevestig dat er geen
              toekomstige afspraken in Flux staan.
            </li>
            <li>
              <b>Geen Recalls: </b> Controleer dat er geen recalls
              (herinneringen) zijn ingesteld.
            </li>
            <li>
              <b>Geen Taken: </b> Zorg ervoor dat er geen openstaande taken in
              het systeem staan.
            </li>
          </ul>
        </div>
        <div>
          Door op "Archiveren" te klikken, bevestigt je dat bovenstaande punten
          zijn gecontroleerd en dat je zeker weet dat het archiveren van de
          patiënt de juiste stap is.
        </div>
        <div><b>Let op: </b> Deze actie kan ongedaan worden gemaakt.</div>
      </div>
      <div v-else>
        Je staat op het punt om een patiënt te dearchiveren. Hierdoor wordt de
        patiënt weer volledig toegankelijk.
      </div>
      <div class="justify-end">
        <flux-input-box class="items-end" v-if="!patient.deleted_at" label="">
          <flux-button class="w-fit" type="delete" @click="archive">
            Archiveren
          </flux-button>
        </flux-input-box>
        <flux-input-box class="items-end" v-else label="">
          <flux-button class="w-fit" type="delete" @click="un_archive">
            Dearchiveren
          </flux-button>
        </flux-input-box>
      </div>
    </flux-card>
    <flux-card-button-header class="m-2" title="Registreer overleden patiënt" />
    <flux-card shadow="never">
      <div v-if="!patient.date_of_death">
        Je staat op het punt om te registreren dat een patiënt is overleden.
        <ul class="m-0 ml-4 p-2">
          <li>
            Een overleden patiënt zal geen emails ontvangen met betrekking tot
            afspraken.
          </li>
          <li>
            Alle afspraken blijven in het systeem staan, het is wel mogelijk
            deze nog te verwijderen of te annuleren.
          </li>
          <li>Eventueel openstaande facturen worden nog verwerkt.</li>
          <li>
            Naast de naam van de patiënt zal een <DeceasedIcon /> verschijnen.
          </li>
          <li>
            In het geval van foutieve invoer, is het mogelijk de registratie van
            het overlijden van de patiënt ongedaan te maken.
          </li>
        </ul>
      </div>
      <div class="flex items-end justify-between">
        <div class="space-y-5" v-if="!patient.date_of_death">
          <flux-input-box label="Overlijdensdatum" required>
            <flux-input v-model:modelValue="dateOfDeath" type="date" />
          </flux-input-box>

          <flux-input-box
            v-if="patient.inactive_at === undefined"
            label="Inactief"
          >
            <flux-checkbox-label>
              <flux-checkbox class="text-sm" v-model="shouldSetInactive" />
              Inactief maken
            </flux-checkbox-label>
          </flux-input-box>
        </div>
        <div class="self-center text-slate-700" v-else>
          Deze patiënt is overleden op
          <strong class="font-semibold">{{
            dateFilter(patient.date_of_death)
          }}</strong
          >.
        </div>

        <div class="flex justify-end">
          <flux-button
            class="bottom-0 h-min w-fit"
            v-if="!patient.date_of_death"
            type="delete"
            :disabled="loading"
            @click="setDateOfDeath(dateOfDeath)"
          >
            Registreren
          </flux-button>
          <flux-button
            class="h-min w-fit"
            v-else
            type="delete"
            :disabled="loading"
            @click="setDateOfDeath(undefined)"
          >
            Ongedaan maken
          </flux-button>
        </div>
      </div>
    </flux-card>
  </div>
</template>

<script lang="ts" setup>
import { Patient, usePatient } from "@/composables/patient";
import { apiClient } from "@/libraries/utils/axios";
import { computed, ref } from "vue";
import { useNotify } from "@/composables/notify";
import { dateFilter } from "@/filters";
import DeceasedIcon from "../ui/Icons/DeceasedIcon.vue";
import {
  ChangeOfDateOfDeathCommand,
  setPatientDateOfDeath,
} from "@/queries/patient/patchPatient";
import router from "@/libraries/router";

const props = defineProps<{
  patient: Patient;
}>();

const { notify } = useNotify();

const { invalidatePatient } = usePatient(
  computed(() => props.patient.zis_number),
);

// date in Y-m-d format
const dateOfDeath = ref(new Date().toISOString().split("T")[0]);
const shouldSetInactive = ref(true);

const loading = ref(false);

async function setDateOfDeath(dateOfDeath: string | undefined) {
  try {
    loading.value = true;
    const command = new ChangeOfDateOfDeathCommand(
      props.patient.zis_number,
      dateOfDeath,
      props.patient.inactive_at === null && dateOfDeath !== undefined
        ? shouldSetInactive.value
        : false,
    );

    await setPatientDateOfDeath(command);

    await invalidatePatient(props.patient.zis_number);
    notify({
      message: dateOfDeath
        ? "Overlijden is geregistreerd."
        : "Registratie van overlijden is ongedaan gemaakt.",
      type: "success",
    });
  } catch (e) {
    notify({
      message: e.response.data.message,
      type: "error",
    });
  } finally {
    loading.value = false;
  }
}

async function archive() {
  try {
    loading.value = true;

    await apiClient.delete("/patients/" + props.patient.zis_number);

    await invalidatePatient(props.patient.zis_number);
    notify({
      message: "Patient gearchiveerd",
      type: "success",
    });
    await router.push("/patients");
  } catch (e) {
    notify({
      message: e.response.data.message,
      type: "error",
    });
  } finally {
    loading.value = false;
  }
}

async function un_archive() {
  try {
    loading.value = true;

    await apiClient.put("/patients/" + props.patient.zis_number + "/restore");

    await invalidatePatient(props.patient.zis_number);
    notify({
      message: "Patient gedearchiveerd",
      type: "success",
    });
  } finally {
    loading.value = false;
  }
}
</script>
