import { defineStore } from "pinia";
import { ref } from "vue";
import { z } from "zod";
import { apiClient } from "../utils/axios";

const specialismSchema = z.object({
  display_name_NL: z.string(),
  code: z.string(),
});

export type Specialism = z.infer<typeof specialismSchema>;

export const useSpecialismStore = defineStore("specialisms", () => {
  const cache = ref<Promise<Specialism[]>>();

  const getCache = (): Promise<Specialism[]> =>
    (cache.value ??= apiClient.get("/specialisms").then(({ data }) => {
      const specialisms = data.specialisms ?? [];
      specialisms.sort((a: Specialism, b: Specialism) =>
        a.display_name_NL.localeCompare(b.display_name_NL),
      );
      return specialisms;
    }));

  async function findAll(): Promise<Specialism[]> {
    return z
      .array(specialismSchema)
      .parse(await getCache())
      .map((specialism) => ({ ...specialism, id: specialism.code }));
  }

  return { findAll };
});
