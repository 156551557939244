import { apiClient } from "@/libraries/utils/axios";
import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";

export function usePhoto(filename: Ref<string | undefined>) {
  const enabled = computed(() => !!filename.value);

  const query = useQuery({
    queryKey: ["photos", filename] as const,
    queryFn: ({ queryKey }) => getPhoto(queryKey[1]!),
    enabled,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const hasPhoto = computed(() => enabled.value && query.data !== undefined);

  return { ...query, hasPhoto };
}

async function getPhoto(photoFilename: string): Promise<string> {
  return apiClient
    .get("/photos/" + photoFilename)
    .then((res) => "data:image/png;base64," + res.data.base64_encoded_photo);
}
