<template>
  <div class="flex items-center">
    <input
      class="m-0 h-4 w-4 border border-gray-300 bg-white p-0 text-blue-600 outline-none ring-offset-2 focus:ring dark:border-neutral-600 dark:bg-neutral-800 dark:ring-offset-neutral-800"
      v-model="modelValue"
      :checked="checked"
      :disabled="disabled"
      type="radio"
      :value="optionValue"
      :id="'radio-button-small' + uuid"
    />
    <slot name="label">
      <label
        class="ml-3 block font-medium text-gray-700 dark:text-neutral-300"
        v-if="label"
        :for="'radio-button-small' + uuid"
        :class="[textSize, disabled ? 'opacity-40' : '']"
        >{{ label }}</label
      >
    </slot>
  </div>
</template>

<script setup lang="ts" generic="T">
import { computed } from "vue";
import { TextSizes } from "@/libraries/UI/text";

const uuid = crypto.randomUUID();
const modelValue = defineModel<T>({ required: true });

const props = withDefaults(
  defineProps<{
    label?: string;
    optionValue: T;
    disabled?: boolean;
    textSize?: TextSizes;
  }>(),
  {
    textSize: "text-sm",
    disabled: false,
  },
);

const checked = computed(() => {
  return props.optionValue === modelValue.value;
});
</script>

<style scoped>
[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

.dark [type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="radio"]:disabled {
  color: #93c5fd;
}
[type="checkbox"]:checked,
[type="radio"]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
[type="radio"] {
  border-radius: 100%;
}
[type="checkbox"],
[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  /* color: #2563eb;
  background-color: #fff; */
}
</style>
