import { ref } from "vue";

const showQaWarning = ref(false);
export function useShowQaWarning() {
  if (import.meta.env.DEV) {
    showQaWarning.value = true;
  } else {
    showQaWarning.value =
      !window.location.href.includes("flux.healthcare") &&
      !window.location.href.includes("fluxhealthcare.nl");
  }

  function removeWarning() {
    showQaWarning.value = false;
  }
  return { showQaWarning, removeWarning };
}
