<template>
  <div v-flux-loading="loading" ref="container">
    <!-- Toolbar:options -->
    <div class="options" style="float: right">
      <clickable-icon-group>
        <clickable-icon
          v-if="editable && state == 'read'"
          icon="fa-pencil"
          ref="btn_start_edit"
          @click="setState('update')"
        ></clickable-icon>
        <clickable-icon
          v-if="removeable && state == 'read'"
          icon="fa-trash-alt"
          ref="btn_start_remove"
          @click="confirmRemove()"
        ></clickable-icon>
      </clickable-icon-group>
    </div>
    <slot name="actions"></slot>

    <!-- Header -->
    <slot name="header">
      <flux-card-button-header
        class="mb-4"
        :title="
          modelValue && 'id' in modelValue
            ? $t(`labels.address_type.${modelValue.address_type}`)
            : $t('patient.edit.addresses.new_address')
        "
      />
    </slot>

    <!-- Actual form -->
    <AddressFormComponent
      v-model:modelValue="modelValue"
      v-if="state == 'update'"
      :poRequired="true"
    >
      <template #footer>
        <flux-button-group class="col-span-full justify-self-end">
          <flux-button
            ref="btn_cancel"
            size="small"
            type="default"
            @click="cancel"
            >{{ $t("general.cancel") }}</flux-button
          >
          <template v-if="!modelValue || !('id' in modelValue)">
            <flux-submit-button
              ref="btn_create"
              icon="fal fa-plus"
              size="small"
              @click="create"
              >{{ $t("general.create") }}</flux-submit-button
            >
          </template>
          <template v-else>
            <flux-submit-button
              ref="btn_update"
              size="small"
              icon="fal fa-pencil"
              @click="update"
              >{{ $t("general.update") }}</flux-submit-button
            >
          </template>
        </flux-button-group>
      </template>
    </AddressFormComponent>
    <div class="view-payer-insurer" v-else-if="state == 'read' && modelValue">
      {{ modelValue.street_name }} {{ modelValue.house_number }}<br />
      {{ modelValue.postal_code }}&nbsp;&nbsp;{{ modelValue.city }}<br />
      <template v-if="country">{{ country.name }}</template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { apiClient } from "@/libraries/utils/axios";
import { nextTick, onMounted, PropType, ref, watch } from "vue";
import AddressFormComponent from "./AddressForm.vue";
import { CRUDComponentState } from "../../contracts/CRUDComponent";
import { AddressForm, Address } from "../../models/Address";
import { isValidationErrorResponse } from "@/libraries/utils/errorHandling";
import { Country } from "../../models/Country";
import { useConfirm } from "@/composables/confirm";
import { useNotify } from "@/composables/notify";
import { pinia } from "@/libraries/store";
import { useCountryStore } from "@/libraries/store/Countries";
import { $t } from "@/libraries/i18n";
import { Patient } from "@/composables/patient";

const { confirm } = useConfirm();
const { notify } = useNotify();

const modelValue = defineModel<Address | AddressForm>({
  default: {
    address_type: "PHYS",
    country_id: 159,
    postal_code: "",
    house_number: "",
    street_name: "",
    city: "",
  },
});

const props = defineProps({
  patient: {
    required: true,
    type: Object as PropType<Patient>,
  },
  editable: {
    default: true,
    type: Boolean,
  },
  removeable: {
    default: true,
    type: Boolean,
  },
});

const emit = defineEmits<{
  (e: "update:modelValue", v: Address | AddressForm): void;
  (e: "created", v: Address): void;
  (e: "updated", v: Address): void;
  (e: "cancel"): void;
  (e: "removed"): void;
  (e: "errors", v: any): void;
}>();

const container = ref<HTMLElement>();
const country = ref<Country>();

const state = ref<CRUDComponentState>("update");
const loading = ref(false);

const countryStore = useCountryStore(pinia);

onMounted(async () => {
  setState(getDefaultState());
  loading.value = true;
  country.value = modelValue.value?.country_id
    ? await countryStore.findById(modelValue.value.country_id)
    : undefined;
  loading.value = false;
});

watch(
  () => modelValue.value,
  async (newVal) => {
    country.value = newVal?.country_id
      ? await countryStore.findById(newVal?.country_id)
      : undefined;
  },
  { deep: true },
);

async function create(): Promise<boolean> {
  loading.value = true;
  try {
    const res = await apiClient.post("/addresses", {
      ...(modelValue.value as any),
      patient_zis_number: props.patient.zis_number,
    });
    if (!res.data.success) {
      return false;
    }
    emit("update:modelValue", res.data.address);
    setState("read");
    emit("created", res.data.address);
    notify({
      message: $t("address.created") as string,
      type: "success",
    });
    loading.value = false;
    return true;
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      emit("errors", e.response.data.errors);
    }
    loading.value = false;
    throw e;
  }
}

async function update(): Promise<boolean> {
  if (!modelValue.value) {
    throw new Error("tried to update address, but address is undefined");
  }

  if (!("id" in modelValue.value) || !modelValue.value.id) {
    throw new Error("tried to update address, but address is unpersisted");
  }

  loading.value = true;
  let res;
  try {
    res = await apiClient.put("/addresses/:id", modelValue.value as any, {
      params: {
        id: modelValue.value.id,
      },
    });
    if (!res.data.success) {
      return false;
    }
    emit("update:modelValue", res.data.address);
    setState("read");
    emit("updated", res.data.address);
    notify({
      message: $t("address.updated") as string,
      type: "success",
    });
    loading.value = false;
    return true;
  } catch (e) {
    if (isValidationErrorResponse(e)) {
      emit("errors", e.response.data.errors);
    }
    loading.value = false;
    return false;
  }
}

async function confirmRemove() {
  const shouldRemove = await confirm({
    title: $t("address.confirm.title") as string,
    message: $t("address.confirm.text") as string,
    type: "delete",
  });

  if (!shouldRemove) {
    return false;
  }

  return remove();
}

async function remove() {
  if (!props.removeable) {
    throw new Error("tried to remove address, but removeable is false");
  }

  if (!modelValue.value) {
    throw new Error("tried to remove address, but address is undefined");
  }

  if (!("id" in modelValue.value) || !modelValue.value.id) {
    throw new Error("tried to remove address, but address is unpersisted");
  }

  loading.value = true;
  try {
    const res = await apiClient.delete("/addresses/:id", {
      params: {
        id: modelValue.value.id,
      },
    });
    loading.value = false;
    emit("removed");
    notify({
      message: $t("address.removed") as string,
      type: "success",
    });
    return true;
  } catch (e) {
    loading.value = false;
    return false;
  }
}

async function cancel(): Promise<void> {
  emit("cancel");
  if (
    !modelValue.value ||
    !("id" in modelValue.value) ||
    !modelValue.value.id
  ) {
    emit("removed");
    return;
  }

  // It is persisted, so return to view
  setState("read");
}

function setState(newState: CRUDComponentState): void {
  switch (newState) {
    case "update":
      state.value = "update";
      scrollIntoView();
      break;

    case "read":
      state.value = "read";
      break;
  }
}

function scrollIntoView() {
  nextTick(() => {
    if (container.value) {
      container.value.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
}

function getDefaultState(): CRUDComponentState {
  if (!modelValue.value || !("id" in modelValue.value) || !modelValue.value.id)
    return "update";

  return "read";
}

defineExpose({
  create,
  setState,
  state,
});
</script>
