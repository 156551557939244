import { parseISO } from "date-fns";
import { z } from "zod";

export const careGoalProgress = [
  "in_progress",
  "achieved",
  "partially_achieved",
  "not_achieved",
  "unknown",
  "undocumented",
] as const;

export type CareGoalProgress = (typeof careGoalProgress)[number];

export const careGoalSchema = z.object({
  id: z.string(),
  care_plan_id: z.string(),
  progress: z.enum(careGoalProgress),
  description: z.string(),
  start_date: z.string(),
  due_date: z.string(),
  sub_goals: z
    .array(
      z.object({
        description: z.string(),
        status: z.string().optional(),
        explanation: z
          .object({
            type: z.literal("doc"),
            content: z.array(
              z
                .any()
                .refine((data): data is object => typeof data === "object"),
            ),
          })
          .optional(),
      }),
    )
    .optional(),
  created_at: z.string().transform((s) => parseISO(s)),
  updated_at: z.optional(
    z.string().transform((s) => (s ? parseISO(s) : undefined)),
  ),
});

export const careGoalFormSchema = careGoalSchema.omit({
  id: true,
  care_plan_id: true,
  created_at: true,
  updated_at: true,
});

export type CareGoal = z.output<typeof careGoalSchema>;
export type CareGoalApi = z.input<typeof careGoalSchema>;
export type CareGoalForm = z.infer<typeof careGoalFormSchema>;
