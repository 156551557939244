<template>
  <div class="relative dark:[color-scheme:dark]">
    <flux-textarea
      v-if="multiline"
      v-model:modelValue="modelValue"
      :icon="icon"
      :min-rows="1"
      @update:model-value="showAutocomplete = true"
      @focus="
        emit('focus', $event);
        data = autocomplete();
        showAutocomplete = true;
      "
      @blur="
        emit('blur', $event);
        showAutocomplete = false;
      "
      @keydown="keydown"
      :placeholder="placeholder || $t('general.select')"
    >
    </flux-textarea>
    <flux-input
      v-else
      v-model:modelValue="modelValue"
      :icon="icon"
      @update:model-value="showAutocomplete = true"
      @focus="
        emit('focus', $event);
        data = autocomplete();
        showAutocomplete = true;
      "
      @blur="onBlur($event)"
      @keydown="keydown"
      :placeholder="placeholder || $t('general.select')"
    >
    </flux-input>
    <transition
      enter-active-class="transition duration-75 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="transform opacity-100"
      leave-active-class="transition duration-75 ease-out"
      leave-from-class="transform opacity-100"
      leave-to-class="transform opacity-0"
    >
      <div
        class="absolute z-30 mt-2 box-border max-h-80 w-full origin-top overflow-y-auto rounded border border-gray-300 bg-white shadow-lg dark:border-neutral-500 dark:bg-neutral-800"
        v-show="data.length > 0 && showAutocomplete"
      >
        <ul class="relative m-0 list-none px-0 py-1">
          <li
            v-for="(item, index) in data"
            :key="index"
            @mousedown="onSelect(item)"
            ref="itemRefs"
          >
            <a
              :class="[
                'group box-border flex w-full cursor-pointer items-center justify-between  px-4 py-2 text-sm transition duration-75',
                activeIndex === index
                  ? 'bg-slate-100 text-slate-800 dark:text-white '
                  : 'text-slate-800 hover:bg-slate-100 active:text-slate-800 dark:text-neutral-300 hover:dark:bg-neutral-600 hover:dark:text-white',
              ]"
            >
              <slot name="item" :item="item">{{ toLabel(item) }}</slot>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts" generic="T">
import { ref, Ref, watch } from "vue";

const activeIndex = ref<number | undefined>();

const modelValue = defineModel<string | undefined>("modelValue", {
  required: true,
});

const props = withDefaults(
  defineProps<{
    placeholder?: string;
    icon?: string;
    multiline?: boolean;
    toLabel: (item: T) => string;
    autocomplete: () => T[];
  }>(),
  {
    placeholder: "",
    icon: "",
    multiline: false,
  },
);

const emit = defineEmits<{
  add: [T];
  input: [string];
  focus: [FocusEvent];
  blur: [string];
  "update:modelValue": [string];
}>();
const data = ref<T[]>([]) as Ref<T[]>;

watch(modelValue, () => {
  data.value = props.autocomplete();
});

const showAutocomplete = ref(false);

function onSelect(selected: T) {
  activeIndex.value = undefined;
  emit("add", selected);
  showAutocomplete.value = false;
}

function onBlur(ev: FocusEvent) {
  if (ev.target instanceof HTMLInputElement) {
    emit("blur", ev.target.value);
  }
  showAutocomplete.value = false;
}

function keydown(ev: KeyboardEvent) {
  if (ev.key == "ArrowUp" && showAutocomplete.value) {
    ev.preventDefault();
    up();
    return;
  }

  if (ev.key == "ArrowDown" && showAutocomplete.value) {
    ev.preventDefault();
    down();
    return;
  }

  if (ev.key == "Escape") {
    showAutocomplete.value = false;
  }

  if (ev.key === "Enter") {
    if (
      activeIndex.value !== undefined &&
      activeIndex.value < data.value.length
    ) {
      onSelect(data.value[activeIndex.value]);
    }
    return;
  }
}

function up() {
  if (activeIndex.value == null) {
    activeIndex.value = data.value.length - 1;
  } else if (activeIndex.value == 0) {
    activeIndex.value = undefined;
  } else {
    activeIndex.value = (activeIndex.value - 1) % data.value.length;
  }
  scrollElementIntoView();
}

function down() {
  if (activeIndex.value == null) {
    activeIndex.value = 0;
    scrollElementIntoView();
    return;
  }
  if (activeIndex.value == data.value.length - 1) {
    activeIndex.value = undefined;
    return;
  }
  activeIndex.value = (activeIndex.value + 1) % data.value.length;
  scrollElementIntoView();
  return;
}

const itemRefs = ref<HTMLElement[]>([]);

function scrollElementIntoView() {
  if (activeIndex.value == undefined) {
    return;
  }
  itemRefs.value[activeIndex.value].scrollIntoView({
    block: "nearest",
    inline: "nearest",
  });
}
</script>
