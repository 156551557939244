import { FluxStorage } from "./fluxStorage";

interface StoredData<K extends IDBValidKey, T> {
  key: K;
  value: T;
  expirationTimestamp?: number;
}

export default class IndexedDBStorage<K extends IDBValidKey, T>
  implements FluxStorage<K, T>
{
  private storage?: IDBDatabase;
  private objectStoreName: string;
  private dbName: string;

  constructor(dbName: string, objectStoreName: string) {
    this.objectStoreName = objectStoreName;
    this.dbName = dbName;
  }

  private async getDatabase(): Promise<IDBDatabase> {
    return new Promise<IDBDatabase>((resolve, reject) => {
      const databaseReq = indexedDB.open(this.dbName, 1);
      databaseReq.onupgradeneeded = (event) => {
        if (!(event.target instanceof IDBOpenDBRequest)) {
          // probably got some error
          reject("Wrong event.target type");
          return;
        }
        const db = event.target.result;

        db.createObjectStore(this.objectStoreName, {
          keyPath: "key",
        });
      };

      databaseReq.onsuccess = (event) => {
        if (!(event.target instanceof IDBOpenDBRequest)) {
          // probably got some error
          reject("Wrong event.target type");
          return;
        }
        resolve(event.target.result);
      };

      databaseReq.onerror = (event) => {
        if (!(event.target instanceof IDBOpenDBRequest)) {
          // probably got some error
          reject("Wrong event.target type");
          return;
        }
        reject(event.target.error);
      };

      databaseReq.onblocked = (event) => {
        if (!(event.target instanceof IDBOpenDBRequest)) {
          // probably got some error
          reject("Wrong event.target type");
          return;
        }
        reject("indexedDB.open is blocked by another instance");
      };
    });
  }

  private async getWriteTransaction(): Promise<IDBTransaction> {
    if (this.storage === undefined) {
      this.storage = await this.getDatabase();
    }

    try {
      return this.storage.transaction(this.objectStoreName, "readwrite");
    } catch (e) {
      if (e instanceof DOMException) {
        if (e.name === "NotFoundError") {
          this.storage = await this.getDatabase();
          return this.storage.transaction(this.objectStoreName, "readwrite");
        }
      }
      throw e;
    }
  }

  private async getReadTransaction(): Promise<IDBTransaction> {
    if (this.storage === undefined) {
      this.storage = await this.getDatabase();
    }

    try {
      return this.storage.transaction(this.objectStoreName, "readonly");
    } catch (e) {
      if (e instanceof DOMException) {
        if (e.name === "NotFoundError") {
          this.storage = await this.getDatabase();
          return this.storage.transaction(this.objectStoreName, "readonly");
        }
      }
      throw e;
    }
  }

  public async setItem(
    storageKey: K,
    data: T,
    expiration?: number,
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const transaction = await this.getWriteTransaction();
      const objectStore = transaction.objectStore(this.objectStoreName);
      const req = objectStore.put(<StoredData<K, T>>{
        key: storageKey,
        value: data,
        expirationTimestamp: expiration,
      });
      req.onsuccess = () => {
        resolve();
      };
      req.onerror = (event) => {
        if (event.target instanceof IDBRequest) {
          reject("Got error: " + event.target.error);
          return;
        }
        reject("Unknown event.target");
      };
    });
  }

  public async getItem(storageKey: K): Promise<T> {
    return new Promise(async (resolve, reject) => {
      const transaction = await this.getReadTransaction();
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.get(storageKey);
      request.onsuccess = (event) => {
        if (event.target instanceof IDBRequest) {
          if (event.target.result === undefined) {
            reject("No item found");
            return;
          }
          if (
            event.target.result.expirationTimestamp !== undefined &&
            event.target.result.expirationTimestamp < Date.now()
          ) {
            this.removeItem(storageKey);
            reject("Data is expired");
          }
          resolve(event.target.result.value);
          return;
        }
        reject("Unknown event.target");
      };
      request.onerror = (event) => {
        if (event.target instanceof IDBRequest) {
          reject("Got error: " + event.target.error);
          return;
        }
        reject("Unknown event.target");
      };
    });
  }

  public async removeItem(storageKey: K): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const transaction = await this.getWriteTransaction();
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.delete(storageKey);
      request.onsuccess = () => {
        resolve();
      };
      request.onerror = (event) => {
        reject("Unknown event.target");
      };
    });
  }

  public async getAllKeys(): Promise<K[]> {
    return new Promise(async (resolve, reject) => {
      const transaction = await this.getReadTransaction();
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        if (event.target instanceof IDBRequest) {
          const results: K[] = [];
          for (const data of event.target.result) {
            if (
              data.expirationTimestamp !== undefined &&
              data.expirationTimestamp < Date.now()
            ) {
              this.removeItem(data.key);
              continue;
            }
            results.push(data.key);
          }
          resolve(results);
          return;
        }
        reject("Unknown event.target");
      };
      request.onerror = (event) => {
        if (event.target instanceof IDBRequest) {
          reject("Got error: " + event.target.error);
          return;
        }
        reject("Unknown event.target");
      };
    });
  }

  public async getAll(): Promise<{ key: K; data: T }[]> {
    return new Promise(async (resolve, reject) => {
      const transaction = await this.getReadTransaction();
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.getAll();
      request.onsuccess = (event) => {
        if (event.target instanceof IDBRequest) {
          const results: { key: K; data: T }[] = [];
          for (const data of event.target.result) {
            if (
              data.expirationTimestamp !== undefined &&
              data.expirationTimestamp < Date.now()
            ) {
              this.removeItem(data.key);
              continue;
            }
            results.push({ key: data.key, data: data.value });
          }
          resolve(results);
          return;
        }
        reject("Unknown event.target");
      };
      request.onerror = (event) => {
        if (event.target instanceof IDBRequest) {
          reject("Got error: " + event.target.error);
          return;
        }
        reject("Unknown event.target");
      };
    });
  }
}
