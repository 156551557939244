import { apiClient } from "@/libraries/utils/axios";
import { handledZodParse } from "@/libraries/utils/errorHandling";
import { z } from "zod";

export async function getAutosavePublicKey(
  autosaveKeyId: string,
): Promise<string> {
  const { data } = await apiClient.get(
    "/autosave_key/:autosave_key_id/public_key",
    {
      params: { autosave_key_id: autosaveKeyId },
    },
  );

  return handledZodParse({
    schema: z.string(),
    input: data["public_key"],
    notifyUser: false,
  });
}

export async function getAutosavePrivateKey(
  autosaveKeyId: string,
): Promise<string> {
  const { data } = await apiClient.get(
    "/autosave_key/:autosave_key_id/private_key",
    {
      params: { autosave_key_id: autosaveKeyId },
    },
  );

  return handledZodParse({
    schema: z.string(),
    input: data["private_key"],
    notifyUser: false,
  });
}
