import { UserFromSync } from "../store/interfaces";
import { getUser } from "../plugins/getUser";

// Include & User to make sure it is defined
export const hasPermission = (
  permissionName: string,
  user?: UserFromSync,
): boolean => {
  const currentUser = user ?? getUser();

  if (
    !currentUser.healthcare_provider.access_settings
      .doctor_has_finance_access &&
    currentUser.roles.length === 1 &&
    currentUser.roles[0].name === "doctor" &&
    financePermissions.includes(permissionName)
  ) {
    return false;
  }

  const permissions = currentUser.roles.flatMap((role) => role.permissions);
  const permissionNames = permissions.map((permission) => permission.name);

  return permissionNames.includes(permissionName);
};

const financePermissions = [
  "view-finance-statistics",
  "view-invoice",
  "view-business-intelligence",
  "view-business-intelligence-all",
  "view-patient-invoice",
];
