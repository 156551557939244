import { hasApp } from "@/libraries/plugins/getUser";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import {
  getPatientZorgvergoedingResult,
  getZorgvergoedingResult,
} from "@/queries/zorgvergoedingen/zorgvergoedingenQuery";
import {
  patientZorgvergoedingenQueryKeys,
  zorgvergoedingenQueryKeys,
} from "@/queries/zorgvergoedingen/zorgvergoedingenQueryKeys";
import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useZorgvergoedingen(
  uzovi: Ref<string>,
  packageCode: Ref<string>,
  date: Ref<string>,
) {
  const query = useQuery({
    gcTime,
    staleTime,
    refetchOnWindowFocus: false,
    throwOnError: (error) => {
      throw error;
    },
    queryKey: zorgvergoedingenQueryKeys.zorgvergoedingen(
      uzovi,
      packageCode,
      date,
    ),
    enabled: hasApp("zorgvergoeding") && hasFeatureFlag("zorgvergoeding"),
    queryFn: ({ queryKey }) =>
      getZorgvergoedingResult(queryKey[1], queryKey[2], queryKey[3]),
  });

  return query;
}

export function usePatientZorgvergoedingen(
  zisNumber: Ref<number>,
  date: Ref<string>,
) {
  const query = useQuery({
    gcTime,
    staleTime,
    refetchOnWindowFocus: false,
    queryKey: patientZorgvergoedingenQueryKeys.patientZorgvergoedingen(
      zisNumber,
      date,
    ),
    throwOnError: (error) => {
      throw error;
    },
    enabled: hasApp("zorgvergoeding") && hasFeatureFlag("zorgvergoeding"),
    queryFn: ({ queryKey }) =>
      getPatientZorgvergoedingResult(queryKey[1], queryKey[2]),
  });

  return query;
}
