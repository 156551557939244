<template>
  <div>
    <template v-if="Array.isArray(item.item)">
      <EditorEnabled
        v-for="(child, index) in item.item"
        :item="child"
        :key="index"
        @remove="
          () => {
            item.item.splice(index, 1);
          }
        "
      ></EditorEnabled>
    </template>
    <template v-else>
      <EditorEnabled @remove="item.item = []" :item="item.item"></EditorEnabled>
    </template>
    <div
      class="col-span-full grid grid-cols-4 gap-1 opacity-40 transition-opacity duration-75 focus-within:opacity-100 hover:opacity-100 xl:grid-cols-6"
    >
      <BooleanExpressionVue v-if="showSingle" @item="pushItem" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Or, BooleanExpression } from "@/libraries/questionnaires/score";
import BooleanExpressionVue from "../score/BooleanExpression.vue";
import { computed } from "vue";

const props = defineProps<{ item: Or }>();

const showSingle = computed(
  () => "item" in props.item && Array.isArray(props.item.item),
);

function pushItem(item: BooleanExpression) {
  if ("item" in props.item && Array.isArray(props.item.item)) {
    props.item.item.push(item);
  }
}
</script>
