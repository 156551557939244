import { dateFilter } from "@/filters";
import { MedicalUpdateWithAttachements } from "@/models/MedicalUpdate";
import { Patient } from "@/composables/patient";
import { fullName } from "@/models/Person";
import { JSONContent } from "@tiptap/vue-3";
import moment from "moment";
import { getUser } from "../plugins/getUser";
import { lookupAgbCode } from "@/queries/vektis";
import { getBsn } from "@/queries/bsn/bsn";
import {
  medicalUpdateToJsonContent,
  referralToLegalExportJsonContent,
} from "./MedicalUpdateToJsonContent";
import { format } from "date-fns";
import {
  ConsultSpecificButton,
  letterTypeSpecificButtons,
} from "@/models/LetterTemplate";
import { $t } from "../i18n";
import { clone } from "../utils/clone";

export type Options = {
  patient?: Patient;
  medicalUpdate?: MedicalUpdateWithAttachements;
  appointment?: { start: Date };
  referral?: Patient["referrals"][0];
  medicalUpdates?: MedicalUpdateWithAttachements[];
};

async function traverse(
  node: JSONContent,
  options: Options,
): Promise<JSONContent[]> {
  if (nodeIsPlaceholder(node)) {
    const content = await getPlaceholderText(node.attrs!.field, options);
    if (typeof content === "string") {
      return [
        {
          type: "text",
          text: content,
          marks: node.marks,
        },
      ];
    }

    return content.content ?? [];
  }
  if (nodeIsMedicalUpdate(node) && options.medicalUpdate !== undefined) {
    return medicalUpdateToJsonContent({
      medicalUpdate: options.medicalUpdate,
    });
  }

  if (
    nodeIsLegalExport(node) &&
    options.referral !== undefined &&
    options.medicalUpdates !== undefined
  ) {
    return referralToLegalExportJsonContent(
      options.referral,
      options.medicalUpdates,
    );
  }

  if (node.content) {
    const content = await Promise.all(
      node.content.map(async (child) => {
        return await traverse(child, options);
      }),
    );
    const flatContent = content.flat(1);

    if (node.type !== undefined && !validateNesting(flatContent, node.type)) {
      return flatContent;
    } else {
      node.content = flatContent;
      return [node];
    }
  }
  return [node];
}

function validateNesting(node: JSONContent[], parentType: string) {
  let validChildren: string[] = [];

  if (parentType === "doc") {
    validChildren = ["paragraph", "heading", "bulletList", "orderedList"];
  } else if (parentType === "paragraph") {
    validChildren = ["text", "hardBreak"];
  } else if (parentType === "heading") {
    validChildren = ["text", "hardBreak"];
  } else if (parentType === "bulletList") {
    validChildren = ["listItem"];
  } else if (parentType === "orderedList") {
    validChildren = ["listItem"];
  } else if (parentType === "listItem") {
    validChildren = ["paragraph"];
  } else if (parentType === "text") {
    validChildren = [];
  }

  for (let i = 0; i < node.length; i++) {
    const nodeType = node[i].type;
    if (nodeType && !validChildren.includes(nodeType)) {
      return false;
    }
  }
  return true;
}

export const replacePlaceholders = async (
  data: JSONContent,
  options: Options,
): Promise<JSONContent> => {
  const node = clone(data);
  if (node.content && node.type === "doc") {
    const content = await Promise.all(
      node.content.map(async (child) => {
        return await traverse(child, options);
      }),
    );

    node.content = content.flat(1);
    return node;
  }
  throw new Error("Can only replace placeholders in a doc node.");
};

function nodeIsPlaceholder(data: JSONContent): boolean {
  return data.type === "templatePlaceholder";
}

function nodeIsMedicalUpdate(data: JSONContent): boolean {
  return (
    data.type === "paragraph" && data.content?.[0]?.type === "medicalUpdate"
  );
}

function nodeIsLegalExport(data: JSONContent): boolean {
  return data.type === "paragraph" && data.content?.[0]?.type === "legalExport";
}

export const consultSpecificPlaceHolderText = (
  field: string,
  update: MedicalUpdateWithAttachements,
): string | undefined | JSONContent => {
  const updateType = update.type;
  if (updateType === undefined && !(updateType in letterTypeSpecificButtons)) {
    return;
  }
  const button = letterTypeSpecificButtons[updateType].find(
    (button: ConsultSpecificButton) => button.field === field,
  );

  if (button === undefined) {
    return;
  }
  const result = clone(update.data.body[button.field]);
  if (typeof result === "boolean") {
    return $t("templates.placeholder." + button.field + "_boolean." + result);
  }
  if (button.translation) {
    return $t(button.translation + result);
  }
  return result;
};

const getPlaceholderText = async (
  field: string,
  options: Options,
): Promise<string | JSONContent> => {
  if (options.medicalUpdate) {
    const consultSpecificResult = consultSpecificPlaceHolderText(
      field,
      options.medicalUpdate,
    );
    if (consultSpecificResult) {
      return consultSpecificResult;
    }
  }

  switch (field) {
    case "letter_creation_date":
      return format(new Date(), "dd-MM-yyyy");
    case "patient.full_name":
      return fullName(options.patient);
    case "patient.date_of_birth": {
      const dateOfBirth = options.patient?.date_of_birth;
      if (!dateOfBirth) {
        return "onbekende geboortedatum";
      }
      return dateFilter(dateOfBirth);
    }
    case "appointment.date":
      return moment(options.appointment?.start).format("DD-MM-YYYY");
    case "patient.bsn":
      if (options.patient?.zis_number) {
        try {
          const bsn = await getBsn(options.patient?.zis_number);
          return bsn.bsn ?? "!!onbekend BSN!!";
        } catch {
          return "!!onbekend BSN!!";
        }
      }
      return "!!onbekend BSN!!";
    case "patient.insurer_name": {
      let start_date: Date;
      if (!options.appointment) {
        if (!options.medicalUpdate) return "!!onbekende verzekeraar!!";
        start_date = new Date(options.medicalUpdate.created_at);
      } else if (options.appointment.start instanceof Date) {
        start_date = options.appointment.start;
      }
      return (
        options.patient?.payer_insurers.find(
          (insurer) =>
            new Date(insurer.start_date) <= start_date &&
            start_date <= new Date(insurer.end_date),
        )?.insurer_name ?? "!!onbekende verzekeraar!!"
      );
    }
    case "healthcare_provider.name":
      return getUser().healthcare_provider.name;
    case "main_therapist.name":
      return (
        fullName(
          getMainTherapistFromReferral(
            options.patient?.referrals.find(
              (r) => r.id === options.medicalUpdate?.referral_id,
            ),
          ),
        ) ?? "!!onbekende behandelaar!!"
      );
    case "main_therapist.AGB_code":
      if (
        getMainTherapistFromReferral(
          options.patient?.referrals.find(
            (r) => r.id === options.medicalUpdate?.referral_id,
          ),
        )?.AGB_code === ""
      )
        return "!!onbekende AGB behandelaar!!";
      return (
        getMainTherapistFromReferral(
          options.patient?.referrals.find(
            (r) => r.id === options.medicalUpdate?.referral_id,
          ),
        )?.AGB_code ?? "!!onbekende AGB behandelaar!!"
      );
    case "main_therapist.big_number":
      if (
        getMainTherapistFromReferral(
          options.patient?.referrals.find(
            (r) => r.id === options.medicalUpdate?.referral_id,
          ),
        )?.big_number === ""
      ) {
        return "!!onbekende BIG behandelaar!!";
      }
      return (
        getMainTherapistFromReferral(
          options.patient?.referrals.find(
            (r) => r.id === options.medicalUpdate?.referral_id,
          ),
        )?.big_number ?? "!!onbekende BIG behandelaar!!"
      );
    case "referral.diagnosis_code":
      return (
        options.patient?.referrals.find(
          (r) => r.id === options.medicalUpdate?.referral_id,
        )?.diagnosis_code ?? "!!onbekende diagnosecode!!"
      );
    case "referral.AGB_code": {
      const agb = options.patient?.referrals.find(
        (r) => r.id === options.medicalUpdate?.referral_id,
      )?.AGB_code;

      if (agb) {
        return agb;
      }
      return "!!onbekende AGB verwijzer!!";
    }
    case "referral.name": {
      const agb = options.patient?.referrals.find(
        (r) => r.id === options.medicalUpdate?.referral_id,
      )?.AGB_code;
      if (agb) {
        try {
          const result = await lookupAgbCode(agb);
          return fullName(result);
        } catch {
          return "!!onbekende verwijzer!!";
        }
      }
      return "!!onbekende verwijzer!!";
    }
    case "referral.appointment_count":
      if (
        options.patient?.referrals.find(
          (r) => r.id === options.medicalUpdate?.referral_id,
        )?.treatment_count === undefined
      )
        return "!!onbekend aantal behandelingen!!";
      return `${
        options.patient?.referrals.find(
          (r) => r.id === options.medicalUpdate?.referral_id,
        )?.treatment_count
      }`;
    case "intake.reason_for_coming":
      if (options.medicalUpdates) {
        const intake = options.medicalUpdates.find(
          (update) =>
            update.type === "MedicalUpdateIntake" ||
            update.type === "MedicalUpdateIntakeFlowIntake",
        );
        if (intake?.data.body.RvK) {
          return intake.data.body.RvK;
        }
      }
      if (options.medicalUpdate && options.medicalUpdate.data.body.RvK) {
        return options.medicalUpdate.data.body.RvK;
      }
      return "!!onbekende reden van komst!!";
    case "intake.conclusion":
      if (options.medicalUpdates) {
        const intake = options.medicalUpdates.find(
          (update) =>
            update.type === "MedicalUpdateIntake" ||
            update.type === "MedicalUpdateIntakeFlowIntake",
        );
        if (intake?.data.body.C || intake?.data.body.conclusie) {
          return intake.data.body.C ?? intake.data.body.conclusie;
        }
      }
      if (
        options.medicalUpdate &&
        options.medicalUpdate.data.body.C &&
        options.medicalUpdate.data.body.conclusie
      ) {
        return (
          options.medicalUpdate.data.body.C ??
          options.medicalUpdate.data.body.conclusie
        );
      }
      return "!!onbekende conclusie!!";
    default:
      return field;
  }
};

function getMainTherapistFromReferral(referral?: Patient["referrals"][number]) {
  return getUser().healthcare_provider.users.find(
    ({ id }) => referral?.main_therapist_id === id,
  );
}
