import * as Sentry from "@sentry/vue";
import store from "../store";
import router from "../router";
import { authModule } from "../../auth";
import { nprogress } from "../utils";
import { State } from "../store/interfaces";
import {
  RouteRecord,
  NavigationGuardNext,
  RouteLocationNormalized,
} from "vue-router";
import { apiClient } from "../utils/axios";
import { Store } from "vuex";
import { App } from "vue";

export default (vue: App) => {
  const auth = authModule(store);

  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      if (
        from.query.redirect &&
        typeof from.query.redirect === "string" &&
        from.query.redirect !== to.fullPath
      ) {
        return next(from.query.redirect);
      }
      nprogress.start();
      const authenticated = auth.checkAuth();
      if (to.matched.some((m: any) => m.meta.auth) && !authenticated) {
        nprogress.done();
        return next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      }

      if (
        to.matched.some((m: RouteRecord) => m.meta.guest) &&
        authenticated &&
        window.location.href.indexOf("set_password") > -1
      ) {
        nprogress.done();
        return next({
          path: "/confirm_set_password",
          query: to.query,
        });
      }

      next();
    },
  );

  router.afterEach((to: any, from: any) => {
    nprogress.done();
  });

  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      if (auth.checkAuth() && !storeIsUpToDate(store) && !to.meta?.noSync) {
        apiClient
          .get("/sync")
          .then((response) => {
            if (!response.data.authenticated) {
              auth.unsetToken();
            }
            store.commit("sync", response);
            if (!response.data.authenticated) {
              if (!to.meta?.guest) {
                return next({
                  path: "/login",
                  query: {
                    redirect: to.fullPath,
                  },
                });
              }
            }
            next();
          })
          .catch((reason) => {
            Sentry.captureException(reason);
            return next({
              path: "/error",
              query: {
                redirect_refresh: to.path,
              },
            });
          });
      } else {
        next();
      }
    },
  );

  router.beforeEach(
    (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      localStorage.setItem("previous_path", from.fullPath);
      next();
    },
  );

  router.onError((error) => {
    if (
      error
        .toString()
        .includes("TypeError: error loading dynamically imported module")
    ) {
      if (isRecentlyRefreshed()) {
        return;
      }
      window.sessionStorage.setItem("chunkLoadRefresh", Date.now().toString());
      window.sessionStorage.setItem("chunkLoadRefreshMessage", "1");
      window.location.reload();
    }
  });

  // mount the nprogress to Vue component instance
  // TODO nprogress to composable
  // Object.defineProperties(Vue.prototype, {
  //   $nprogress: { get: () => nprogress },
  // });
};

function storeIsUpToDate({ state }: Store<State>) {
  return (
    state.apps !== undefined &&
    state.roles !== undefined &&
    state.user !== undefined
  );
}

function isRecentlyRefreshed() {
  const refresh = window.sessionStorage.getItem("chunkloadRefresh");
  if (refresh !== null) {
    return Date.now() - parseInt(refresh) < 10000;
  }
  return false;
}
