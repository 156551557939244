<template>
  <BasicTootipGrid class="max-w-sm" v-if="user" :properties="properties">
    <template #icon>
      <user-avatar v-if="user" :user="user" />
    </template>
    <template #name>
      <div class="flex items-baseline gap-2">
        <span>{{ fullName(user) }}</span>
        <span
          class="rounded bg-gray-100 px-1 py-0.5 text-gray-700"
          v-if="user.active === false"
          >Inactive</span
        >
      </div>
    </template>
  </BasicTootipGrid>
</template>

<script lang="ts" setup>
import { fullName } from "@/models/Person";
import { getUser } from "@/libraries/plugins/getUser";
import UserAvatar from "@/components/UserAvatar.vue";
import { computed } from "vue";
import BasicTootipGrid from "./BasicTootipGrid.vue";

const props = defineProps<{ userId: number }>();

const user = getUser().healthcare_provider.users.find(
  (user) => user.id === props.userId,
);

const properties = computed((): string[] => {
  return [
    user?.function_title,
    ...(user?.user_labels?.map((label) => label.name) ?? []),
  ].filter((x): x is string => x !== undefined && x !== "");
});
</script>
