<template>
  <div
    class="text-gray-900 dark:text-white"
    v-flux-loading="isFetching"
    :style="`height: ${size}; width: ${size};`"
    @click="(e) => emit('click', e)"
  >
    <i
      class="fas fa-user-circle"
      v-if="!hasPhoto"
      :style="`font-size: ${size}`"
      aria-hidden="true"
    ></i>
    <img
      class="rounded-full"
      v-else
      :style="`height: ${size}; width: ${size};`"
      :src="photo"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { usePatient } from "@/composables/patient";
import { usePhoto } from "@/queries/photo";

const props = withDefaults(
  defineProps<{
    size?: string;
    zisNumber: number;
  }>(),
  {
    size: "2.5rem",
  },
);

const emit = defineEmits<{
  (e: "click", value: MouseEvent): void;
}>();

// Query
const { data: patient } = usePatient(computed(() => props.zisNumber));

const {
  isFetching,
  data: photo,
  hasPhoto,
} = usePhoto(computed(() => patient.value?.photo_filename));
</script>
