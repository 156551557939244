<template>
  <flux-short-form>
    <flux-short-form-item
      :label="$t('patient.create.form.step.contacts.fullname')"
      :required="true"
    >
      <flux-input
        v-model:modelValue="modelValue.fullname"
        :required="true"
        :prop="propRoot + 'fullname'"
        @error="emit('error', $event)"
      />
    </flux-short-form-item>
    <flux-short-form-item :label="$t('patient.create.form.step.contacts.role')">
      <flux-select class="max-w-xs" v-model:modelValue="modelValue.role">
        <option
          v-for="(role, index) in contactRoles"
          :key="index"
          :value="role"
          :label="$t(`contact.role.${role}`)"
        >
          {{ $t(`contact.role.${role}`) }}
        </option>
      </flux-select>
    </flux-short-form-item>
    <flux-short-form-item
      :label="$t('patient.create.form.step.contacts.relationship')"
    >
      <flux-select
        class="max-w-xs"
        v-model:modelValue="modelValue.relationship"
      >
        <option
          v-for="relationship in contactRelationships"
          :value="relationship"
          :label="$t(`contact.relationship.${relationship}`)"
        >
          {{ $t(`contact.relationship.${relationship}`) }}
        </option>
      </flux-select>
    </flux-short-form-item>
    <div
      class="contents"
      v-for="(phone_number, phoneIndex) in modelValue.phone_numbers"
    >
      <flux-short-form-item
        :label="$t('patient.create.form.step.phone_number.phone_number')"
        span="col-span-10 md:col-span-6"
      >
        <flux-input
          v-model:modelValue="modelValue.phone_numbers[phoneIndex].phone_number"
          type="tel"
          @error="emit('error', $event)"
        />
      </flux-short-form-item>
      <flux-short-form-item span="col-span-2 md:hidden">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-times"
          @click="removePhoneNumber(phoneIndex)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.phone_number.phone_number_type')"
        span="col-span-6 md:col-span-5"
      >
        <flux-select
          v-model:modelValue="
            modelValue.phone_numbers[phoneIndex].phone_number_type
          "
        >
          <option
            v-for="phoneNumberType in phoneNumberTypes"
            :value="phoneNumberType"
            :label="$t(`phone_number.type.${phoneNumberType}`)"
          >
            {{ $t(`phone_number.type.${phoneNumberType}`) }}
          </option>
        </flux-select>
      </flux-short-form-item>
      <flux-short-form-item span="col-span-1 hidden md:block">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-times"
          @click="removePhoneNumber(phoneIndex)"
        />
      </flux-short-form-item>
    </div>
    <div class="col-span-12 -mt-4">
      <flux-button
        type="text"
        size="small"
        icon="fal fa-plus"
        @click="addPhoneNumber()"
      >
        {{ $t("patient.create.form.step.phone_number.add_more") }}
      </flux-button>
    </div>
    <div
      class="contents"
      v-for="(email_address, emailIndex) in modelValue.email_addresses"
      :key="emailIndex"
    >
      <flux-short-form-item
        :label="$t('patient.create.form.step.email_address.email_address')"
        span="col-span-10 md:col-span-6"
      >
        <EmailInput
          v-model:modelValue="
            modelValue.email_addresses[emailIndex].email_address
          "
        />
      </flux-short-form-item>
      <flux-short-form-item span="col-span-2 md:hidden">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-times"
          @click="removeEmailAddress(emailIndex)"
        />
      </flux-short-form-item>
      <flux-short-form-item
        :label="$t('patient.create.form.step.email_address.email_address_type')"
        span="col-span-6 md:col-span-5"
      >
        <flux-select
          v-model:modelValue="
            modelValue.email_addresses[emailIndex].email_address_type
          "
        >
          <option
            v-for="emailAddressType in emailAddressTypes"
            :value="emailAddressType"
            :label="$t(`email_address.type.${emailAddressType}`)"
          >
            {{ $t(`email_address.type.${emailAddressType}`) }}
          </option>
        </flux-select>
      </flux-short-form-item>
      <flux-short-form-item span="col-span-1 hidden md:block">
        <flux-button
          type="text"
          size="small"
          icon="fal fa-times"
          @click="removeEmailAddress(emailIndex)"
        />
      </flux-short-form-item>
    </div>
    <div class="col-span-12 -mt-4">
      <flux-button
        type="text"
        size="small"
        icon="fal fa-plus"
        @click="addEmailAddress()"
      >
        {{ $t("patient.create.form.step.email_address.add_more") }}
      </flux-button>
    </div>
    <flux-short-form-item
      :label="$t('patient.create.form.step.contacts.is_payer')"
    >
      <flux-switch v-model:modelValue="modelValue.is_payer"></flux-switch>
    </flux-short-form-item>
  </flux-short-form>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Patient } from "@/composables/patient";
import {
  contactRoles,
  contactRelationships,
  ContactForm,
} from "../../models/Contact";
import { phoneNumberTypes } from "../../models/PhoneNumber";
import { emailAddressTypes } from "../../models/EmailAddress";
import { $t } from "@/libraries/i18n";
import EmailInput from "@/components/ui/Input/EmailInput.vue";

const modelValue = defineModel<Patient["contacts"][number] | ContactForm>(
  "modelValue",
  {
    required: true,
  },
);

const props = defineProps<{
  patient?: Patient;
  formIndex?: number;
  formPropRoot?: string;
}>();

const emit = defineEmits<{
  error: [boolean];
}>();

const propRoot = computed(() =>
  props.formPropRoot
    ? props.formPropRoot + "." + (props.formIndex ?? 0).toString() + "."
    : "",
);

function addPhoneNumber() {
  modelValue.value.phone_numbers.push({
    phone_number: "",
    phone_number_type: "HP",
    preferred: true,
  });
}

function addEmailAddress() {
  modelValue.value.email_addresses.push({
    email_address: "",
    email_address_type: "HP",
    preferred: true,
  });
}

function removePhoneNumber(phoneIndex: number) {
  modelValue.value.phone_numbers.splice(phoneIndex, 1);
}

function removeEmailAddress(emailIndex: number) {
  modelValue.value.email_addresses.splice(emailIndex, 1);
}
</script>
