import ApiKeyStore from "@/libraries/storage/apiKeyStore";

let apiKeyStore: ApiKeyStore | undefined = undefined;

export function useApiKeyStore() {
  function getApiKeyStore(): ApiKeyStore {
    if (apiKeyStore === undefined) {
      apiKeyStore = new ApiKeyStore();
    }
    return apiKeyStore;
  }
  return {
    keyStore: getApiKeyStore(),
    deInitialize: () => (apiKeyStore = undefined),
  };
}
