<template>
  <flux-button
    :type="type"
    :size="size"
    :icon="icon"
    :iconPosition="iconPosition"
    :native-button-type="buttonType"
    @click="emit('click')"
    :disabled="formError || disabled"
  >
    <slot></slot>
  </flux-button>
</template>

<script lang="ts" setup>
import FormManager from "@/libraries/managers/FormManager";
import { computed, inject } from "vue";
import { ButtonType } from "@/models/Button";

withDefaults(
  defineProps<{
    disabled?: boolean;
    size?: "mini" | "small" | "medium";
    icon?: string;
    iconPosition?: "left" | "right";
    type?: ButtonType;
  }>(),
  {
    type: "primary",
  },
);

const emit = defineEmits<{
  (event: "click"): void;
}>();

const formManager = inject<FormManager | null>("formManager", null);

const buttonType = computed((): HTMLButtonElement["type"] => {
  if (formManager?.submitOnEnter) {
    return "submit";
  }

  return "button";
});

const formError = computed(() => {
  return formManager?.formHasError ?? false;
});
</script>
