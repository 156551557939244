import { useQueryClient, useQuery, useMutation } from "@tanstack/vue-query";
import { apiClient } from "@/libraries/utils/axios";
import { Ref, unref } from "vue";
import { MaybeRef } from "@tanstack/vue-query/build/legacy/types";
import { z } from "zod";
import { useNotify } from "@/composables/notify";
import { ConsentType, consentSchema } from "@/models/Consent";

async function getNivelConsent(
  zisNumber: MaybeRef<number>,
): Promise<NivelConsent[]> {
  const result = await apiClient.get(
    `/patients/${unref(zisNumber)}/consent/nivel`,
  );

  return z.array(nivelConsentScheme).parse(result.data);
}

const nivelConsentScheme = consentSchema
  .omit({ referral_id: true, care_plan_id: true, letter_id: true })
  .extend({ action: z.enum(["nivel"]) });
export type NivelConsent = z.infer<typeof nivelConsentScheme>;

const gcTime = 1000 * 60 * 5;
const staleTime = 1000 * 60 * 60 * 24;

export function useNivelConsent(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  function invalidateNivelConsent(zisNumber: number) {
    queryClient.invalidateQueries({ queryKey: ["nivel_consent", zisNumber] });
  }

  const query = useQuery({
    gcTime,
    staleTime,
    retry: 1,
    queryKey: ["nivel_consent", zisNumber] as const,
    queryFn: ({ queryKey }) => getNivelConsent(queryKey[1]),
  });

  return { ...query, invalidateNivelConsent };
}

export function useUpdateNivelConsent(zisNumber: Ref<number>) {
  const queryClient = useQueryClient();
  const { notify } = useNotify();

  const mutation = useMutation({
    mutationFn: (payload: any) => mutateNivelConsent(zisNumber.value, payload),
    onMutate: async (newConsent) => {
      await queryClient.cancelQueries({
        queryKey: ["nivel_consent", zisNumber.value],
      });

      const previousConsent = queryClient.getQueryData([
        "nivel_consent",
        zisNumber.value,
      ]);

      queryClient.setQueryData(["nivel_consent", zisNumber.value], newConsent);

      return { previousConsent };
    },
    onError: (err, newTodo, context) => {
      notify({
        message: "Consent opslaan mislukt",
        type: "error",
      });
      queryClient.setQueryData(
        ["nivel_consent", zisNumber.value],
        context?.previousConsent,
      );
      queryClient.invalidateQueries({
        queryKey: ["nivel_consent", zisNumber.value],
      });
    },
    onSuccess: () => {
      notify({
        message: "Opslaan Nivel consent gelukt",
        type: "success",
      });
      queryClient.invalidateQueries({ queryKey: ["nivel_consent", zisNumber] });
    },
  });

  return {
    updateNivelConsent: (payload: any) => mutation.mutateAsync(payload, {}),
    isMutating: mutation.isPending,
  };
}

async function mutateNivelConsent(zisNumber: number, newType: ConsentType) {
  await apiClient.patch(
    "/patients/:zis_number/consent",
    { type: newType, action: "nivel" },
    {
      params: {
        zis_number: zisNumber,
      },
    },
  );
}
