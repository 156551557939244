<template>
  <MetaLink :url @linkClick="saveAppointment(appointment)">
    <ContentButton
      class="box-border flex rounded border border-gray-200 bg-white p-2 pt-3 dark:border-neutral-600 dark:bg-neutral-900"
    >
      <div class="min-w-0 flex-grow">
        <DashboardEntryAppointment
          :appointment="appointment"
          :appointmentTypes="appointmentTypes"
        />
        <DashboardEntrySubject :appointment="appointment" :patient="patient" />
      </div>
      <div class="flex gap-2 pr-2 md:pr-6">
        <ClickableIcon
          icon="fa-clock"
          @click.stop="emit('showEvents')"
          :hideTooltip="true"
          content=""
        />
        <div class="hidden lg:block">
          <AppointmentStatusDropdown
            :appointment="appointment"
            direction="right"
            :useAppointmentStore="false"
            @change="
              (appointment, status) => handleStatusChange(appointment, status)
            "
          />
        </div>
        <div class="block lg:hidden">
          <AppointmentStatusDropdown
            :appointment="appointment"
            direction="left"
            :useAppointmentStore="false"
            @change="
              (appointment, status) => handleStatusChange(appointment, status)
            "
          />
        </div>
      </div>
    </ContentButton>
  </MetaLink>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import {
  AppointmentStatus,
  AppointmentType,
  getPatientParticipants,
  PatientParticipant,
} from "@/models/Appointment";
import AppointmentStatusDropdown from "@/components/calendar/Appointment/AppointmentStatusDropdown.vue";

import DashboardEntryAppointment from "./DashboardEntryAppointment.vue";
import DashboardEntrySubject from "./DashboardEntrySubject.vue";
import {
  AppointmentApi,
  PatientApi,
} from "@/libraries/repositories/appointmentRepositoryUsingApi";
import ClickableIcon from "../ClickableIcon.vue";
import { useShareAppointment } from "@/composables/appointment";
import MetaLink from "../ui/MetaLink.vue";
import ContentButton from "../ui/Input/ContentButton.vue";

const props = defineProps<{
  appointment: AppointmentApi;
  appointmentTypes?: AppointmentType[];
  patientZisMap: Map<number, PatientApi>;
}>();

const emit = defineEmits(["appointmentStatusChange", "showEvents"]);

const patientParticipant = computed((): PatientParticipant | undefined =>
  getPatientParticipants(props.appointment).at(0),
);

const patient = computed((): PatientApi | undefined => {
  if (!patientParticipant.value) {
    return;
  }
  return props.patientZisMap.get(patientParticipant.value.patient_zis_number);
});

const url = computed(() => {
  if (patient.value) {
    return `/patient/${patient.value.zis_number}`;
  }
  return "/calendar";
});

const handleStatusChange = async (
  appointment: AppointmentApi,
  status: AppointmentStatus,
) => {
  emit("appointmentStatusChange", appointment, status);
};

const { saveAppointment } = useShareAppointment();
</script>
