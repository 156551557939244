<template>
  <div class="contents">
    <div>
      <div class="text-base font-semibold dark:text-white">
        {{ props.title }}
      </div>
      <span
        class="text-xs text-gray-600 dark:text-neutral-300"
        v-if="!$slots.description"
      >
        {{ props.description }}
      </span>
      <slot v-else name="description"></slot>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string;
  description?: string;
}>();
</script>
