import { TypedAxiosInstance } from "restyped-axios";
import {
  AppointmentRepository,
  AppointmentRequest,
} from "./appointmentRepository";
import { FluxApi } from "@/apis/flux";
import { z } from "zod";
import {
  appointmentParticipantRequired,
  appointmentParticipantStatus,
  appointmentStatus,
  AppointmentStatus,
} from "@/models/Appointment";
import { telemetryManager } from "../telemetry/Manager";
import { hasFeatureFlag } from "../plugins/hasFeatureFlag";
import { generateInvoice } from "@/actions/generateInvoice";
import { Router } from "vue-router";
import { genderTypes } from "@/models/Patient";
import { locationSchema } from "@/models/Location";

const AppointmentTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  duration: z.number(),
  style: z.string(),
  online_scheduling: z.boolean(),
  slug: z.string(),
  deleted_at: z.optional(z.string()),
  private: z.boolean(),
  house_call: z.boolean(),
  first_consult: z.boolean(),
});

const EmailSentSchema = z.object({
  id: z.number(),
  type: z.string(),
  email: z.string(),
  patient_zis_number: z.optional(z.number()),
  user_id: z.optional(z.number()),
  appointment_id: z.optional(z.number()),
  invoice_id: z.optional(z.number()),
  metadata: z.optional(z.any()),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.string().transform((s) => new Date(s)),
});

const UserSchema = z.object({
  id: z.number(),
  first_names: z.string(),
  surname_prefix: z.optional(z.string()),
  surname: z.optional(z.string()),
  initials: z.string(),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.optional(z.string().transform((s) => new Date(s))),
});

const ParticipantSchema = z.object({
  id: z.number(),
  appointment_id: z.number(),
  patient_zis_number: z.optional(z.number()),
  user_id: z.optional(z.number()),
  location_id: z.optional(z.number()),
  healthcare_service_id: z.optional(z.number()),
  responsible_user_id: z.optional(z.number()),
  status: z.enum(appointmentParticipantStatus),
  required: z.optional(z.enum(appointmentParticipantRequired)),
});

const ReferralSchema = z.object({
  id: z.number(),
  patient_zis_number: z.optional(z.number()),
  AGB_code: z.optional(z.string()),
  referred_at: z.optional(z.string().transform((s) => new Date(s))),
  specialism_code: z.optional(z.string()),
  is_self_referral: z.boolean(),
  hcp_diagnosis: z.optional(z.string()),
  diagnosis_code: z.optional(z.string()),
  referrer_diagnosis_code: z.optional(z.string()),
  closed_at: z.optional(z.string().transform((s) => new Date(s))),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.optional(z.string().transform((s) => new Date(s))),
  deleted_at: z.optional(z.string().transform((s) => new Date(s))),
});

export const AppointmentSchema = z.object({
  id: z.number(),
  status: z.enum(appointmentStatus),
  description: z.optional(z.string()),
  start: z.string().transform((s) => new Date(s)),
  end: z.string().transform((s) => new Date(s)),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.string().transform((s) => new Date(s)),
  deleted_at: z
    .optional(z.string())
    .transform((s) => (s ? new Date(s) : undefined)),
  referral_id: z.optional(z.number()),
  appointment_type_id: z.optional(z.number()),
  private: z.optional(z.boolean()),
  uuid: z.string().uuid(),
  participants: z.array(ParticipantSchema),
  email_sents: z.array(EmailSentSchema).optional(),
  referral: z.optional(ReferralSchema),
  locked: z.optional(z.boolean()),
});

const AppointmentPatientSchema = z.object({
  zis_number: z.number(),
  first_names: z.optional(z.string()),
  initials: z.optional(z.string()),
  surname: z.optional(z.string()).transform((s) => s ?? ""),
  surname_prefix: z.optional(z.string()),
  maiden_name: z.optional(z.string()),
  maiden_name_prefix: z.optional(z.string()),
  gender: z.enum(genderTypes),
  date_of_birth: z.optional(z.string().transform((s) => new Date(s))),
  date_of_death: z.optional(z.string().transform((s) => new Date(s))),
  deceased: z.boolean().optional(),
  created_at: z.string().transform((s) => new Date(s)),
  updated_at: z.optional(z.string().transform((s) => new Date(s))),
  deleted_at: z.optional(z.string().transform((s) => new Date(s))),
  languages: z.optional(
    z.array(
      z.object({ code: z.string(), communication_language_nl: z.string() }),
    ),
  ),
});

export const appointmentDataApiResponseSchema = z.object({
  appointments: z.array(AppointmentSchema),
  users: z.array(UserSchema),
  locations: z.array(locationSchema),
  patients: z.array(AppointmentPatientSchema),
});

export type AppointmentDataApiResponse = z.infer<
  typeof appointmentDataApiResponseSchema
>;

export type AppointmentRepositoryBody = ReturnType<
  (typeof AppointmentSchema)["parse"]
>;
export type AppointmentRepositoryResponse = AppointmentRepositoryBody[];

export type AppointmentApi = AppointmentDataApiResponse["appointments"][number];

export type PatientApi = AppointmentDataApiResponse["patients"][number];

class AppointmentRepositoryUsingApi implements AppointmentRepository {
  #apiClient!: TypedAxiosInstance<FluxApi>;

  constructor(apiClient: TypedAxiosInstance<FluxApi>) {
    this.#apiClient = apiClient;
  }
  async findAll(req: AppointmentRequest): Promise<AppointmentDataApiResponse> {
    const { data } = await this.#apiClient.get("appointments", {
      params: req,
    });
    return appointmentDataApiResponseSchema.parse(data);
  }

  async findTrashed(
    req: AppointmentRequest,
  ): Promise<AppointmentDataApiResponse> {
    const { data } = await this.#apiClient.get("appointments/trashed", {
      params: req,
    });
    return appointmentDataApiResponseSchema.parse(data);
  }

  async updateStatus(
    appointment: AppointmentRepositoryBody,
    status: AppointmentStatus,
    router: Router,
  ): Promise<void> {
    await this.#apiClient.patch(
      "/appointments/:id/status",
      { status },
      {
        params: {
          id: appointment.id,
        },
      },
    );
    telemetryManager.queueEntry({
      action: "appointment.update.status",
      context: {
        status: appointment.status,
        appointment_id: appointment.id,
        invoice_generation: hasFeatureFlag("invoice-generation"),
      },
    });
    const patientParticipant = appointment.participants.find(
      ({ patient_zis_number }) => patient_zis_number !== undefined,
    );
    if (
      status === "FULFILLED" &&
      patientParticipant?.patient_zis_number &&
      hasFeatureFlag("invoice-generation")
    ) {
      generateInvoice(
        router,
        patientParticipant.patient_zis_number,
        appointment,
        undefined,
        appointment.referral_id,
      );
    }
  }
}

export default AppointmentRepositoryUsingApi;
