<template></template>

<script lang="ts" setup>
import useManager, { managerKey } from "@/libraries/managers/useManager";
import { h, inject, onBeforeUnmount, onMounted, VNode } from "vue";
import { TableColumn } from "./tableHelper";

const props = defineProps<{
  prop: string;
  label: string;
  smLabel?: string;
  thClass?: string;
  tdClass?: string;
  columnClass?: string;
  primary?: boolean;
  clickable?: true;
  sortable?: true;
  align?: "left" | "right";
  sortFunction?: (direction: "asc" | "desc", a: any, b: any) => number;
  dataTransform?: (d: any | undefined) => any;
}>();

const manager = inject(managerKey) as
  | ReturnType<typeof useManager<TableColumn>>
  | undefined;
if (!manager) {
  throw new Error("Expected tablecolumn manager to be defined");
}

const slots = defineSlots<{
  row(props: any): VNode;
}>();

onMounted(() => {
  const f = (data: any) => {
    if (props.dataTransform) {
      return h("span", props.dataTransform(data[props.prop]));
    } else {
      const value = data[props.prop];
      if (value instanceof Temporal.Instant) {
        return h(
          "span",
          value.toLocaleString("nl-NL", { timeZone: "Europe/Amsterdam" }),
        );
      }

      return h("span", data[props.prop]);
    }
  };

  const content: any = slots.row ?? f;

  manager.register({
    ...props,
    content,
    primary: props.primary ?? false,
  });
});

onBeforeUnmount(() => manager.unregister(props.prop));
</script>
