<template>
  <div>
    <select
      class="border-box relative m-0 block appearance-none rounded border border-gray-300 bg-white py-2 pl-3 pr-3 text-base shadow-sm transition duration-75 focus:border-blue-600 focus:outline-none focus:ring sm:text-sm dark:border-neutral-700 dark:bg-neutral-800 dark:text-neutral-300"
      v-model="selectedValue"
      @update:model-value="add($event)"
      @keyup.enter="add(selectedValue)"
      @blur="emit('blur', $event)"
      :class="[
        error ? 'ring ring-red-400 focus:border-red-500' : '',
        disabled ? 'cursor-not-allowed bg-gray-100 text-gray-500' : '',
        fitContent ? 'w-auto' : 'w-full',
      ]"
      :disabled="disabled"
    >
      <option v-if="placeholder" :value="undefined" disabled hidden selected>
        {{ placeholder }}
      </option>
      <option
        v-for="option in options"
        v-bind:value="option"
        :label="getLabel(option)"
        :key="getOptionKey(option)"
        @click="add(option)"
      >
        {{ getLabel(option) }}
      </option>
    </select>
    <div class="mt-4 min-h-[2rem]">
      <div class="flex max-w-xl flex-wrap gap-2" v-if="options.length > 0">
        <span
          class="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700 dark:bg-neutral-700 dark:text-white"
          v-for="(optionId, index) in modelValue"
          :key="index"
        >
          {{ getLabel(optionId) }}
          <button
            type="button"
            :class="[
              'ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full',
              'text-gray-500 hover:bg-gray-200 hover:text-gray-500 dark:bg-neutral-700 dark:text-neutral-300 hover:dark:bg-neutral-800 hover:dark:text-white',
              'cursor-pointer focus:bg-gray-500 focus:text-white focus:outline-none',
            ]"
            @click="removeAt(index)"
          >
            <svg
              class="h-2 w-2"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 8 8"
            >
              <path
                stroke-linecap="round"
                stroke-width="1.5"
                d="M1 1l6 6m0-6L1 7"
              />
            </svg>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script
  setup
  lang="ts"
  generic="T extends { [key: string]: any }, K extends keyof T"
>
import { ref } from "vue";

const modelValue = defineModel<(string | number)[]>("modelValue", {
  required: true,
});

const props = withDefaults(
  defineProps<{
    options: Readonly<T[]>;
    idKey: K;
    labelKey: K | ((option: T) => string);
    placeholder?: string;
    icon?: string;
    error?: boolean;
    disabled?: boolean;
    fitContent?: boolean;
  }>(),
  {
    placeholder: undefined,
    icon: undefined,
    error: undefined,
    disabled: undefined,
    fitContent: false,
  },
);

const emit = defineEmits<{
  (e: "blur", value: Event): void;
  (e: "focus", value: Event): void;
}>();

const selectedValue = ref<T>();

function removeAt(removed: number) {
  modelValue.value = modelValue.value.filter((_, index) => index !== removed);
}

function add(option: T | undefined) {
  if (option === undefined) {
    return;
  }

  if (optionHasBeenSelected(option)) {
    return;
  }

  modelValue.value.push(option[props.idKey]);
}

function optionHasBeenSelected(option: T): boolean {
  return modelValue.value.some((selected) => selected == option[props.idKey]);
}

function getLabel(option: T | string | number): string {
  if (typeof option === "string" || typeof option === "number") {
    return getLabel(getOptionFromId(option));
  }

  if (typeof props.labelKey === "function") {
    return props.labelKey(option);
  }

  return option[props.labelKey].toString();
}

function getOptionFromId(id: string | number) {
  const foundOption = props.options.find((option) => option[props.idKey] == id);
  if (!foundOption) {
    throw new Error("Could not find option");
  }
  return foundOption;
}

function getOptionKey(option: T) {
  return option[props.idKey];
}
</script>

<style>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.dark select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}
</style>
