import { apiClient } from "../utils/axios";
import { ref } from "vue";
import { Insurer } from "@/models/Insurer";
import { defineStore } from "pinia";

export const useInsurerStore = defineStore("insurers", () => {
  const cache = ref<Promise<Insurer[]>>();

  const getCache = () =>
    (cache.value ??= apiClient
      .get("/insurers")
      .then(({ data }) => data.insurers ?? []));

  async function findAll(): Promise<Insurer[]> {
    return await getCache();
  }

  async function findById(insurerId: number): Promise<Insurer | undefined> {
    return (await getCache()).find(({ id }) => id === insurerId);
  }

  return { findAll, findById };
});
