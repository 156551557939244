import { AxiosInstance } from "axios";
import { InjectionKey } from "vue";

export type CompanyAdministrationForm = {
  name: string;
  attention_name?: string;
  email_address?: string;
  kvk?: string;
  btw?: string;
  address?: {
    street_name: string;
    house_number: string;
    postal_code: string;
    city: string;
    country_id: number;
  };
};

export const companyAdministrationRepositoryKey = Symbol() as InjectionKey<
  () => CompanyAdministrationRepository
>;

export interface CompanyAdministrationRepository {
  update(
    uuid: string,
    companyId: string,
    form: CompanyAdministrationForm,
  ): Promise<string>;

  create(companyId: string, form: CompanyAdministrationForm): Promise<string>;
}

export class CompanyAdministrationRepositoryUsingMemory
  implements CompanyAdministrationRepository
{
  constructor(protected administrations: any[]) {}

  async create(
    companyId: string,
    form: CompanyAdministrationForm,
  ): Promise<string> {
    const uuid = crypto.randomUUID();
    this.administrations.push({
      uuid,
      ...form,
    });

    return uuid;
  }

  async update(
    uuid: string,
    companyId: string,
    form: CompanyAdministrationForm,
  ): Promise<string> {
    return uuid;
  }
}

export class CompanyAdministrationRepositoryUsingApi
  implements CompanyAdministrationRepository
{
  constructor(protected apiClient: AxiosInstance) {}

  async create(
    companyId: string,
    form: CompanyAdministrationForm,
  ): Promise<string> {
    const res = await this.apiClient.post(
      "/companies/:companyId/administrations",
      form,
      {
        params: {
          companyId,
        },
      },
    );

    return res.data.uuid;
  }

  async update(
    uuid: string,
    companyId: string,
    form: CompanyAdministrationForm,
  ): Promise<string> {
    const res = await this.apiClient.put(
      "/companies/:companyId/administrations/:uuid",
      form,
      {
        params: {
          uuid,
          companyId,
        },
      },
    );
    return res.data.uuid;
  }
}
