import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

const isInitialLoad = ref<boolean>();

export function useErrorPageRedirects() {
  const router = useRouter();
  const route = useRoute();
  if (isInitialLoad.value === true) {
    isInitialLoad.value = false;
  }
  if (isInitialLoad.value === undefined) {
    isInitialLoad.value = true;
  }
  onMounted(() => {
    if (isInitialLoad.value === false) {
      return;
    }
    if (
      !route.query.redirect_refresh ||
      typeof route.query.redirect_refresh !== "string"
    ) {
      return;
    }
    router.push(route.query.redirect_refresh);
  });
}
