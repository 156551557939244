import { mergeAttributes, Node } from "@tiptap/vue-3";
import { $t } from "../i18n";

interface TemplatePlaceholderOptions {
  field: string;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    proseMirrorTemplatePlaceholder: {
      /**
       * Add an image
       */
      setProseMirrorTemplatePlaceholder: (options: {
        field: string;
      }) => ReturnType;
    };
  }
}

export const ProseMirrorTemplatePlaceholder =
  Node.create<TemplatePlaceholderOptions>({
    name: "proseMirrorTemplatePlaceholder",
    renderHTML({ HTMLAttributes }) {
      const text = $t(`templates.placeholder.${HTMLAttributes.field}`);
      return [
        "span",
        mergeAttributes(HTMLAttributes, {
          class: "ProseMirror-TemplatePlaceholder",
        }),
        text,
      ];
    },
    parseHTML() {
      return [
        {
          tag: "span.ProseMirror-TemplatePlaceholder",
        },
      ];
    },
    addAttributes() {
      return {
        field: "default",
      };
    },
    inline: false,

    group: "block",

    addCommands() {
      return {
        setProseMirrorTemplatePlaceholder:
          (options) =>
          ({ commands }) => {
            return commands.insertContent({
              type: "proseMirrorTemplatePlaceholder",
              attrs: options,
            });
          },
      };
    },
  });
