import { z } from "zod";

export const taskStatuses = ["to_do", "doing", "cancelled", "done"] as const;

const personScheme = z.object({
  first_names: z.string().optional(),
  surname: z.string().optional(),
  surname_prefix: z.string().optional(),
  maiden_name: z.string().optional(),
  maiden_name_prefix: z.string().optional(),
  fullname: z.string().optional(),
  nickname: z.string().optional(),
  initials: z.string().optional(),
});

const taskPatientSchema = personScheme.extend({
  zis_number: z.number(),
});

const taskAssigneeSchema = personScheme.extend({
  id: z.number(),
});

export const taskScheme = z.object({
  id: z.string().optional(),
  patient_zis_number: z.number().optional(),
  patient: taskPatientSchema.optional(),
  assignee_id: z.number().optional(),
  assignee: taskAssigneeSchema.optional(),
  title: z.string(),
  due_date: z.string().transform((value) => Temporal.PlainDate.from(value)),
  healthcare_provider_id: z.number(),
  status: z.enum(taskStatuses),
  description: z.string().optional(),
  created_at: z.string().optional(),
  updated_at: z.string().optional(),
});

export type Task = z.infer<typeof taskScheme>;
export type TaskPatient = z.infer<typeof taskPatientSchema>;
export type TaskAssignee = z.infer<typeof taskAssigneeSchema>;
export type TaskStatus = (typeof taskStatuses)[number];
