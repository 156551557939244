<template>
  <span
    :class="[
      'inline-flex items-center rounded-md px-2.5 py-0.5 text-xs font-medium',
      colorsResult,
    ]"
    ><slot></slot
  ></span>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const colors = {
  success: "text-green-800 bg-green-100 dark:text-green-100 dark:bg-green-800",
  neutral:
    "text-slate-800 bg-slate-100 dark:bg-neutral-700 dark:text-neutral-300",
  down: "text-orange-800 bg-orange-100 dark:bg-orange-800 dark:text-orange-100",
} as const;

const props = defineProps<{
  type?: keyof typeof colors;
}>();

const colorsResult = computed(() => {
  return colors[props.type ?? "success"];
});
</script>
