import { Nationality } from "@/models/Nationality";
import { defineStore } from "pinia";
import { ref } from "vue";
import { apiClient } from "../utils/axios";

export const useNationalityStore = defineStore("nationalities", () => {
  const cache = ref<Promise<Nationality[]>>();

  const getCache = () =>
    (cache.value ??= apiClient
      .get("/nationalities")
      .then(({ data }) => data.nationalities ?? []));

  async function findAll(): Promise<Nationality[]> {
    return await getCache();
  }

  return { findAll };
});
