import { InjectionKey, Ref, ref } from "vue";

interface Item {
  prop: string;
}

export const managerKey = Symbol() as InjectionKey<
  ReturnType<typeof useManager<any>>
>;

export default function useManager<T extends Item>() {
  const items = ref<T[]>([]) as Ref<T[]>;

  function register(item: T) {
    items.value.push(item);
  }

  function unregister(prop: string) {
    items.value = items.value.filter((item) => item.prop !== prop);
  }

  return { items, register, unregister };
}
