export function sortObjectByKey<T>(
  direction: "asc" | "desc",
  key: keyof T,
  a: T,
  b: T,
) {
  if (a[key] < b[key]) {
    return direction === "desc" ? 1 : -1;
  }
  if (b[key] < a[key]) {
    return direction === "desc" ? -1 : 1;
  }
  return 0;
}
