import { Ref, computed } from "vue";
import { useCompanies } from "./companies";

export function useCompanyDivision(companyDivisionId: Ref<string | undefined>) {
  const { data: companies, isLoading, isError } = useCompanies();
  const company = computed(() =>
    companies.value?.find((c) =>
      c.company_divisions.some(({ uuid }) => uuid === companyDivisionId.value),
    ),
  );

  const companyDivision = computed(() =>
    company.value?.company_divisions.find(
      ({ uuid }) => uuid === companyDivisionId.value,
    ),
  );

  return {
    company,
    companyDivision,
    isLoading,
    isError,
  };
}
