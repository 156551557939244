import Manager from "@/libraries/managers/Manager";

interface SecondaryMenuItem {
  prop: string;
  label: string;
}

export class ItemManager extends Manager<SecondaryMenuItem> {
  constructor(activeItem: string | null = null) {
    super();
    this.activeItem = activeItem;
  }

  activeItem: string | null;

  setActiveItem(prop: string) {
    this.activeItem = prop;
  }
}
