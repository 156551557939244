<template>
  <div v-html="content" v-once />
</template>

<script lang="ts" setup>
import StarterKit from "@tiptap/starter-kit";
import { TemplatePlaceholder } from "@/libraries/tiptap/TemplatePlaceholder";
import { MedicalUpdate } from "@/libraries/tiptap/MedicalUpdate";
import { generateHTML } from "@tiptap/html";
import Link from "@tiptap/extension-link";
import Underline from "@tiptap/extension-underline";

const props = defineProps<{ json: object }>();

const CustomStarterKit = StarterKit.configure({
  paragraph: {
    HTMLAttributes: {
      class: "min-h-[1rem] ",
    },
  },
  horizontalRule: {
    HTMLAttributes: {
      class: "border-t border-200 my-4",
    },
  },
});

const content = generateHTML(props.json, [
  TemplatePlaceholder,
  MedicalUpdate,
  CustomStarterKit,
  Link.configure({
    openOnClick: false,
  }),
  Underline,
]);
</script>

<style>
.ProseMirror p:empty::before {
  content: "";
  display: inline-block;
}
</style>
