<template>
  <div class="min-h-[2rem]" v-flux-loading="loading">
    <div class="text-xs" v-if="noLogsAvailable === true">
      Geen geschiedenis beschikbaar.
    </div>
    <div v-else-if="!loading">
      <h3 class="mb-1 text-sm font-normal dark:text-white">Geschiedenis</h3>
      <ul class="m-0 space-y-3 p-0" v-if="typeof logs === 'object'" role="list">
        <li
          class="relative flex justify-between"
          v-for="(groupedLogs, key, index) in logs"
        >
          <div class="absolute -bottom-6 right-0 top-0 flex w-3 justify-center">
            <div
              class="w-px bg-gray-200 dark:bg-neutral-600"
              v-if="!(index == Object.keys(logs).length - 1)"
            ></div>
          </div>
          <div class="flex gap-x-2">
            <p
              class="text-xs leading-4"
              v-if="groupedLogs[0].payload.actor.user_id"
            >
              <Tooltip placement="top-start">
                <template #content>
                  <UserTooltipContent
                    :userId="groupedLogs[0].payload.actor.user_id"
                  />
                </template>
                <AvatarVue :avatar="getFullUser(groupedLogs[0])?.avatar" />
              </Tooltip>
            </p>
            <p
              class="py-0.5 text-xs leading-4"
              v-else-if="groupedLogs[0].payload.actor.zis_number"
            >
              <span class="" :title="fullName(getFullUser(groupedLogs[0]))">
                <async-patient-link
                  :format="FormatName.fullNameInitials"
                  :zisNumber="groupedLogs[0].payload.actor.zis_number"
                />
              </span>
            </p>
            <p class="py-0.5 text-xs leading-4" v-else>
              <span class="font-medium text-gray-900 dark:text-neutral-100">{{
                getInitiatorTitle(groupedLogs[0])
              }}</span>
            </p>
            <ul class="p-0" role="list">
              <li class="flex" v-for="log in groupedLogs">
                <p
                  class="text flex-auto py-0.5 text-left text-xs leading-4 text-gray-500 dark:text-neutral-300"
                >
                  {{ getLogText(log) }}
                </p>
              </li>
            </ul>
          </div>
          <div class="flex flex-shrink-0 items-start gap-x-2">
            <flux-tooltip
              class="leading-4"
              :content="
                moment(groupedLogs[0].payload.at).format('dd DD-MM-YYYY hh:mm')
              "
            >
              <time
                class="flex-none text-xs leading-4 text-gray-500 dark:text-white"
                :datetime="groupedLogs[0].payload.at"
                >{{ getLogTime(groupedLogs[0]) }}</time
              >
            </flux-tooltip>

            <div
              class="relative flex h-5 w-3 flex-none items-center justify-center bg-white dark:bg-neutral-900"
            >
              <div
                class="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300 dark:bg-neutral-700 dark:ring-neutral-100"
              ></div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, Ref, ref, unref, watchEffect } from "vue";
import { Appointment } from "@/models/Appointment";
import { apiClient } from "@/libraries/utils/axios";
import { fullName } from "@/models/Person";
import AvatarVue from "@/components/Avatar.vue";
import { $t } from "@/libraries/i18n";
import { Location } from "@/models/Location";
import moment from "moment";
import { getUser } from "@/libraries/plugins/getUser";
import { User } from "@/models/User";
import Tooltip from "@/components/ui/Tooltip.vue";
import UserTooltipContent from "@/components/ui/TooltipContent/UserTooltipContent.vue";
import { FormatName } from "@/models/Person";
import { AppointmentApi } from "@/libraries/repositories/appointmentRepositoryUsingApi";

const props = defineProps({
  appointment: {
    required: true,
    type: Object as PropType<Appointment | AppointmentApi>,
  },
  locations: {
    required: true,
    type: Array as PropType<Location[]>,
  },
});

interface Log {
  type: string;
  payload: any;
}

const { loading, logs } = useHistory(computed(() => props.appointment.id));

function getFullUser(log: Log): User | undefined {
  if (log.payload.actor.user_id) {
    return userName(log.payload.actor.user_id);
  }
}

function getInitiatorTitle(log: Log): string {
  if (log.payload.actor.user_id) {
    return fullName(userName(log.payload.actor.user_id));
  }
  if (log.payload.actor.email) {
    return log.payload.actor.email;
  }
  if (log.payload.actor.zis_number) {
    return "Patient";
  }
  if (log.payload.actor.initiator) {
    return "Flux";
  }
  return "Onbekend";
}

function userName(id: number): User | undefined {
  return getUser().healthcare_provider.users.find((user) => id == user.id);
}

function locationName(id: number) {
  return props.locations.find((location: Location) => id == location.id)?.name;
}

function getLogText(log: Log) {
  switch (log.type) {
    case "AppointmentWasCreated":
      return `maakt afspraak ${moment(log.payload.start).format(
        "DD/MM",
      )} - ${moment(log.payload.start).format("H:mm")} - ${moment(
        log.payload.end,
      ).format("H:mm")}`;
    case "AppointmentStatusWasChanged":
      return `wijzigt status naar ${$t(
        `appointment.status.${log.payload.status}`,
      )}`;
    case "AppointmentLocationWasChanged":
      return `wijzigt locatie naar ${locationName(log.payload.location_id)}`;
    case "AppointmentTimeWasChanged":
      return `wijzigt datum ${moment(log.payload.start).format(
        "DD/MM",
      )} - ${moment(log.payload.start).format("H:mm")} - ${moment(
        log.payload.end,
      ).format("H:mm")}`;
    case "AppointmentWasRescheduled":
      return `verplaats naar ${moment(log.payload.start).format(
        "DD/MM",
      )} - ${moment(log.payload.start).format("H:mm")} - ${moment(
        log.payload.end,
      ).format("H:mm")}`;
    case "AppointmentPatientWasChanged":
      return `wijzigt patient`;
    case "AppointmentResponsibleUserWasChanged":
      return `wijzigt verantwoordelijke naar ${fullName(
        userName(log.payload.user_id),
      )}`;
    case "AppointmentUserWasAdded":
      return `voegt ${fullName(userName(log.payload.user_id))} toe`;
    case "AppointmentUserWasRemoved":
      return `verwijdert ${fullName(userName(log.payload.user_id))}`;
    case "AppointmentEmailWasSent":
      if (log.payload.actor.email || log.payload.actor.zis_number) {
        return `heeft email ontvangen`;
      }
      return `heeft email verstuurd`;
    case "AppointmentCreditWasReserved":
      return `heeft ${log.payload.amount} credit${
        log.payload.amount > 1 ? "s" : ""
      } gereserveed`;
    case "AppointmentCreditWasUnReserved":
      return `heeft ${log.payload.amount} credit${
        log.payload.amount > 1 ? "s" : ""
      } vrijgegeven`;
    case "AppointmentCreditWasSpent":
      return `heeft ${log.payload.amount} credit${
        log.payload.amount > 1 ? "s" : ""
      } afgeschreven`;
    case "AppointmentCreditWasUnSpent":
      return `heeft ${log.payload.amount} credit${
        log.payload.amount > 1 ? "s" : ""
      } terug geschreven`;
    case "AppointmentTypeWasChanged":
      return log.payload.appointment_type_name
        ? `wijzigt type ${log.payload.appointment_type_name}`
        : "verwijdert afspraak type";
    case "AppointmentWasDeleted":
      return `heeft de afspraak verwijderd`;
    case "AppointmentBlockedForGenerationWasChanged":
      return `heeft de afspraak ${
        log.payload.block_generation ? "geblokkeerd" : "gedeblokkeerd"
      } voor facturatie`;
    default:
      return "";
  }
}

function getLogTime(log: Log) {
  const datetime = moment(log.payload.at);
  return datetime.fromNow();
}

moment.updateLocale("nl", {
  relativeTime: {
    past: "%s",
    s: "nu",
    m: "1 min",
    mm: "%d min",
    h: "1 uur",
    d: "1 dag",
    w: "1 week",
    M: "1 maand",
    y: "1 jaar",
  },
});

function useHistory(appointmentId: Ref<number>) {
  const logs = ref<Record<string, any[]>>();
  const loading = ref(false);
  const loadedForAppointment = ref<number>();

  async function fetch() {
    loading.value = true;
    try {
      if (unref(appointmentId) === loadedForAppointment.value) {
        return;
      } else {
        logs.value = undefined;
      }
      const res = await apiClient.get(
        `appointments/${unref(appointmentId)}/events`,
      );
      loadedForAppointment.value = unref(appointmentId);
      if (!Array.isArray(res.data.logs)) {
        logs.value = res.data.logs;
      }
    } finally {
      loading.value = false;
    }
  }

  watchEffect(fetch);

  return { logs, loading };
}

const noLogsAvailable = computed((): boolean | undefined => {
  if (loading.value) {
    return undefined;
  }
  return logs.value === undefined || Object.keys(logs.value).length === 0;
});
</script>
