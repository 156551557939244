<template>
  <div :class="cssClass" :style="cssStyle">
    <template v-for="(child, index) in children">
      <template
        v-if="
          code === 'table-header' &&
          headers !== undefined &&
          'header' in child &&
          child.header === true
        "
      >
        <div :key="uuid + child.linkId + 'a'"></div>
        <div
          class="mt-3 self-end text-center text-xs text-gray-700 dark:text-neutral-300"
          v-for="(header, index) in headers"
          :class="'col_' + index"
          :key="index + 'header' + child.linkId"
        >
          {{ header }}
        </div>
      </template>
      <ItemComponent
        v-else-if="!('header' in child)"
        :style="
          code === 'table' || code === 'table-header'
            ? 'display: contents;'
            : ''
        "
        :key="uuid + child.linkId"
        :class="code === 'table-header' ? 'row_' + index : ''"
        @change="(e) => $emit('change', e)"
        :item="child"
        :response="response"
        :readonly="readonly"
        :groupType="code"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  EXTENSION,
  GroupItem,
  Item,
  VALUE_SET,
} from "@/libraries/questionnaires/item";
import {
  BooleanExpression,
  calcBoolean,
  mapResponse,
} from "@/libraries/questionnaires/score";
import { computed } from "vue";
import ItemComponent from "./Item.vue";

const uuid = crypto.randomUUID();

const props = withDefaults(
  defineProps<{
    item: GroupItem;
    response: any;
    readonly?: boolean;
  }>(),
  {
    readonly: false,
  },
);

const children = computed(
  (): Array<Item | { header: true; linkId: string }> => {
    if (code.value !== "table-header") return props.item.item;
    const items: Array<Item | { header: true; linkId: string }> =
      props.item.item;

    return items.flatMap((item: any) => {
      if ("type" in item && item.type === "display") {
        return [item, { header: true, linkId: Math.random().toString() }];
      }

      return [item];
    });
  },
);

const code = computed(
  () =>
    props.item.extension
      ?.find((ex) => {
        return ex.url === EXTENSION.DISPLAY;
      })
      ?.valueCodeableConcept?.coding?.find((coding) => {
        return coding.system === VALUE_SET.DISPLAY;
      })?.code,
);

const headers = computed((): string[] | undefined => {
  const headers = props.item.extension
    ?.find((ex) => {
      return ex.url === EXTENSION.DISPLAY;
    })
    ?.valueCodeableConcept?.coding?.find((coding) => {
      return coding.system === VALUE_SET.DISPLAY_TABLE_HEADERS;
    })?.code;

  if (headers === undefined) {
    return undefined;
  }

  if (!Array.isArray(headers)) {
    throw new Error("expected headers to be an array");
  }

  return headers;
});

const gridTemplateColumnsCss = computed((): string | undefined => {
  const css = props.item.extension
    ?.find((ex) => {
      return ex.url === EXTENSION.DISPLAY;
    })
    ?.valueCodeableConcept?.coding?.find((coding) => {
      return coding.system === VALUE_SET.DISPLAY_GRID_COLUMNS;
    })?.code;

  if (css !== undefined && typeof css !== "string") {
    throw new Error(
      "Expected code to be undefined or string, found " + typeof css,
    );
  }

  return css;
});

const mappedResponse = computed(() => mapResponse(props.response));

const cssClass = computed((): string[] => {
  if (!enabled.value) {
    return ["hidden"];
  }

  if (code.value === undefined) return [];

  if (code.value === "contents") return ["contents"];

  if (code.value === "question") {
    return [
      "col-span-full border-l-4 border-blue-500 border-opacity-20 hover:border-opacity-50 focus-within:border-opacity-50 transition-colors duration-75 p-2",
      props.readonly ? "my-2" : "my-4 space-y-4",
    ];
  }

  if (code.value === "table" || code.value === "table-header") {
    return [
      "col-span-full grid gap-y-3 gap-x-2 items-center",
      `questionnaire-${code.value}`,
    ];
  }

  return [];
});

const cssStyle = computed((): any => {
  if (code.value !== "table" && code.value !== "table-header") return "";

  const css = gridTemplateColumnsCss.value;
  if (css === undefined) {
    return "";
  }

  return { "grid-template-columns": css };
});

const enabled = computed((): boolean => {
  const enabledWhen = props.item.extension?.find((ex) => {
    return ex.url == EXTENSION.ENABLED_WHEN;
  })?.valueJson;

  if (enabledWhen !== undefined) {
    if (mappedResponse.value === undefined) {
      return false;
    }
    try {
      return calcBoolean(
        enabledWhen as BooleanExpression,
        mappedResponse.value,
      );
    } catch (err) {
      return true;
    }
  }
  return true;
});

const hasStyle = computed(
  (): boolean =>
    props.item.extension?.some((ex) => {
      return ex.url == EXTENSION.DISPLAY;
    }) ?? false,
);
</script>

<style>
@media (hover: hover) {
  .questionnaire-table-header:hover div[class*="row_"]:hover div[id$="_label"] {
    color: black;
  }

  .dark
    .questionnaire-table-header:hover
    div[class*="row_"]:hover
    div[id$="_label"] {
    color: rgb(255, 255, 255);
  }
  .questionnaire-table-header:focus-within
    div[class*="row_"]:focus-within
    div[id$="_label"] {
    color: black;
  }

  .dark
    .questionnaire-table-header:focus-within
    div[class*="row_"]
    div[id$="_label"] {
    /* gray-500 */
    color: rgb(255, 255, 255);
  }

  .questionnaire-table-header:focus-within
    div[class*="row_"]
    div[id$="_label"] {
    /* gray-500 */
    color: rgba(113, 113, 122);
  }

  .dark
    .questionnaire-table-header:focus-within
    div[class*="row_"]
    div[id$="_label"] {
    /* gray-500 */
    color: rgb(255, 255, 255);
  }

  .questionnaire-table-header:hover div[class*="row_"] div[id$="_label"] {
    /* gray-500 */
    color: rgba(113, 113, 122);
  }

  .dark .questionnaire-table-header:hover div[class*="row_"] div[id$="_label"] {
    /* gray-500 */
    color: rgb(154, 154, 154);
  }
}
</style>
