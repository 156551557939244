import { Address, AddressForm } from "@/models/Address";
import { AddressCreateForm } from "@/models/Forms";
import { fetchAddress } from "@/queries/address/addressPostalCode";

export function useAddress() {
  function canGetAddressFromPostalCode(
    country_id: number,
    postal_code: string,
  ) {
    // Can only verify in the Netherlands
    if (country_id != 159) {
      return false;
    }

    return postalCodeIsValid(postal_code);
  }

  async function fillAddress(
    address: Address | AddressForm | AddressCreateForm,
  ): Promise<Address | AddressForm | AddressCreateForm> {
    // Check only works for NL
    if (
      !address.country_id ||
      !address.postal_code ||
      !canGetAddressFromPostalCode(address.country_id, address.postal_code)
    ) {
      return address;
    }

    const data = await fetchAddress(address.postal_code);

    if (data && data.street_name && data.city) {
      address = {
        ...address,
        street_name: data.street_name,
        city: data.city,
      };
    }
    return address;
  }

  function postalCodeIsValid(string: string): boolean {
    // Dutch format
    const postalFormat = /^\s*[0-9]{4}\s*[a-zA-Z]{2}\s*$/;

    return postalFormat.test(string);
  }

  return { fillAddress, postalCodeIsValid };
}
