<template>
  <div
    class="flex w-full flex-col gap-4 self-center py-2"
    v-flux-loading="loading"
  >
    <div class="flex gap-6 text-sm text-gray-500">
      <div class="w-20">Jaar</div>
      <div class="w-12">Aantal</div>
    </div>
    <div
      class="flex items-center gap-6"
      v-for="(count, idx) in treatmentCounts"
    >
      <div class="w-20">{{ treatmentCounts[idx].reference_year }}</div>
      <flux-input
        class="w-20"
        :model-value="treatmentCounts[idx].amount.toString()"
        @update:model-value="treatmentCounts[idx].amount = Number($event)"
        :maxLength="3"
      />
      <flux-button
        size="small"
        type="primary"
        @click="
          submitTreatmentCount(
            treatmentCounts[idx].reference_year,
            treatmentCounts[idx].amount,
          )
        "
      >
        Aanpassen
      </flux-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount, inject } from "vue";
import { patientTreatmentCountRepositoryKey } from "@/libraries/repositories/PatientTreatmentCount/PatientTreatmentCountRepository";

type TreatmentCount = {
  amount: number;
  reference_year: number;
};

const props = defineProps({
  patientZisNumber: {
    required: true,
    type: Number,
  },
  patientTreatmentCounts: {
    type: Array<TreatmentCount>,
  },
});

const repository = inject(patientTreatmentCountRepositoryKey)?.();
if (repository === undefined) {
  throw new Error("patientTreatmentCountRepository injection failure");
}

const loading = ref<boolean>(false);

const treatmentCounts = ref<TreatmentCount[]>(getEmptyTreatmentCounts());

onBeforeMount(async () => {
  if (!props.patientTreatmentCounts) {
    setTreatmentCounts();
  } else {
    props.patientTreatmentCounts.forEach((count) =>
      Object.assign(treatmentCounts.value, count),
    );
  }
});

function getEmptyTreatmentCounts(): TreatmentCount[] {
  const currentYear = new Date().getFullYear();
  return [
    {
      amount: 0,
      reference_year: currentYear,
    },
    {
      amount: 0,
      reference_year: currentYear - 1,
    },
    {
      amount: 0,
      reference_year: currentYear - 2,
    },
  ];
}

async function setTreatmentCounts() {
  loading.value = true;
  try {
    const fetchedCounts = await repository!.findAllForPatient(
      props.patientZisNumber,
    );
    if (!fetchedCounts) {
      return;
    }
    fetchedCounts.forEach((count) =>
      Object.assign(treatmentCounts.value, count),
    );
  } finally {
    loading.value = false;
  }
}

async function submitTreatmentCount(
  reference_year: string | number,
  amount: string | number,
) {
  loading.value = true;
  try {
    const treatmentCounts = await repository!.create(props.patientZisNumber, {
      amount: Number(amount),
      reference_year: Number(reference_year),
    });
    if (!treatmentCounts) {
      return;
    }
    await setTreatmentCounts();
  } finally {
    loading.value = false;
  }
}
</script>
