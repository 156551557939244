import { PatientSearchResult } from "@/libraries/repositories/patientRepository";
import { ref } from "vue";

const recentPatients = ref<PatientSearchResult[]>([]);

export function useRecentPatients() {
  function add(patient: PatientSearchResult) {
    recentPatients.value = recentPatients.value.filter(
      (p) => p.zis_number !== patient.zis_number,
    );

    if (recentPatients.value.length >= 10) {
      recentPatients.value.pop();
    }

    recentPatients.value.unshift(patient);
  }

  return { add, recentPatients };
}
