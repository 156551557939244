<template>
  <AvatarVue :avatar="avatar" :big="big" />
</template>

<script lang="ts" setup>
import { getUser } from "@/libraries/plugins/getUser";
import { User } from "@/models/User";
import { computed } from "vue";
import AvatarVue from "./Avatar.vue";

const props = defineProps<{
  user: User;
  big?: boolean;
}>();

const avatar = computed(
  () =>
    getUser().healthcare_provider.users.find((user) => user.id == props.user.id)
      ?.avatar,
);

defineExpose({ avatar });
</script>
