import { apiClient } from "../utils/axios";
import { TelemetryEntry } from "./Entry";

export interface TelemetryTransmitter {
  transmit(entries: TelemetryEntry[]): Promise<boolean>;
}

export class BackendApiTelemetryTransmitter implements TelemetryTransmitter {
  async transmit(entries: TelemetryEntry[]) {
    try {
      await apiClient.post("/telemetry/entries", {
        entries,
      });
    } catch {
      return false;
    }
    return true;
  }
}
