<template>
  <div v-if="isActive">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onMounted, onUnmounted, Ref } from "vue";
import { ItemManager } from "@/libraries/managers/ItemManager";

const props = defineProps<{
  label: string;
  prop: string;
}>();

const manager = inject<Ref<ItemManager>>("itemManager");
if (manager === undefined) {
  throw new Error("No item manager found");
}

const isActive = computed(() => manager.value.activeItem === props.prop);

onMounted(() => {
  manager.value.register({
    prop: props.prop,
    label: props.label,
  });
});

onUnmounted(() => {
  manager.value.unregister(props.prop);
});
</script>
