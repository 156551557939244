import { assertNever } from "../utils";
import { Coding, ResponseForm } from "./item";

type ScoreType = "main" | "subscore";

export type Score = {
  type: ScoreType;
  name?: string;
  enable_when?: BooleanExpression;
  score: NumericExpression;
  range?: [number, number];
  scoreDirection?: "good-to-bad" | "bad-to-good";
  categorical?: boolean;
  norm?: Array<{
    range: [number, number];
    description: string;
  }>;
};

export type Expression =
  | NumericExpression
  | NumericArrayExpression
  | BooleanExpression;

export type NumericExpression =
  | Sum
  | Subtract
  | Divide
  | Product
  | ReferenceNumber
  | ConstantNumber
  | IfElse
  | MapOneElement
  | Floor
  | Ceil
  | Maximum
  | Minimum
  | Count
  | CountNull
  | CountNotNull
  | MapNumber;

export type NumericArrayExpression =
  | Find
  | MapManyElements
  | ReferenceNumberArray
  | FindFilter;

export type BooleanExpression =
  | ReferenceBoolean
  | NumericComparison
  | Not
  | And
  | Or
  | IsNull
  | ConstantBoolean;

export interface And {
  type: "and";
  item: BooleanExpression[];
}

export interface Or {
  type: "or";
  item: BooleanExpression[];
}

export interface Find {
  type: "find";
  pattern: RegExp | string;
}

export type MapOneElement = {
  type: "map_one";
  map: Map<any, number>;
  item: ReferenceNumber;
};

export interface Floor {
  type: "floor";
  item: ReferenceNumber;
}

export interface Ceil {
  type: "ceil";
  item: ReferenceNumber;
}

export type MapManyElements = {
  type: "map_many";
  map: Record<any, number>;
  item: ReferenceNumber[] | ReferenceNumberArray;
};

export interface ReferenceNumber {
  type: "reference_number";
  linkId: string;
}

export interface ReferenceNumberArray {
  type: "reference_number_array";
  linkId: string;
}

export interface FindFilter {
  type: "find_filter";
  pattern: RegExp | string;
}

export interface Count {
  type: "count";
  item: NumericArrayExpression;
}

export interface CountNotNull {
  type: "count_not_null";
  pattern: RegExp | string;
}

export interface CountNull {
  type: "count_null";
  pattern: RegExp | string;
}

export interface IsNull {
  type: "is_null";
  linkId: string;
}

export interface ConstantBoolean {
  type: "constant_boolean";
  value: boolean;
}

export interface ReferenceBoolean {
  type: "reference_boolean";
  linkId: string;
}

export interface ConstantNumber {
  type: "constant_number";
  value: number;
}

export interface Sum {
  type: "sum";
  item: NumericExpression[] | NumericArrayExpression;
}

export interface Maximum {
  type: "maximum";
  item: NumericExpression[] | NumericArrayExpression;
}

export interface Minimum {
  type: "minimum";
  item: NumericExpression[] | NumericArrayExpression;
}

export interface Subtract {
  type: "subtract";
  left: NumericExpression;
  right: NumericExpression;
}

export interface Divide {
  type: "divide";
  left: NumericExpression;
  right: NumericExpression;
}

export interface Product {
  type: "product";
  item: NumericExpression[] | NumericArrayExpression;
}

export interface MapNumber {
  type: "map_number";
  item: NumericExpression;
  map: { [key: number]: number };
}

export const operatorNames = ["=", "!=", "<", ">", "<=", ">="] as const;

export interface Not {
  type: "not";
  exp: BooleanExpression;
}
export interface Or {
  type: "or";
  item: BooleanExpression[];
}

export interface And {
  type: "and";
  item: BooleanExpression[];
}

export interface NumericComparison {
  type: "numeric_comparison";

  left: NumericExpression;
  right: NumericExpression;

  operator: (typeof operatorNames)[number];
}

export type IfElse =
  | ({
      type: "ifelse";

      true: NumericExpression;
      false: NumericExpression;
      operator: (typeof operatorNames)[number];
    } & Omit<NumericComparison, "type">)
  | {
      type: "ifelse";
      exp: BooleanExpression;

      true: NumericExpression;
      false: NumericExpression;
      operator: (typeof operatorNames)[number];
    };

export function isNumericExpression(
  exp: Expression | Expression[],
): exp is NumericExpression {
  if (Array.isArray(exp)) {
    return false;
  }
  const types: Array<NumericExpression["type"]> = [
    "map_one",
    "map_number",
    "floor",
    "ceil",
    "reference_number",
    "constant_number",
    "sum",
    "subtract",
    "divide",
    "product",
    "ifelse",
    "maximum",
    "minimum",
    "count",
    "count_null",
    "count_not_null",
  ];
  return (types as string[]).includes(exp.type);
}

export function isNumericArrayExpression(
  exp: Expression | Expression[],
): exp is NumericArrayExpression {
  if (Array.isArray(exp)) {
    return false;
  }
  const types: Array<NumericArrayExpression["type"]> = [
    "find",
    "map_many",
    "reference_number_array",
    "find_filter",
  ];
  return (types as string[]).includes(exp.type);
}

export function isBooleanExpression(exp: Expression): exp is BooleanExpression {
  const types = [
    "reference_boolean",
    "and",
    "not",
    "or",
    "numeric_comparison",
    "is_null",
    "reference_boolean",
    "constant_boolean",
  ] as const;
  return (types as ReadonlyArray<string>).includes(exp.type);
}

type ComparisonOperator = (a: number, b: number) => boolean;
const operators = new Map<(typeof operatorNames)[number], ComparisonOperator>();
operators.set("<", (a: number, b: number) => a < b);
operators.set(">", (a: number, b: number) => a > b);
operators.set("=", (a: number, b: number) => a == b);
operators.set("!=", (a: number, b: number) => a != b);
operators.set("<=", (a: number, b: number) => a <= b);
operators.set(">=", (a: number, b: number) => a >= b);

export type Response = Map<
  string,
  string | string[] | number | number[] | boolean | null
>;

export function isArrayOf<T>(
  arr: unknown,
  comp: (t: unknown) => t is T,
): arr is T[] {
  if (!Array.isArray(arr)) {
    return false;
  }

  return arr.every(comp);
}

function isNumber(x: unknown): x is number {
  return typeof x === "number";
}
function isString(x: unknown): x is string {
  return typeof x === "string";
}
function isNonNullable<T>(x: T): x is NonNullable<T> {
  return x !== undefined && x !== null;
}

export function mapResponse(newResponse: ResponseForm) {
  const response: Response = new Map();
  for (const key in newResponse) {
    const res = newResponse[key];
    if (
      res === null ||
      typeof res === "string" ||
      typeof res === "number" ||
      typeof res === "boolean"
    ) {
      response.set(key, res);
    } else if (isArrayOf(res, (t): t is number => typeof t === "number")) {
      response.set(key, res);
    } else if (isArrayOf(res, isCoding)) {
      const val = res.flatMap((v) => v.valueCoding.code).filter(isNonNullable);
      if (!val.every(isNumber) && !val.every(isString)) {
        throw new Error("Expected only numbers or only strings.");
      }
      response.set(key, val);
    } else if (isCoding(res)) {
      response.set(key, res.valueCoding.code ?? null);
    } else if (isArrayOf(res, isString)) {
      response.set(key, res);
    } else {
      assertNever(res);
    }
  }
  return response;
}

function calcArray(exp: NumericArrayExpression, response: Response): number[] {
  const result: number[] = [];
  if (exp.type === "find") {
    response.forEach((value, key) => {
      if (new RegExp(exp.pattern).test(key)) {
        if (typeof value !== "number") {
          throw new Error("Find only supports finding number");
        }
        result.push(value);
      }
    });
  } else if (exp.type == "reference_number_array") {
    const val = response.get(exp.linkId);
    if (val === undefined) {
      throw new Error("unknown linkId " + exp.linkId);
    }
    if (!isArrayOf(val, isNumber)) {
      throw new Error("reference_number_array only supports finding number[]");
    }

    return val;
  } else if (exp.type === "find_filter") {
    response.forEach((value, key) => {
      if (new RegExp(exp.pattern).test(key)) {
        if (typeof value === "number") {
          result.push(value);
        } else if (value !== null) {
          throw new Error(`Rejected [${key} => ${typeof value}]`);
        }
      }
    });
  } else if (exp.type === "map_many") {
    if (Array.isArray(exp.item)) {
      return exp.item.map((item) => {
        return calc(
          {
            type: "map_number",
            map: exp.map,
            item,
          },
          response,
        );
      });
    }
    return calcArray(exp.item, response).map((item) => {
      return calc(
        {
          type: "map_number",
          map: exp.map,
          item: { type: "constant_number", value: item },
        },
        response,
      );
    });
  }
  return result;
}

export function calcBoolean(
  exp: BooleanExpression,
  response: Response,
): boolean {
  switch (exp.type) {
    case "reference_boolean":
      const val = response.get(exp.linkId);
      if (typeof val !== "boolean") {
        throw new Error("reference_boolean only supports finding boolean");
      }
      return val;

    case "not":
      return !calcBoolean(exp.exp, response);

    case "and":
      return exp.item.every((item) => calcBoolean(item, response));

    case "or":
      return exp.item.some((item) => calcBoolean(item, response));

    case "numeric_comparison":
      const left = calc(exp.left, response);
      const right = calc(exp.right, response);

      const compare = operators.get(exp.operator);

      if (compare === undefined) {
        throw new Error("unknown operator");
      }
      return compare(left, right);

    case "is_null":
      const isNull = (linkId: string) =>
        response.get(linkId) === null || response.get(linkId) === undefined;
      return isNull(exp.linkId);

    case "constant_boolean":
      return exp.value;
  }
}

export function calc(exp: NumericExpression, response: Response): number {
  switch (exp.type) {
    case "sum":
      if (isNumericArrayExpression(exp.item)) {
        return calcArray(exp.item, response).reduce((a, b) => a + b, 0);
      }

      return exp.item
        .map((item) => calc(item, response))
        .reduce((a, b) => a + b, 0);
    case "product":
      if (isNumericArrayExpression(exp.item)) {
        return calcArray(exp.item, response).reduce((a, b) => a * b, 1);
      }

      return exp.item
        .map((item) => calc(item, response))
        .reduce((a, b) => a * b, 1);
    case "maximum":
      if (isNumericArrayExpression(exp.item)) {
        return calcArray(exp.item, response).reduce(
          (a, b) => Math.max(a, b),
          -Infinity,
        );
      }

      return exp.item
        .map((item) => calc(item, response))
        .reduce((a, b) => Math.max(a, b), -Infinity);
    case "minimum":
      if (isNumericArrayExpression(exp.item)) {
        return calcArray(exp.item, response).reduce(
          (a, b) => Math.min(a, b),
          Infinity,
        );
      }

      return exp.item
        .map((item) => calc(item, response))
        .reduce((a, b) => Math.min(a, b), Infinity);
    case "reference_number":
      const val = response.get(exp.linkId);
      if (val === undefined) {
        throw new Error("unknown linkId " + exp.linkId);
      }
      if (typeof val !== "number") {
        throw new Error("reference_number_array only supports finding number");
      }
      return val;
    case "subtract":
      return calc(exp.left, response) - calc(exp.right, response);
    case "divide":
      return calc(exp.left, response) / calc(exp.right, response);
    case "constant_number":
      return exp.value;
    case "ifelse":
      let result;
      if ("left" in exp && exp.left !== undefined) {
        result = calcBoolean(
          Object.assign({}, exp, { type: "numeric_comparison" as const }),
          response,
        );
      } else {
        result = calcBoolean((exp as any).exp, response);
      }

      return calc(result ? exp.true : exp.false, response);
    case "count":
      return calcArray(exp.item, response).length;
    case "ceil":
      return Math.ceil(calc(exp.item, response));
    case "floor":
      return Math.floor(calc(exp.item, response));
    case "map_one":
      return exp.map.get(exp.item.linkId) ?? NaN;

    case "map_number":
      return exp.map[calc(exp.item, response)] ?? -1;

    case "count_not_null":
      return Array.from(response.entries())
        .filter(([key, _]) => new RegExp(exp.pattern).test(key))
        .filter(([_, val]) => val !== null && val !== undefined).length;

    case "count_null":
      return Array.from(response.entries())
        .filter(([key, _]) => new RegExp(exp.pattern).test(key))
        .filter(([_, val]) => val === null || val === undefined).length;
  }
}

export function isCoding(answer: any): answer is { valueCoding: Coding } {
  return (
    typeof answer === "object" && answer !== null && "valueCoding" in answer
  );
}

type X = string | number | boolean | Coding;

export function getValue(answer: X | X[] | null | undefined): X | X[] {
  if (["number", "string", "boolean"].includes(typeof answer)) {
    return answer as string | number | boolean;
  }

  if (Array.isArray(answer)) {
    return answer.flatMap(getValue);
  }

  if (answer !== null && typeof answer === "object" && isCoding(answer)) {
    const code = answer.valueCoding.code;
    if (code === undefined) {
      throw new Error("unknown code");
    }
    return code;
  }

  throw new Error(`unknown answer ${typeof answer}`);
}

export function simplifyNumericExpression(
  exp: NumericExpression,
): NumericExpression {
  if (exp.type === "sum" || exp.type === "product") {
    if (!Array.isArray(exp.item)) {
      return simplifyNumericExpression(exp);
    }

    if (exp.item.length === 1) {
      return exp.item[0];
    }
  }

  return exp;
}
export function simplifyBooleanExpression(
  exp: BooleanExpression,
): BooleanExpression {
  if (exp.type === "or" || exp.type === "and") {
    if (!Array.isArray(exp.item)) {
      return simplifyBooleanExpression(exp);
    }

    if (exp.item.length === 1) {
      return exp.item[0];
    }
  }

  return exp;
}
