export const emailAddressTypes = ["HP", "WP"] as const;
export type EmailAddressType = (typeof emailAddressTypes)[number];

export interface EmailAddress {
  id?: number;
  patient_zis_number?: number;
  email_address?: string;
  email_address_type?: EmailAddressType;
  preferred?: boolean;
}

export interface EmailAddressPersisted {
  id: number;
  patient_zis_number: number;
  email_address: string;
  email_address_type: EmailAddressType;
  preferred: boolean;
}
