import HomeComponent from "../../components/Home.vue";
import LayoutComponent from "../../components/Layout.vue";
import LoginComponent from "../../components/Login.vue";
import PatientEditComponent from "../../components/PatientEdit/PatientEdit.vue";
import PatientSearchResultsComponent from "../../components/PatientSearchResults.vue";
import NotFoundComponent from "../../components/NotFound.vue";
import ErrorComponent from "../../components/Error.vue";
import SetPasswordComponent from "../../components/SetPassword.vue";

const routeOptions = [
  {
    path: "/",
    component: LayoutComponent,
    meta: { auth: true },
    children: [
      {
        path: "",
        name: "Home",
        component: HomeComponent,
      },
      {
        path: "patients",
        name: "patientList",
        component: () =>
          import(
            /* webpackChunkName: "patientlist" */ "../../components/PatientListPage.vue"
          ),
      },
      {
        path: "healthcare_professionals",
        name: "healthcareProfessionalList",
        component: () =>
          import(
            /* webpackChunkName: "patientlist" */ "../../components/HealthcareProfessionalPage/HealthcareProfessionalPage.vue"
          ),
      },
      {
        path: "data_explorer",
        name: "dataExplorer",
        component: () =>
          import("../../components/DataExplorer/DataExplorer.vue"),
      },
      {
        path: "data_explorer/:data_view",
        name: "dataExplorerView",
        component: () => import("../../components/DataExplorer/DataView.vue"),
      },
      {
        path: "patient/create",
        name: "patientCreate",
        component: () =>
          import(
            /* webpackChunkName: "patientcreate" */ "../../components/PatientCreate/PatientCreate.vue"
          ),
        // component: PatientCreateComponent,
      },
      {
        path: "patient/:zis_number/edit",
        name: "patientEdit",
        component: PatientEditComponent,
      },
      {
        path: "patient/search",
        name: "PatientSearchResults",
        component: PatientSearchResultsComponent,
        props: true,
      },
      {
        path: "patient/:zis_number",
        name: "PatientOverview",
        // component: PatientOverviewComponent,
        component: () =>
          import(
            /* webpackChunkName: "patientOverview" */ "../../components/PatientOverview.vue"
          ),
        props: true,
      },
      {
        path: "finance",
        name: "Finance",
        component: () =>
          import(
            /* webpackChunkName: "finance" */ "../../components/finance/Finance.vue"
          ),
        // component: FinanceComponent,
      },
      {
        path: "patient/:zis_number/invoices/create",
        name: "FinanceCreateInvoice",
        component: () =>
          import(
            /* webpackChunkName: "financeCreateInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateInvoice.vue"
          ),
        // component: FinanceCreateInvoiceComponent,
        props: true,
      },
      {
        path: "finance/create",
        name: "FinanceSelectDebtor",
        component: () =>
          import(
            /* webpackChunkName: "financeSelectDebtor" */ "../../components/finance/FinanceSelectDebtor.vue"
          ),
        // component: FinanceSelectDebtorComponent,
        props: true,
      },
      {
        path: "finance/company_product/create",
        name: "FinanceCreateCompanyProductInvoice",
        component: () =>
          import(
            /* webpackChunkName: "FinanceCreateCompanyProductInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateCompanyProductInvoice.vue"
          ),
        props: false,
      },
      {
        path: "patient/:zis_number/product_invoices/create",
        name: "FinanceCreatePatientProductInvoice",
        component: () =>
          import(
            /* webpackChunkName: "FinanceCreatePatientProductInvoice" */ "../../components/finance/CreateInvoice/FinanceCreatePatientProductInvoice.vue"
          ),
        props: true,
      },
      {
        path: "patient/:zis_number/product_invoices/:invoice_id/edit",
        name: "FinanceCreatePatientProductInvoiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "FinanceCreatePatientProductInvoice" */ "../../components/finance/CreateInvoice/FinanceCreatePatientProductInvoice.vue"
          ),
        props: false,
      },
      {
        path: "patient/:zis_number/product_invoices/:invoice_id/credit",
        name: "FinanceCreatePatientProductInvoiceCredit",
        component: () =>
          import(
            /* webpackChunkName: "FinanceCreatePatientProductInvoice" */ "../../components/finance/CreateInvoice/FinanceCreatePatientProductInvoice.vue"
          ),
        props: false,
      },
      {
        path: "invoices/:id/edit",
        name: "FinanceCreateCompanyProductInvoiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "financeCreateInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateCompanyProductInvoice.vue"
          ),
        props: false,
      },
      {
        path: "invoices/:id/credit",
        name: "FinanceCreateCompanyProductInvoiceCredit",
        component: () =>
          import(
            /* webpackChunkName: "financeCreateInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateCompanyProductInvoice.vue"
          ),
        props: false,
      },
      {
        path: "patient/:zis_number/invoices/:id",
        name: "InvoicePatientDetails",
        component: () =>
          import(
            /* webpackChunkName: "financeSelectDebtor" */ "../../components/finance/Details/InvoiceDetails.vue"
          ),
        // component: FinanceSelectDebtorComponent,
        props: false,
      },
      {
        path: "invoices/:id",
        name: "InvoiceDetails",
        component: () =>
          import(
            /* webpackChunkName: "financeSelectDebtor" */ "../../components/finance/Details/InvoiceDetails.vue"
          ),
        // component: FinanceSelectDebtorComponent,
        props: false,
      },
      {
        path: "invoices/:id/creditCollection",
        name: "CollectionInvoiceCredit",
        component: () =>
          import(
            /* webpackChunkName: "financeSelectDebtor" */ "../../components/finance/Credit/CollectionInvoice.vue"
          ),
        // component: FinanceSelectDebtorComponent,
        props: false,
      },
      {
        path: "patient/:zis_number/invoices/:id/edit",
        name: "PatientInvoiceEdit",
        component: () =>
          import(
            /* webpackChunkName: "financeCreateInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateInvoice.vue"
          ),
        // component: FinanceCreateInvoiceComponent,
        props: true,
      },
      {
        path: "patient/:zis_number/invoices/:id/credit",
        name: "PatientInvoiceCredit",
        component: () =>
          import(
            /* webpackChunkName: "financeCreateInvoice" */ "../../components/finance/CreateInvoice/FinanceCreateInvoice.vue"
          ),
        // component: FinanceCreateInvoiceComponent,
        props: true,
      },
      {
        path: "calendar",
        name: "Calendar",
        component: () =>
          import(
            /* webpackChunkName: "calendarWrapper" */ "../../components/CalendarWrapper.vue"
          ),
        props: true,
        // component: CalendarWrapperComponent,
      },
      {
        name: "Calendar/Settings",
        path: "calendar/settings",
        component: () =>
          import(
            /* webpackChunkName: "availabilitySettings" */ "../../components/calendar/AvailabilitySettings/AvailabilitySettings.vue"
          ),
      },
      {
        path: "calendar/trash",
        name: "calendar/trash",
        component: () =>
          import("../../components/calendar/TrashedAppointments.vue"),
        props: true,
      },
      {
        path: "/admin",
        name: "Admin",
        redirect: { name: "AdminOverview" },
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "../../components/admin/AdminLayout.vue"
          ),
        children: [
          {
            path: "/admin",
            name: "AdminOverview",
            component: () => import("../../components/admin/AdminOverview.vue"),
          },
          {
            path: "users",
            component: () => import("../../components/admin/AdminUsers.vue"),
          },
          {
            path: "user-labels",
            component: () =>
              import("../../components/admin/AdminUserLabels.vue"),
          },
          {
            path: "general",
            component: () => import("../../components/admin/pages/General.vue"),
          },
          {
            path: "email_settings",
            component: () =>
              import(
                "../../components/admin/pages/EmailSettings/EmailSettings.vue"
              ),
          },
          {
            path: "questionnaire_response_requests",
            component: () =>
              import(
                "../../components/admin/pages/QuestionnaireResponseRequestSettings/QuestionnaireResponseRequestSettings.vue"
              ),
          },
          {
            path: "import",
            component: () => import("../../components/admin/pages/Import.vue"),
          },
          {
            path: "import/flux-service",
            component: () =>
              import("../../components/admin/pages/Import/ImportService.vue"),
          },
          {
            path: "import/self-service",
            component: () =>
              import(
                "../../components/admin/pages/Import/ImportSelfService.vue"
              ),
          },
          {
            path: "import/self-service/documents",
            component: () =>
              import(
                "../../components/admin/pages/Import/ImportSelfServiceDocuments.vue"
              ),
          },
          {
            path: "legal",
            component: () => import("../../components/admin/pages/Legal.vue"),
          },
          {
            path: "invoices",
            component: () =>
              import(
                "../../components/admin/pages/InvoiceSettings/InvoiceSettings.vue"
              ),
          },
          {
            path: "invoice-generation",
            component: () =>
              import("../../components/admin/pages/InvoiceGeneration.vue"),
          },
          {
            path: "payment-reminders",
            component: () =>
              import("../../components/admin/pages/PaymentReminders.vue"),
          },
          {
            path: "logos",
            component: () => import("../../components/admin/pages/Logos.vue"),
          },
          {
            path: "prestatiecode-prices",
            component: () =>
              import("../../components/admin/pages/PrestatiecodePrices.vue"),
          },
          {
            path: "contracts",
            component: () =>
              import(
                "../../components/admin/pages/InsurerContract/InsurerContracts.vue"
              ),
          },
          {
            path: "contracts/create",
            name: "AdminInsurerContractCreate",
            component: () =>
              import(
                "../../components/admin/pages/InsurerContract/InsurerContractCreate.vue"
              ),
          },
          {
            path: "contracts/:contract",
            name: "AdminInsurerContractDetail",
            component: () =>
              import(
                "../../components/admin/pages/InsurerContract/InsurerContractDetail.vue"
              ),
          },
          {
            path: "packages",
            name: "AdminPackages",
            component: () =>
              import("../../components/admin/pages/Packages/Packages.vue"),
          },
          {
            path: "subscription-plans",
            name: "AdminSubscriptionPlans",
            component: () =>
              import(
                "../../components/admin/pages/SubscriptionPlans/SubscriptionPlans.vue"
              ),
          },
          {
            path: "pm305-import",
            name: "AdminPm305ForImports",
            component: () =>
              import("../../components/admin/pages/Pm305ForImports.vue"),
          },
          {
            path: "debtor-numbers",
            name: "AdminDebtorNumbers",
            component: () =>
              import("../../components/admin/pages/DebtorNumbers.vue"),
          },
          {
            path: "pm305-import",
            name: "AdminPm305ForImports",
            component: () =>
              import("../../components/admin/pages/Pm305ForImports.vue"),
          },
          {
            path: "apps",
            component: () => import("../../components/admin/pages/Apps.vue"),
          },
          {
            path: "online-scheduling",
            component: () =>
              import("../../components/admin/pages/OnlineScheduling.vue"),
          },
          {
            path: "appointment-types",
            component: () =>
              import(
                "../../components/admin/pages/AppointmentTypes/AppointmentTypes.vue"
              ),
          },
          {
            path: "locations",
            component: () =>
              import("../../components/admin/pages/Locations.vue"),
          },
          {
            path: "recalls",
            component: () => import("../../components/admin/pages/Recalls.vue"),
          },
          {
            path: "email_templates",
            component: () =>
              import(
                "../../components/admin/pages/EmailTemplates/EmailTemplates.vue"
              ),
          },
          {
            path: "email_templates/create",
            component: () =>
              import(
                "../../components/admin/pages/EmailTemplates/EmailTemplateCreate.vue"
              ),
          },
          {
            path: "email_templates/:email_template_uuid",
            component: () =>
              import(
                "../../components/admin/pages/EmailTemplates/EmailTemplateEdit.vue"
              ),
          },
          {
            path: "salutation_templates",
            component: () =>
              import(
                "../../components/admin/pages/SalutationTemplates/SalutationTemplates.vue"
              ),
          },
          {
            path: "salutation_templates/create",
            component: () =>
              import(
                "../../components/admin/pages/SalutationTemplates/SalutationTemplateCreate.vue"
              ),
          },
          {
            path: "salutation_templates/:salutation_template_uuid",
            component: () =>
              import(
                "../../components/admin/pages/SalutationTemplates/SalutationTemplateEdit.vue"
              ),
          },
          {
            path: "patient_titles",
            component: () =>
              import(
                "../../components/admin/pages/PatientTitles/PatientTitles.vue"
              ),
          },
          {
            path: "apps/moneybird",
            name: "AdminAppMoneybird",
            component: () =>
              import(
                /* webpackChunkName: "adminAppMoneybird" */ "../../components/admin/apps/Moneybird.vue"
              ),
          },
          {
            path: "apps/mollie",
            name: "AdminAppMollie",
            component: () =>
              import(
                /* webpackChunkName: "adminAppMollie" */ "../../components/admin/apps/Mollie.vue"
              ),
          },
          {
            path: "apps/zorg-mail",
            name: "AdminAppZorgmail",
            component: () =>
              import(
                /* webpackChunkName: "adminAppZorgMail" */ "../../components/admin/apps/ZorgMail.vue"
              ),
          },
          {
            path: "apps/vecozo-cov",
            name: "AdminAppVecozoCov",
            component: () =>
              import(
                /* webpackChunkName: "adminAppVecozo" */ "../../components/admin/apps/VecozoCov.vue"
              ),
          },
          {
            path: "apps/vecozo-declaration",
            name: "AdminAppVecozoDeclaration",
            component: () =>
              import(
                /* webpackChunkName: "adminAppVecozo" */ "../../components/admin/apps/VecozoDeclaration.vue"
              ),
          },
          {
            path: "apps/qdna",
            name: "AdminAppQdna",
            component: () =>
              import(
                /* webpackChunkName: "adminAppQdna" */ "../../components/admin/apps/Qdna.vue"
              ),
          },
          {
            path: "apps/qualiview",
            name: "AdminAppQualiview",
            component: () =>
              import(
                /* webpackChunkName: "adminAppQualiview" */ "../../components/admin/apps/Qualiview.vue"
              ),
          },
          {
            path: "apps/zorgvergoeding",
            name: "AdminAppZorgvergoeding",
            component: () =>
              import(
                /* webpackChunkName: "adminAppZorgvergoeding" */ "../../components/admin/apps/Zorgvergoeding.vue"
              ),
          },
          {
            path: "apps/sbv-z",
            name: "AdminAppSbvZ",
            component: () =>
              import(
                /* webpackChunkName: "adminAppSbvZ" */ "../../components/admin/apps/SbvZ.vue"
              ),
          },
          {
            path: "apps/twinfield",
            name: "AdminAppTwinfield",
            component: () =>
              import(
                /* webpackChunkName: "adminAppQdna" */ "../../components/admin/apps/Twinfield.vue"
              ),
          },
          {
            path: "apps/healthtrain",
            name: "AdminAppHealthTrain",
            component: () =>
              import(
                /* webpackChunkName: "adminAppQdna" */ "../../components/admin/apps/HealthTrain/HealthTrain.vue"
              ),
          },
          {
            path: "apps/nivel",
            name: "AdminAppNivel",
            component: () => import("../../components/admin/apps/Nivel.vue"),
          },
          {
            path: "apps/ggn",
            name: "AdminAppGgn",
            component: () => import("../../components/admin/apps/Ggn/Ggn.vue"),
          },
          {
            path: "apps/ldf",
            name: "AdminAppLdf",
            component: () =>
              import("../../components/admin/apps/Ldf/LdfApp.vue"),
          },
          {
            path: "apps/exact",
            name: "AdminAppExact",
            component: () =>
              import("../../components/admin/apps/Exact/Exact.vue"),
          },
          {
            path: "accounting_system_configuration/:type/:configurationId",
            name: "AdminAccountingSystemConfiguration",
            component: () =>
              import(
                "../../components/admin/pages/AccountingSystemConfiguration/AccountingSystemConfiguration.vue"
              ),
          },
          {
            path: "apps/zorgdomein",
            name: "AdminAppZorgdomein",
            component: () =>
              import("../../components/admin/apps/Zorgdomein/Zorgdomein.vue"),
          },
          {
            path: "apps/skf",
            name: "AdminAppSkf",
            component: () => import("../../components/admin/apps/SkfApp.vue"),
          },
          {
            path: "security",
            component: () =>
              import("../../components/admin/pages/Security.vue"),
          },
          {
            path: "letter-templates",
            component: () => import("../../components/ui/RouterPage.vue"),
            children: [
              {
                path: "",
                component: () =>
                  import(
                    "../../components/admin/pages/LetterTemplates/LetterTemplates.vue"
                  ),
              },
              {
                path: "create",
                component: () =>
                  import(
                    "../../components/admin/pages/LetterTemplates/LetterTemplateCreate.vue"
                  ),
              },
              {
                path: ":id(\\d+)",
                component: () =>
                  import(
                    "../../components/admin/pages/LetterTemplates/LetterTemplateDetail.vue"
                  ),
              },
            ],
          },
          {
            path: "snippets",
            component: () => import("../../components/ui/RouterPage.vue"),
            children: [
              {
                path: "",
                component: () =>
                  import("../../components/admin/pages/Snippets/Snippets.vue"),
              },
              {
                path: "create",
                component: () =>
                  import(
                    "../../components/admin/pages/Snippets/SnippetCreate.vue"
                  ),
              },
              {
                path: ":uuid([a-f0-9-]+)",
                component: () =>
                  import(
                    "../../components/admin/pages/Snippets/SnippetDetail.vue"
                  ),
              },
            ],
          },
          {
            path: "consult-templates",
            component: () =>
              import("../../components/admin/pages/ConsultTemplates.vue"),
          },
          {
            path: "consult-templates/edit",
            component: () =>
              import("../../components/admin/pages/ConsultTemplateEdit.vue"),
          },
          {
            path: "patientlabels",
            component: () =>
              import("../../components/admin/pages/AddPatientLabel.vue"),
          },
          {
            path: "access",
            component: () =>
              import("../../components/admin/pages/AccessSettings.vue"),
          },
          {
            path: "care-plan-templates",
            component: () =>
              import(
                "../../components/admin/pages/CarePlanTemplates/CarePlanTemplates.vue"
              ),
          },
          {
            path: "companies",
            component: () =>
              import("../../components/admin/pages/Companies/Companies.vue"),
          },
          {
            path: "companies/create",
            component: () =>
              import(
                "../../components/admin/pages/Companies/CompanyCreate.vue"
              ),
          },
          {
            path: "companies/:uuid",
            component: () =>
              import(
                "../../components/admin/pages/Companies/CompanyDetailProvider.vue"
              ),
          },
          {
            path: "products",
            component: () =>
              import("../../components/admin/pages/Products/Products.vue"),
          },
          {
            path: "products/create",
            component: () =>
              import("../../components/admin/pages/Products/ProductWrite.vue"),
          },
          {
            path: "products/:id",
            component: () =>
              import("../../components/admin/pages/Products/ProductWrite.vue"),
          },
          {
            path: "pricelists",
            component: () =>
              import("../../components/admin/pages/Products/Pricelists.vue"),
          },
          {
            path: "pricelists/create",
            component: () =>
              import(
                "../../components/admin/pages/Products/PricelistCreate.vue"
              ),
          },
          {
            path: "pricelists/:id",
            component: () =>
              import(
                "../../components/admin/pages/Products/PricelistDetail.vue"
              ),
          },
          {
            path: "user-calendar-settings",
            component: () =>
              import(
                "../../components/admin/pages/UserCalendarSettings/UserCalendarSettings.vue"
              ),
          },
          {
            path: "user-patient-settings",
            component: () =>
              import(
                "../../components/admin/pages/UserPatientSettings/UserPatientSettings.vue"
              ),
          },
          {
            path: "user-notification-settings",
            component: () =>
              import(
                "../../components/admin/pages/UserNotificationSettings/UserNotificationSettings.vue"
              ),
          },
        ],
      },
      {
        path: "releasenotes",
        name: "ReleaseNotes",
        component: () =>
          import(
            /* webpackChunkName: "releasenotes" */ "../../components/ReleaseNotes/ReleaseNotes.vue"
          ),
      },
      {
        path: "releasenotes/note/:note",
        name: "ReleaseNote",
        component: () =>
          import(
            /* webpackChunkName: "releasenotes" */ "../../components/ReleaseNotes/ReleaseNote.vue"
          ),
      },
      {
        path: "businessIntelligence",
        name: "businessIntelligence",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligence" */ "../../components/BusinessIntelligence/Dashboard.vue"
          ),
      },
      {
        path: "businessIntelligence/revenueEstimation",
        name: "businessIntelligenceRevenueEstimation",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligence" */ "../../components/BusinessIntelligence/RevenueEstimation.vue"
          ),
      },
      {
        path: "businessIntelligence/revenueEstimation/user/:userId",
        name: "businessIntelligenceRevenueEstimationUser",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligence" */ "../../components/BusinessIntelligence/RevenueEstimationUserGraphsPage.vue"
          ),
      },
      {
        path: "businessIntelligence/:kpi/details",
        name: "businessIntelligenceDetails",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligenceDetails" */ "../../components/BusinessIntelligence/Details.vue"
          ),
      },
      {
        path: "businessIntelligence/:kpi/appointments",
        name: "businessIntelligenceAppointments",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligenceDetails" */ "../../components/BusinessIntelligence/Appointments.vue"
          ),
      },
      {
        path: "businessIntelligence/:kpi/details/csi",
        name: "businessIntelligenceDetailsCsi",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligenceDetails" */ "../../components/BusinessIntelligence/RevenueCsiDetails.vue"
          ),
      },
      {
        path: "businessIntelligence/:kpi/details/product",
        name: "businessIntelligenceDetailsProduct",
        component: () =>
          import(
            /* webpackChunkName: "businessIntelligenceDetails" */ "../../components/BusinessIntelligence/RevenueProduct.vue"
          ),
      },
      {
        path: "inbox",
        name: "inbox",
        component: () =>
          import("../../components/Inbox/MedicalConverstionInbox.vue"),
      },
      {
        path: "/confirm_set_password",
        name: "confirmSetPassword",
        component: () => import("../../components/confirmSetPassword.vue"),
      },
    ],
  },
  { path: "/login", component: LoginComponent, meta: { guest: true } },
  { path: "/error", component: ErrorComponent, meta: { noSync: true } },
  {
    path: "/set_password",
    component: SetPasswordComponent,
    meta: { guest: true },
  },
  { path: "/:pathMatch(.*)*", component: NotFoundComponent },
];

export default routeOptions;
