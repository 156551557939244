<template>
  <flux-tabs
    :activeTab="activeTab"
    @update:activeTab="(newTab) => setActiveTab(newTab)"
    ref="tabs"
  >
    <flux-tab label="Afspraken" name="today">
      <TodayWidgetComponent class="mt-6"></TodayWidgetComponent>
    </flux-tab>
    <flux-tab label="Recalls" name="recalls">
      <DashboardRecalls class="mt-6"></DashboardRecalls>
    </flux-tab>
    <flux-tab
      v-if="canSeeTasks"
      :badge-color="hasOpenTasks ? 'bg-orange-600' : ''"
      label="Taken"
      name="tasks"
    >
      <TaskOverview class="mt-6" v-if="activeTab === 'tasks'"></TaskOverview>
    </flux-tab>
    <flux-tab v-if="canSeeWorkList" label="Werklijst" name="worklist">
      <WorkListWidget
        class="mt-6"
        v-if="activeTab === 'worklist'"
      ></WorkListWidget>
    </flux-tab>
  </flux-tabs>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onMounted } from "vue";
import TodayWidgetComponent from "./calendar/TodayWidget.vue";
import TaskOverview from "@/components/Tasks/TaskOverview.vue";
import DashboardRecalls from "./Dashboard/DashboardRecalls.vue";
import { useMyTasks } from "@/composables/myTasks";
import WorkListWidget from "./Dashboard/WorkListWidget.vue";
import { useHomeTabs } from "@/composables/homeTabs";
import { hasFeatureFlag } from "@/libraries/plugins/hasFeatureFlag";
import { getUser } from "@/libraries/plugins/getUser";
import { hasPermission } from "@/libraries/utils/hasPermission";
import { useRouter } from "vue-router";

const router = useRouter();

const { activeTab, setActiveTab, setActiveTabUsingSessionStorage } =
  useHomeTabs();

const { hasOpenTasks, data } = useMyTasks();

onBeforeMount(() => {
  if (!hasPermission("view-patient")) {
    router.replace("/calendar");
  }
});

onMounted(() => {
  setActiveTabUsingSessionStorage();
});

const canSeeTasks = computed(() => {
  return hasPermission("view-task");
});

const canSeeWorkList = computed(() => {
  if (
    !hasPermission("view-work-list") &&
    !hasPermission("view-work-list-any")
  ) {
    return false;
  }
  if (
    hasFeatureFlag("work-list-owner") &&
    getUser().roles.some((role) => role.name === "owner")
  ) {
    return true;
  }
  if (hasFeatureFlag("work-list")) {
    return true;
  }
  return false;
});
</script>
